import React, { Component } from "react";
import { connect } from "react-redux";
import "./TopTab.scss";

class TopTab extends Component {
  render() {
    return (
      <div className="admin-top-tabs position-fixed bg-white">
        {this.props.tabCategoryName === "contentManagment" ? (
          <ul className="tabs-list d-flex align-items-center">
            <li>
              <a
                href="/carousel-management"
                className={
                  this.props.activeTab === "Carousel"
                    ? "font-weight-bold fs-16 d-inline-block active"
                    : "font-weight-bold fs-16 d-inline-block "
                }
              >
                Carousel
              </a>
            </li>
            <li>
              <a
                href="/upload-content-listing"
                className={
                  this.props.activeTab === "Upload Content"
                    ? "font-weight-bold fs-16 d-inline-block active"
                    : "font-weight-bold fs-16 d-inline-block "
                }
              >
                Upload Content
              </a>
            </li>
            <li>
              <a
                href="/announcements-management"
                className={
                  this.props.activeTab === "Announcement"
                    ? "font-weight-bold fs-16 d-inline-block active"
                    : "font-weight-bold fs-16 d-inline-block "
                }
              >
                Announcement
              </a>
            </li>
            <li>
              <a
                href="/faqs-listing-management"
                className={
                  this.props.activeTab === "FAQs"
                    ? "font-weight-bold fs-16 d-inline-block active"
                    : "font-weight-bold fs-16 d-inline-block "
                }
              >
                FAQs
              </a>
            </li>
          </ul>
        ) : null}

        {this.props.tabCategoryName === "userDataReports" ? (
          <ul className="tabs-list d-flex align-items-center">
            <li>
              <a
                href="/admin-home-page"
                className={
                  this.props.activeTab === "User Data"
                    ? "font-weight-bold fs-16 d-inline-block active"
                    : "font-weight-bold fs-16 d-inline-block "
                }
              >
                User Data
              </a>
            </li>
            <li>
              <a
                href="/user-reports-review"
                className={
                  this.props.activeTab === "Report/Review"
                    ? "font-weight-bold fs-16 d-inline-block active"
                    : "font-weight-bold fs-16 d-inline-block "
                }
              >
                Report/Review
              </a>
            </li>
          
          
          </ul>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  // userLogout: (data) => dispatch(logout_user_action(data))
});

export default connect(null, mapDispatchToProps)(TopTab);
