import React, { Component } from "react";
import humanizeDuration from "humanize-duration";
import "./EpisodesList.scss";

class EpisodesList extends Component {
  render() {
    const { episodes } = this.props;

    return (
      <div className="common-dashboard suggestions-wapper">
        <ul className="suggestion-list">
          {episodes && episodes.length ? (
            episodes.map((episode) => (
              <a
                key={episode.id}
                className="suggestion-detail d-flex mb-4 text-dark"
                href={`/watch-show/${episode.id}`}
              >
                <div className="position-relative">
                  <img
                    src={`https://image.mux.com/${
                      episode && episode.video_playback_id
                    }/thumbnail.png?width=400&height=200&fit_mode=smartcrop&time=35`}
                    alt="suggestions-item"
                    className="img-fit border-6 mr-4"
                  />
                  {episode && episode.is_premium ? (
                    <span class="d-flex align-items-center bg-warning px-2 text-white premium-tag position-absolute">
                      <img
                        src="../assets/images/royal-crown.png"
                        alt="crown"
                        class="mr-1"
                      />
                      Premium
                    </span>
                  ) : null}
                </div>

                <span className="right d-flex flex-column align-items-around">
                  <span className="fs-20 font-weight-semi">
                    {episode["title"]}
                  </span>
                  <span className="time text-grey-4 font-weight-medium font-smallest">
                    {episode["video_details"] &&
                    episode["video_details"]["duration"]
                      ? humanizeDuration(
                          episode["video_details"]["duration"] * 1000
                        )
                      : null}
                  </span>
                </span>
              </a>
            ))
          ) : (
            <p className="text-danger">No Episodes to show!</p>
          )}
        </ul>
      </div>
    );
  }
}
export default EpisodesList;
