import React from 'react';
import { Editor} from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import { EditorState, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {stateFromHTML} from 'draft-js-import-html'


const content = {"entityMap":{},"blocks":[{"key":"637gr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    const contentState = convertFromRaw(content);
    this.state = {
      contentState,
      editorState:  this.props &&
      this.props.htmlData ?
          EditorState.createWithContent(stateFromHTML(this.props.htmlData))
          : EditorState.createEmpty(),
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    }, () => {
      if(this.props.getHtmlData) {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        this.props.getHtmlData(currentContentAsHTML)
      }
    });
  };

  handleContentChange = (contentState) => {
    this.setState({
      contentState,
    }, () => {
      if(this.props.onChange) {
        this.props.onChange(contentState.blocks[0].text);
      }
    });
  } 

  convertContentToHTML = () => {
    const { editorState } = this.state
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    this.props.getHtmlData(currentContentAsHTML)
  }

  render() {
    const { editorState } = this.state;
    if (
        this.props &&
        this.props.clearInstruction
    )
      this.setState({ editorState: EditorState.createEmpty() })

      return (
          <Editor
              editorState={editorState}
              wrapperClassName="custom-editor-wrapper pt-2 pb-4"
              editorClassName="custom-editor bg-white px-3"
              toolbarClassName="custom-editor-toolbar bg-transparent px-0"
              onEditorStateChange={this.onEditorStateChange}
              onContentStateChange={this.handleContentChange}
              toolbar={this.props.toolbarSetting}
          />
      );
  }
}

export default TextEditor;