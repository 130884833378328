import React, { Component } from 'react';
import { connect } from 'react-redux'
import './Comments.scss';

import CommentsCard from './CommentsCard/CommentsCard';
import CommentsSearch from './CommentsSearch/CommentsSearch';
import ContentLoader from "../../../../Common/Loader/ContentLoader";
import {get_comments_by_id_action} from "../../../../../_actions/User/post.action";

class Comments extends Component {
    state = {
        commentsLoading: false,
        gotCommentError: false,
        comments: []
    }
    componentDidMount() { this.mountComments() }

    mountComments = async () => {
        this.setState({ commentsLoading: true })

        const postId = this.props &&
            this.props.commentsDetails &&
            this.props.commentsDetails.id

        const data = {}

        switch (
        this.props &&
        this.props.commentsDetails &&
        this.props.commentsDetails['content_type']
            ) {
            case "youtube-recipe":
                data.comment_type = "youtube_recipe"
                break;
            case "video":
                data.comment_type = "video"
                break;
            case "episode":
                data.comment_type = "episode"
                break;
            case "user_post":
                data.comment_type = "user_post"
                break;
        }

        const getCommentsRes = await this.props.getCommentsAction(data, postId)
        if (getCommentsRes && getCommentsRes.data) {
            this.setState({
                comments: getCommentsRes.data,
                gotCommentError: false
            })
        } else
            this.setState({ gotCommentError: true })

        this.setState({ commentsLoading: false })
    }

    refreshComments = async () => {
        await this.mountComments()
    }
    render() {
        const { comments, commentsLoading, gotCommentError } = this.state
        const post = this.props && this.props.commentsDetails
        return (
            <>
                <div className='comments-wrapper'>
                    <CommentsSearch
                        post={post}
                        refreshComments={() => this.refreshComments()}
                    />
                    <div className="comments-list">
                        {
                            commentsLoading ?
                                <ContentLoader color="text-warning" />
                                : gotCommentError ?
                                <p className="text-warning">Cannot fetch comments! refresh the page.</p>
                                : <>
                                    <CommentsCard
                                        refreshComments={() => this.refreshComments()}
                                        comments={comments}
                                        delete="Delete"
                                        report="Report"
                                    />
                                </>
                        }
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    getCommentsAction: (data, id) => dispatch(get_comments_by_id_action(data, id))
})

export default connect(null, mapDispatchToProps)(Comments);