import React, { Component } from "react";
import {
  Container,
  Modal,
  Button,
  Table,
  FormGroup,
  FormControl, Pagination,
} from "react-bootstrap";
import { connect } from 'react-redux'
import swal from 'sweetalert'

import "./FaqsListing.scss";
import TopTab from "../../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../../common/SidebarMenu/AdminSidebarMenu";
import {delete_faq_topic_action, load_faq_table_list_action} from "../../../../../_actions/Admin";
import ContentLoader from "../../../../Common/Loader/ContentLoader";
class FaqsListing extends Component {
  state = {
    deleteActionModal: false,
    faqTableData: [],
    deleteRowId: null,
    deletedItem: false,
    deleteLoading: false,
    loading: false,
    searchString: '',
    filteredTableData: [],
    entriesPerPage: 10,
    currentPageNumber: 1,
    maxPages: 1,
    prevFaqsLink: null,
    nextFaqsLink: null
  }

  componentDidMount() { this.handleLoadFaqTable() }

  handleLoadFaqTable = async () => {
    this.setState({ loading: true })
    const loadFaqTableRes = await this.props.loadFaqTable()
    if (loadFaqTableRes && loadFaqTableRes.data) {
      this.setState({
        faqTableData: loadFaqTableRes.data.reverse(),
        filteredTableData: loadFaqTableRes.data.reverse()
      }, () => {
        this.setMaxPagesValue()
      })
    } else if (loadFaqTableRes && loadFaqTableRes.msg) {
      swal(loadFaqTableRes.msg)
      this.setState({ loading: false })
      return
    }
    this.setState({ loading: false })
  }

  setMaxPagesValue = () => {
    // calculate max number of pages required to accommodate all the user details
    const { entriesPerPage, faqTableData } = this.state;
    const remainder = faqTableData.length % entriesPerPage;
    const quotient = faqTableData.length / entriesPerPage;

    let maxPagesNumber = remainder > 0 ?
        parseInt((quotient + 1).toString(), 10)
        : quotient;
    this.setState({ maxPages: maxPagesNumber }, () => {
      // manage current page number if it exceeds the max page capacity.
      const { currentPageNumber, maxPages } = this.state;
      if (currentPageNumber > maxPages)
        this.setState({ currentPageNumber: this.state.maxPages });
      if (currentPageNumber < 1) this.setState({ currentPageNumber: 1 });
    });
  };

  handlePageNumberChange = async (type) => {
    const {
      currentPageNumber,
      prevFaqsLink,
      nextFaqsLink,
      faqTableData,
      maxPages,
    } = this.state;
    if (type === "decrease") {
      if (currentPageNumber > 1)
          // decrease page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
      else {
        // hit the prev data api to fetch data of users
        if (prevFaqsLink !== null) {
          this.setState({ loading: true });
          const prevUserDataRes = await this.props.getPrevUserDetails(
              prevFaqsLink
          );
          if (prevUserDataRes) {
            const prevUserDataArray = prevUserDataRes.results;
            prevUserDataArray.push(...faqTableData);
            this.setState({
              faqTableData: prevUserDataArray,
              prevFaqsLink: prevUserDataRes.previous,
            });
          }
          this.setState({ loading: false });
        }
      }
    } else if (type === "increase") {
      if (currentPageNumber < maxPages)
          // increase page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
      else {
        // hit the next data api to fetch data of users
        if (nextFaqsLink !== null) {
          this.setState({ loading: true });
          const nextUserDataRes = await this.props.getNextUserDetails(
              nextFaqsLink
          );
          if (nextUserDataRes) {
            const nextUserDataArray = faqTableData;
            nextUserDataArray.push(...nextUserDataRes.results);
            this.setState({
              faqTableData: nextUserDataArray,
              nextFaqsLink: nextUserDataRes.next,
            });
          }
          this.setState({ loading: false });
        }
      }
    }
  };

  handleCapacityChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({ [name]: parseInt(value) }, () => this.setMaxPagesValue());
  }

  deleteActionModalHandler = (id) => {
    this.setState(prevState => ({
      deleteActionModal: !prevState.deleteActionModal,
    }), () => {
      if (this.state.deleteActionModal) {
        this.setState({ deleteRowId: id })
        return
      } else {
        this.setState({ deleteRowId: null })
      }
    });
  };

  handleTopicDelete = async () => {
    const { deleteRowId } = this.state
    if (!deleteRowId) return

    this.setState({ deleteLoading: true })
    await this.props.deleteTopic(deleteRowId)

    this.setState({ deletedItem: true, deleteRowId: null }, () => {
      this.handleLoadFaqTable();
      this.deleteActionModalHandler();
      setTimeout(() => {
        this.setState({ deletedItem: false });
      }, 5000);
    });
    this.setState({ deleteLoading: false })
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({ searchString: e.target.value }, () => {
      this.searchTopics();
    });
  };

  searchTopics = () => {
    const { searchString, faqTableData } = this.state;
    const filterBy = (stringPassed) => {
      const lowerCaseStringValue = stringPassed.toLowerCase();
      return (object) =>
          Object.keys(object) &&
          Object.keys(object).some(() => {
            return object.topic.toLowerCase().indexOf(lowerCaseStringValue) !== -1
          });
    };
    const searchedUsers = faqTableData.filter(filterBy(searchString));
    this.setState({ filteredTableData: searchedUsers });
  };

  render() {
    const {
      entriesPerPage,
      currentPageNumber,
      maxPages,
      searchString,
      filteredTableData,
      deletedItem,
      loading,
      deleteLoading
    } = this.state

    let startIndex = (currentPageNumber - 1) * entriesPerPage;
    const lastIndex = startIndex + entriesPerPage;
    let faqs =
        filteredTableData.length <= entriesPerPage ?
            filteredTableData.slice(startIndex, entriesPerPage)
            : filteredTableData.slice(startIndex, lastIndex)
    return (
        <div className="content-management admin-home-dashboard common-dashboard">
          <Container className="p-0">
            <AdminSidebarMenu />
            <TopTab activeTab="FAQs" tabCategoryName="contentManagment" />
            <div className="main-content  bg-white">
              <div className="faqs-listing  bg-grey-6 pt-md-4 mt-4 pl-5">
                <row>
                  {/* <col> */}
                  <div className="faqs-content">
                    <h2 className="fs-34 pb-2 font-weight-semi">FAQs</h2>

                    {/* -----form-search------ */}
                    <FormGroup className="mt-3 search-bar">
                      <FormControl
                          className="text-grey-3 py-3 h-auto"
                          onChange={this.handleSearch}
                          value={searchString}
                          placeholder="Search"
                      />
                    </FormGroup>
                    {
                      deletedItem ?
                          (
                              <p className="fs-14 text-success font-source-sans text-left font-weight-semi">
                                Topic successfully deleted
                              </p>
                          ) : null
                    }
                    {/* -----form-search------ */}

                    {/* -------New Topic----- */}
                    <div className="new-topic-action text-right">
                      <button
                          onClick={() => this.props.history.push('/faqs-new-topic/new-topic')}
                          className="btn btn-info rounded fs-small mt-4 font-weight-bold py-3 px-5"
                      >
                        New Topic
                      </button>
                    </div>
                    {/* -------New Topic----- */}

                    {/* ------ pagination ---------- */}
                    <div className="count-filters-and-pagination d-flex justify-content-end mb-4">
                      <div className="count-filter d-flex align-items-center">
                        <p className="font-smallest opacity-half font-weight-semi mr-2 mb-0">
                          Entries per page :
                        </p>
                        <select
                            id="#"
                            className="border"
                            name="entriesPerPage"
                            value={entriesPerPage}
                            onChange={this.handleCapacityChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                        </select>
                      </div>
                      <Pagination className="table-pagination ml-2">
                        <Pagination.Prev
                            className="prev"
                            onClick={() => this.handlePageNumberChange("decrease")}
                        />
                        <Pagination.Item>{currentPageNumber}</Pagination.Item>
                        <Pagination.Item className="last-item">
                          {maxPages}
                        </Pagination.Item>
                        <Pagination.Next
                            className="next"
                            onClick={() => this.handlePageNumberChange("increase")}
                        />
                      </Pagination>
                    </div>
                    {/* ------ pagination ---------- */}

                    {/* ------topic table---- */}
                    <div className="topic-table mt-5">
                      <Table striped bordered hover responsive className="text-black mt-5">
                        <thead className='bg-primary text-white'>
                        <tr>
                          <th>id</th>
                          <th>Topic</th>
                          <th>Last Modified</th>
                          <th/>
                          <th/>
                        </tr>
                        </thead>
                        <tbody className="font-smallest font-weight-semi">
                        {
                          loading ?
                              (
                                  <div
                                      className="position-absolute"
                                      style={{
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                  >
                                    <div
                                        className="spinner-border position-absolute text-warning"
                                        role="status"
                                    >
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </div>
                              )
                              : faqs !== null &&
                              faqs.map(row =>
                                  <tr>
                                    <td>{row.id}</td>
                                    <td className="topic">{row['topic']}</td>
                                    <td>{row['updated'].toString().substring(0, 10)}</td>
                                    {/* edit topic */}
                                    <td>
                                      <a
                                          onClick={() => this.props.history.push(`/faqs-new-topic/${row.id}`)}
                                      >
                                        <img
                                            src={"/assets/images/add-content.svg"}
                                            alt="editTopic"
                                        />
                                      </a>
                                    </td>
                                    {/* delete topic */}
                                    <td>
                                      <a href="#" onClick={() => this.deleteActionModalHandler(row.id)}>
                                        <img
                                            src={"assets/images/delete-content.svg"}
                                            alt="Delete"
                                        />
                                      </a>
                                    </td>
                                  </tr>
                              )
                        }
                        </tbody>
                      </Table>
                    </div>
                    {/* ------topic table---- */}
                  </div>
                  {/* </col> */}
                </row>
                {/* delete faq popup */}
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.deleteActionModal}
                    onHide={this.deleteActionModalHandler}
                    className="text-center delete-action-popup"
                >
                  <Modal.Body className="delete-action-popup-content">
                    <p className="font-poppins fs-18 text-center font-weight-medium">
                      Are you sure you want to delete this Topic?
                      All the sub-topics within this topic will also be deleted.
                      All data will be lost.
                    </p>
                  </Modal.Body>
                  <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
                    <Button
                        onClick={this.deleteActionModalHandler}
                        variant="outline-dark"
                        className="rounded"
                    >
                      Cancel
                    </Button>
                    <Button
                        className="rounded"
                        variant="danger"
                        onClick={this.handleTopicDelete}
                    >
                      {
                        deleteLoading ?
                            <ContentLoader />
                            : 'Delete'
                      }
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </Container>
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadFaqTable: () => dispatch(load_faq_table_list_action()),
  deleteTopic: (id) => dispatch(delete_faq_topic_action(id))
})

export default connect(null, mapDispatchToProps)(FaqsListing);
