import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import "./UploadVideoContent.scss";
import {
  delete_connected_recipe_action,
  publish_video_action,
  save_video_details_action,
} from "../../../../../../_actions/Admin/uploadVideo.action";
import ContentLoader from "../../../../../Common/Loader/ContentLoader";
import { isEmptyObject } from "../../../../../../_helpers/validators";

class UploadVideoContent extends Component {
  state = {
    loading: false,
    deleteVideoModal: false,
    deleteRecipeModal: false,
    videoName: this.props.videoDetails !== null ? this.props.videoDetails.title : '',
    videoDesc: this.props.videoDetails !== null ? this.props.videoDetails.description : '',
    imageSrc: null,
    imageUrl: this.props.videoDetails !== null ? this.props.videoDetails['title_image'] : null,
    imageName: "",
    videoSrc: null,
    videoFileName: this.props.videoDetails !== null &&
    this.props.videoDetails['video'] !== null ?
        this.props.videoDetails['video'].toString().substr(8)
        : '',
     isPremium: this.props.videoDetails !== null ?
        this.props.videoDetails['is_premium'] : false, 
        //isPremium:false,
    recipeToDelete: "",
    deleteConnectedRecipe: false,
    connectedRecipes: this.props.videoDetails !== null &&
        this.props.videoDetails['connected_recipe'] !== null ?
        this.props.videoDetails['connected_recipe']
        : null,
    videoId: this.props.videoDetails !== null ? this.props.videoDetails.id : null,
    submitTriggered: false,
    isChangesSaved: this.props.videoDetails !== null,
    isSaveVideoClicked: false,
    videoPublished: false,
    publishVideoTriggered: false,
    deleteLoading: false,
    addRecipeClicked: false
  };

  deleteVideoModalHandler = () => {
    const { videoSrc, isPremium } = this.state;
    if (videoSrc && isPremium) {
      this.setState({
        deleteVideoModal: !this.state.deleteVideoModal,
      });
    }
  };

  handleContentSubmit = async (e) => {
    e.preventDefault();
    const { videoName, videoDesc, videoSrc, imageSrc, isPremium } = this.state;
    this.setState({ isSaveVideoClicked: true, submitTriggered: true });

    if (!videoName || !videoDesc || !videoSrc || !imageSrc) return;

    this.setState({ loading: true });

    const detailsForm = new FormData();
    detailsForm.append("title", videoName);
    detailsForm.append("title_image", this.state.imageSrc);
    detailsForm.append("video", this.state.videoSrc);
    detailsForm.append("is_premium", isPremium.toString());
    detailsForm.append("description", videoDesc);

    const saveRes = await this.props.onSaveVideoDetails(detailsForm);
    this.setState({ loading: false, submitTriggered: false });

    //  set content recipe once the video is saved
    if (saveRes && saveRes.data && !isEmptyObject(saveRes.data)) {
      this.setState({
        publishOptionEnabled: true,
        connectedRecipes: saveRes["connected_recipe"],
        videoId: saveRes.id,
        isChangesSaved: true,
      });
      this.props.history.push(`/update-content/video/${saveRes.data.id}`)
      window.location.reload()
    }
  }

  handleUploadFile = (e, type) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file.length === 0) return;

    const uploadedFileType = file.type.toLowerCase();
    if (type === "image" && uploadedFileType.includes("image")) {
      this.setState({
        imageSrc: file,
        imageName: file.name,
      });
    } else if (type === "video" && uploadedFileType.includes("video")) {
      this.setState({
        videoSrc: file,
        videoFileName: file.name,
      });
    } else {
      swal("Wrong File type encountered! Please try again");
      return;
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onAddRecipeClick = () => {
    const { isChangesSaved } = this.state;
    const { videoDetails } = this.props
    this.setState({ addRecipeClicked: true })

    if (!isChangesSaved) {
      this.setState({
        errorText: "You will have to save changes before adding recipes!",
      });
      return;
    }

    if (isChangesSaved && videoDetails !== null) {
      this.props.history.push(`/upload-recipe-instructions/video/${videoDetails.id}/new-recipe`)
    }
  };

  onPublishVideoClick = async () => {
    const { videoName, videoDesc } = this.state;
    if (!videoName || !videoDesc) return;
    this.setState({ publishVideoTriggered: true });

    if (
        this.state.isChangesSaved &&
        (this.props.videoDetails &&
            this.props.videoDetails['connected_recipe'] &&
            this.props.videoDetails['connected_recipe'].length !== 0)
    ) {

      const formData = new FormData();
      formData.append("title", this.state.videoName);
      if (this.state.imageSrc) formData.append("title_image", this.state.imageSrc);
      if (this.state.videoSrc) formData.append("video", this.state.videoSrc);
      formData.append("description", this.state.videoDesc);
      formData.append("is_publish", 'true');
      formData.append("is_premium", this.state.isPremium)

      this.setState({ loading: true });
      const publishVideoRes = await this.props.onPublishVideo(this.props.videoDetails.id, formData);
      this.setState({ loading: false });
      if (publishVideoRes) {
        this.setState({ videoPublished: true });
        swal('Video Published Successfully!')
        this.props.history.push('/upload-content-listing')
      }
    }
  };

  onToggleDeleteConnectedRecipe = (e, recipeId) => {
    this.setState((prevState) => ({
      deleteConnectedRecipe: !prevState.deleteConnectedRecipe,
      recipeToDelete: recipeId,
    }));
  };

  onDeleteConnectedRecipeClick = async () => {
    const { isChangesSaved, recipeToDelete } = this.state;
    if (isChangesSaved && recipeToDelete) {
      this.setState({ deleteLoading: true })
      const data = {
        delete_connected_recipe: [recipeToDelete],
      };
      await this.props.onDeleteConnectedVideoRecipe(this.props.videoDetails.id, data);
      this.setState(prevState => ({ deleteConnectedRecipe: !prevState.deleteConnectedRecipe, deleteLoading: !prevState.deleteLoading }))
      window.location.reload()
    }
  };

  render() {
    const {
      videoName,
      videoDesc,
      videoFileName,
      videoSrc,
      imageName,
      imageSrc,
      loading,
      submitTriggered,
      isPremium,
      isChangesSaved,
      videoPublished,
      publishVideoTriggered,
      addRecipeClicked
    } = this.state;


    return (
      <div className="form-outer bg-grey-6 fs-16 font-weight-semi">
        <Form.Group className="bg-grey-4 p-2 text-black">
          {/* video name input */}
          <Form.Control
            className="font-weight-semi fs-16 text-black"
            type="text"
            name="videoName"
            value={videoName}
            onChange={this.handleChange}
            placeholder="video Name"
            required
          />
          {!videoName && submitTriggered ? (
            <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
              Field required!
            </p>
          ) : null}
        </Form.Group>
        {/* video desc */}
        <div>
          <Form.Group className="bg-grey-4 p-3 ">
            <Form.Label className="mb-2">Description</Form.Label>
            <Form.Control
              className="bg-white"
              as="textarea"
              rows={8}
              name="videoDesc"
              value={videoDesc}
              onChange={this.handleChange}
              required
            />
            {videoDesc && !videoDesc.length && submitTriggered ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                Field required!
              </p>
            ) : null}
          </Form.Group>
          {/* upload image section */}
          <Form.Group className="bg-grey-4 p-3">
            <Form.Label className="mb-2">Images</Form.Label>
            <label
              htmlFor="titleImage"
              className="btn btn-block text-grey-5 bg-white rounded"
            >
              <input
                type="file"
                className="d-none invisible"
                id="titleImage"
                name="videoImage"
                onChange={(e) => this.handleUploadFile(e, 'image')}
                accept="image/*"
                required
              />
              {imageName ? imageName : "Add title Image"}
            </label>
            {
              this.state.imageUrl ?
                  <img
                      src={this.state.imageUrl}
                      alt="upload image"
                  />
                  : null

            }
            {!imageSrc && submitTriggered ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                Field required!
              </p>
            ) : null}
          </Form.Group>
          <div className="form-outer p-3  bg-grey-4 fs-16 font-weight-semi">
            {/* handle video delete action */}
            <div className="d-flex align-items-center justify-content-between">
              <span>Video</span>
            </div>

            <Form.Group>
              <div className="tile-video-checkbox mt-4 fs-14 text-dark-3 d-flex align-items-center justify-content-between">
                <span className="font-weight-bold text-grey-5 ">
                  Title and video
                </span>
                <div className="custom-control custom-checkbox  info">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="premium-content"
                    name="PremiumContent"
                    defaultChecked={this.state.isPremium}
                    onChange={() =>
                      this.setState({ isPremium: !this.state.isPremium })
                    }
                  />
                  <label
                    className="custom-control-label text-grey-5 font-weight-semi ml-2"
                    htmlFor="premium-content"
                  >
                    Check if this is premium content
                  </label>
                </div>
              </div>
            </Form.Group>

            {/* upload video input */}
            <div className="upload-video d-flex align-items-center">
              <label
                htmlFor="videoUpload"
                className="btn btn-outline-dark font-smallest font-weight-semi rounded py-2 px-4"
              >
                <input
                  id="videoUpload"
                  className="d-none"
                  type="file"
                  onChange={(e) => this.handleUploadFile(e, 'video')}
                  accept="video/*"
                  required
                />
                {
                  videoFileName
                      ? videoFileName
                      : "Name_of_video"
                }
              </label>
              <img
                src={"/assets/images/upload-video.svg"}
                alt="upload-video"
                className="ml-2"
              />
            </div>
            {!videoSrc && submitTriggered ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                Field required!
              </p>
            ) : null}
            <hr className="bg-info my-4" />

            <Form.Group>
              <Form.Label className="mb-4 fs-14 font-weight-bold text-grey-5">
                Recipe
              </Form.Label>
              <ul>
                {
                  this.props.videoDetails &&
                  this.props.videoDetails['connected_recipe'] &&
                  this.props.videoDetails['connected_recipe'].length ?
                  this.props.videoDetails['connected_recipe'].map((recipe) => (
                      <li
                        className="d-flex mb-3"
                        key={recipe.id}
                      >
                        <button
                            onClick={() => this.props.history.push(`/edit-recipe-instructions/video/${this.props.videoDetails.id}/edit-recipe/${recipe.id}`)}
                            className="btn btn-block bg-white font-weight-semi py-2 mr-2 border-info rounded text-grey-5 text-left"
                        >
                          {recipe.name}
                        </button>
                        <a
                          href="#"
                          className="ml-2 d-flex"
                          onClick={(e) =>
                            this.onToggleDeleteConnectedRecipe(e, recipe.id)
                          }
                        >
                          <img
                            src={"/assets/images/delete-content.svg"}
                            alt="delete"
                          />
                        </a>
                      </li>
                    )) : null
                }
              </ul>
            </Form.Group>

            {/* add more button */}
            <a
              onClick={this.onAddRecipeClick}
              className="text-info d-block mt-4"
            >
              +{" "}
              {this.props.videoDetails &&
              this.props.videoDetails['connected_recipe'] &&
              this.props.videoDetails['connected_recipe'].length ?
                "Add more"
                : "Add Recipe"}
            </a>
            {
              !isChangesSaved && addRecipeClicked ?
                  <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                    You will have to save changes before adding recipes!
                  </p>
                  : videoPublished ? (
                      <p className="fs-14 text-success font-source-sans text-left font-weight-semi">
                        Video published successfully!
                      </p>
                  ) : addRecipeClicked && isChangesSaved ?
                  <p className="fs-14 text-success font-source-sans text-left font-weight-semi">
                    Video saved successfully!
                  </p>
                  : null
            }
          </div>

          {publishVideoTriggered && !this.props.videoDetails['connected_recipe'].length ? (
            <p className="fs-14 text-danger font-source-sans text-left font-weight-semi">
              You need add recipe first to publish!
            </p>
          ) : null}
          <div className="action fs-14 d-flex justify-content-end mt-3 flex-wrap">
            {
              isChangesSaved ?
                  <button
                      onClick={() => {
                        localStorage.removeItem("contentRecipe");
                        this.props.history.push('/upload-content-listing')
                      }}
                      className="btn btn-outline-dark py-2 px-4 font-weight-400 my-2 rounded"
                  >
                    Exit
                  </button>
                  : null
            }
            {
              isChangesSaved ?
                  <button
                      onClick={this.onPublishVideoClick}
                      className="btn btn-success py-2 px-4 font-weight-semi ml-3 my-2 rounded"
                      disabled={
                        !videoName ||
                        !videoDesc ||
                        isChangesSaved &&
                        this.props &&
                        this.props.videoDetails &&
                        this.props.videoDetails.connected_recipe &&
                        this.props.videoDetails.connected_recipe.length === 0 ||
                        !this.props.videoDetails
                      }
                  >
                    {loading ? (
                        <ContentLoader color="text-white" />
                    ) : (
                        "Publish Video"
                    )}
                  </button>
                  : null
            }
            {
              !isChangesSaved ?
                  <button
                      onClick={() =>  this.props.history.push('/upload-content-listing')}
                      className="btn btn-outline-dark py-2 px-3 ml-3 font-weight-400 my-2 rounded"
                  >
                    Cancel
                  </button>
                  : null
            }
            {/* save and exit is not in use */}
            {/*<button className="btn btn-warning py-2 px-3 font-weight-semi ml-3 my-2 rounded">Save & Exit</button>*/}
            {
              !isChangesSaved ?
                  <button
                      className="btn btn-info py-2 px-3 font-weight-semi ml-3 my-2 rounded"
                      disabled={ !videoName || !imageSrc && !this.state.imageUrl }
                      onClick={this.handleContentSubmit}
                  >
                    {loading ? (
                        <ContentLoader color="text-white" />
                    ) : (
                        "Save Changes"
                    )}
                  </button>
                  : null
            }
          </div>
        </div>
        {/* delete action modal for premium content*/}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={isPremium && this.state.deleteVideoModal}
          onHide={this.deleteVideoModalHandler}
          className="text-center delete-action-popup"
        >
          <Modal.Body className="delete-action-popup-content">
            <p className="font-poppins fs-18 text-center font-weight-medium">
              You cannot delete this video since it has been marked as premium
              content.
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
            <Button
              onClick={this.deleteVideoModalHandler}
              variant="outline-dark"
              className="rounded"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete action modal for premium content Ends */}

        {/* delete connected recipe modal*/}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.deleteConnectedRecipe}
          onHide={this.onToggleDeleteConnectedRecipe}
          className="text-center delete-action-popup"
        >
          <Modal.Body className="delete-action-popup-content">
            <p className="font-poppins fs-18 text-center font-weight-medium">
              Are you sure you want to delete the recipe instructions?
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
            <Button
              onClick={this.onToggleDeleteConnectedRecipe}
              variant="outline-dark"
              className="rounded"
            >
              Cancel
            </Button>
            <Button
              onClick={this.onDeleteConnectedRecipeClick}
              variant="danger"
              className="rounded"
            >
              {
                this.state.deleteLoading ?
                    <ContentLoader />
                    : 'Delete'
              }
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete recipe instructions popup ends */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    publishedContent: state.publish_video_content_reducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPublishVideo: (id, data) => dispatch(publish_video_action(id, data)),
  onSaveVideoDetails: (data) => dispatch(save_video_details_action(data)),
  onDeleteConnectedVideoRecipe: (id, data) => dispatch(delete_connected_recipe_action(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadVideoContent);