
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import "./UploadCarouselSlide.scss";

class UploadCarouselSlide extends Component {
    render() {
        return (
            <div className="UploadCarouselSlide-preview mx-2 position-relative bg-grey-2">
            <img src={this.props.imgPreview} className="border-60 img-fit" />
           
              <Button variant="outline-info" className="bg-white rounded position-absolute" >{this.props.buttonTitle}</Button>
            
          </div>
        )
    }
}
export default UploadCarouselSlide;