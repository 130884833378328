import React, { Component } from "react";
import {
  Container,
  FormControl,
  Table,
  Button,
  Modal,
  FormGroup,
  Row,
  Col, Pagination,
} from "react-bootstrap";
import { connect } from  'react-redux'
import swal from 'sweetalert'
import ReactHtmlparser from 'react-html-parser'

import DateRange from "../../../Common/DatePicker/DateRange";
import TextEditor from "../../../Common/TextEditor/TextEditor";
import "./Announcements.scss";
import TopTab from "../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../common/SidebarMenu/AdminSidebarMenu";
import {
  add_new_announcement_action,
  delete_announcement_action,
  get_announcement_table_list_action, get_single_announcement_details_action, update_announcement_details_action
} from "../../../../_actions/Admin";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import moment from "moment";

const toolbarConfig = {
  options: ["inline", "colorPicker"],
  inline: {
    inDropdown: false,
    bold: { className: "bg-grey-7 px-2" },
    italic: { className: "bg-grey-7 px-2" },
    underline: { className: "bg-grey-7 px-2" },
    strikethrough: { className: "d-none" },
    monospace: { className: "d-none" },
    superscript: { className: "d-none" },
    subscript: { className: "d-none" },
  },
  colorPicker: {
    colors: [
      "rgba(36, 170, 225, 1)",
      "rgba(135, 68, 154, 1)",
      "rgba(255, 164, 0, 1)",
    ],
    className: "bg-grey-7 px-2",
  },
};

class Announcements extends Component {
  state = {
    deleteActionModal: false,
    announcementLoading: false,
    announcementTableData: [],
    filteredTableData: [],
    displayAddNewAnnouncementSection: false,
    searchString: '',
    deleteAnnouncementId: null,
    deleteLoading: false,
    itemDeleted: false,
    announcementToEditDetails: {
      editorHtmlText: '',
      startDate: null,
      endDate: null,
    },
    addAnnouncementClicked: false,
    errorText: '',
    successText: '',
    addAnnouncementLoading: false,
    mountAnnouncementForEditLoading: false,
    editingAnnouncement: false,
    dataBeforeEdit: null,
    isDataSame: true,
    entriesPerPage: 10,
    currentPageNumber: 1,
    maxPages: 1,
    prevAnnouncementsLink: null,
    nextAnnouncementsLink: null
  };

  componentDidMount() { this.loadAnnouncementData() }

  loadAnnouncementData = async () => {
    this.setState({ announcementLoading: true })

    const loadAnnouncementDataRes = await this.props.getAnnouncementData("admin")
    if (loadAnnouncementDataRes &&
        loadAnnouncementDataRes.type &&
        loadAnnouncementDataRes.type === 'admin'
    ) {
      if (loadAnnouncementDataRes && loadAnnouncementDataRes.response && loadAnnouncementDataRes.response.data) {
        this.setState({
          announcementTableData: loadAnnouncementDataRes.response.data.reverse(),
          filteredTableData: loadAnnouncementDataRes.response.data.reverse()
        }, () => {
          this.setMaxPagesValue()
        })
      } else if (loadAnnouncementDataRes && loadAnnouncementDataRes.response && loadAnnouncementDataRes.response.msg) {
        swal(loadAnnouncementDataRes.response.msg)
      }
    }

    this.setState({ announcementLoading: false })
  }

  setMaxPagesValue = () => {
    // calculate max number of pages required to accommodate all the user details
    const { entriesPerPage, announcementTableData } = this.state;
    const remainder = announcementTableData.length % entriesPerPage;
    const quotient = announcementTableData.length / entriesPerPage;

    let maxPagesNumber = remainder > 0 ?
        parseInt((quotient + 1).toString(), 10)
        : quotient;
    this.setState({ maxPages: maxPagesNumber }, () => {
      // manage current page number if it exceeds the max page capacity.
      const { currentPageNumber, maxPages } = this.state;
      if (currentPageNumber > maxPages)
        this.setState({ currentPageNumber: this.state.maxPages });
      if (currentPageNumber < 1) this.setState({ currentPageNumber: 1 });
    });
  };

  handlePageNumberChange = async (type) => {
    const {
      currentPageNumber,
      prevAnnouncementsLink,
      nextAnnouncementsLink,
      announcementTableData,
      maxPages,
    } = this.state;
    if (type === "decrease") {
      if (currentPageNumber > 1)
          // decrease page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
      else {
        // hit the prev data api to fetch data of users
        if (prevAnnouncementsLink !== null) {
          this.setState({ announcementLoading: true });
          const prevUserDataRes = await this.props.getPrevUserDetails(
              prevAnnouncementsLink
          );
          if (prevUserDataRes) {
            const prevUserDataArray = prevUserDataRes.results;
            prevUserDataArray.push(...announcementTableData);
            this.setState({
              announcementTableData: prevUserDataArray,
              prevAnnouncementsLink: prevUserDataRes.previous,
            });
          }
          this.setState({ announcementLoading: false });
        }
      }
    } else if (type === "increase") {
      if (currentPageNumber < maxPages)
          // increase page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
      else {
        // hit the next data api to fetch data of users
        if (nextAnnouncementsLink !== null) {
          this.setState({ loading: true });
          const nextUserDataRes = await this.props.getNextUserDetails(
              nextAnnouncementsLink
          );
          if (nextUserDataRes) {
            const nextUserDataArray = announcementTableData;
            nextUserDataArray.push(...nextUserDataRes.results);
            this.setState({
              announcementTableData: nextUserDataArray,
              nextAnnouncementsLink: nextUserDataRes.next,
            });
          }
          this.setState({ announcementLoading: false });
        }
      }
    }
  };

  handleCapacityChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({ [name]: parseInt(value) }, () => this.setMaxPagesValue());
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({ searchString: e.target.value }, () => {
      this.searchAnnouncement();
    });
  }

  searchAnnouncement = () => {
    const { searchString, announcementTableData } = this.state

    const filterBy = (stringPassed) => {
      const lowerCaseStringValue = stringPassed.toLowerCase();
      return (object) =>
          Object.keys(object) &&
          Object.keys(object).some(() => {
            return object.content.toLowerCase().indexOf(lowerCaseStringValue) !== -1
          });
    };
    const searchedAnnouncements = announcementTableData.filter(filterBy(searchString))
    this.setState({ filteredTableData: searchedAnnouncements })
  }

  deleteActionModalHandler = (id) => {
    this.setState(prevState => ({
      deleteActionModal: !prevState.deleteActionModal,
    }), () => {
      if (this.state.deleteActionModal)
        this.setState({ deleteAnnouncementId: id })
      else
        this.setState({ deleteAnnouncementId: null })
    });
  };

  handleAnnouncementDelete = async () => {
    const { deleteAnnouncementId, dataBeforeEdit } = this.state
    if (!deleteAnnouncementId) return

    this.setState({ deleteLoading: true })

    const deleteRes = await this.props.deleteAnnouncement(deleteAnnouncementId)
    if (dataBeforeEdit && dataBeforeEdit.id === deleteAnnouncementId){
      await this.addNewAnnouncement()
      this.setState({ displayAddNewAnnouncementSection: false })
    }

    if (deleteRes && deleteRes.status) {
      this.setState({ itemDeleted: true, deleteAnnouncementId: null }, () => {
        this.loadAnnouncementData()
        this.deleteActionModalHandler()
        setTimeout(() => {
          this.setState({ itemDeleted: false })
        }, 5000)
      })
    }

    this.setState({ deleteLoading: false })
  }

  handleUpdateAnnouncementContent = async (htmlData) => {
    this.setState({ announcementToEditDetails: {
        ...this.state.announcementToEditDetails, editorHtmlText: htmlData
      }
    }, () => {
      this.checkForChanges()
    })
  }

  updateDate = async (data) => {
    if (data && data.startValue) {
      await this.setState({ announcementToEditDetails: {
          ...this.state.announcementToEditDetails, startDate: data.startValue['_d'].toLocaleDateString("en-029")
        }
      }, () => {
        this.checkForChanges()
      })
    }

    if (data && data.endValue) {
      await this.setState({ announcementToEditDetails: {
          ...this.state.announcementToEditDetails, endDate: data.endValue['_d'].toLocaleDateString("en-029")
        }
      }, () => {
        this.checkForChanges()
      })
    }
  }

  checkForChanges = () => {
    const { editingAnnouncement, announcementToEditDetails, dataBeforeEdit } = this.state
    if (!editingAnnouncement) return

    if (editingAnnouncement) {
      const checkForChangesArray = []
      if (dataBeforeEdit !== null) {
        checkForChangesArray.push(dataBeforeEdit.content && dataBeforeEdit.content === announcementToEditDetails.editorHtmlText)
        checkForChangesArray.push(dataBeforeEdit.end_date && dataBeforeEdit.end_date === new Date(announcementToEditDetails.endDate).toLocaleDateString("en-ZA").toString().replaceAll("/", "-"))
        checkForChangesArray.push(dataBeforeEdit.start_date && dataBeforeEdit.start_date === new Date(announcementToEditDetails.startDate).toLocaleDateString("en-ZA").toString().replaceAll("/", "-"))
      }

      if (checkForChangesArray.includes(false))
        this.setState({ isDataSame: false })
      else
        this.setState({ isDataSame: true })
    }
  }

  handleAnnouncementSubmit = async () => {
    const { announcementToEditDetails } = this.state
    this.setState({ addAnnouncementClicked: true })
    if (
        announcementToEditDetails.editorHtmlText === '' ||
        announcementToEditDetails.editorHtmlText === "<p></p>" ||
        announcementToEditDetails.endDate === null ||
        announcementToEditDetails.startDate === null
    ) return

    this.setState({ addAnnouncementLoading: true })

    const postData = {
      content: announcementToEditDetails.editorHtmlText,
      start_date: new Date(announcementToEditDetails.startDate).toLocaleDateString("en-ZA").replaceAll("/", "-") + "T00:00:00",
      end_date: new Date(announcementToEditDetails.endDate).toLocaleDateString("en-ZA").replaceAll("/", "-") + "T00:00:00"
    }

    const addAnnouncementRes = await this.props.addNewAnnouncement(postData)

    this.setState({ displayAddNewAnnouncementSection: false })
    if (addAnnouncementRes) {
      if (addAnnouncementRes.status) {
        if (addAnnouncementRes.msg)
        this.setState({ successText: addAnnouncementRes.msg })
      } else if (addAnnouncementRes.msg) {
        this.setState({ errorText: addAnnouncementRes.msg })
      }
    }
    setTimeout(() => {
      this.setState({ successText: '', errorText: '' })
    }, 5000)
    this.setState({ addAnnouncementLoading: false })
    await this.loadAnnouncementData()

  }

  mountAnnouncementDataForEditing = async (announcementId) => {
    if (announcementId) {
      this.setState({ mountAnnouncementForEditLoading: true, displayAddNewAnnouncementSection: true })

      const getAnnouncementDetailsRes = await this.props.getSingleAnnouncementDetails(announcementId)
      if (getAnnouncementDetailsRes) {
        this.setState({ announcementToEditDetails: {
          ...this.state.announcementToEditDetails,
            editorHtmlText: getAnnouncementDetailsRes['content'],
            startDate: getAnnouncementDetailsRes['start_date'],
            endDate: getAnnouncementDetailsRes['end_date']
          },
          editingAnnouncement: true,
          dataBeforeEdit: getAnnouncementDetailsRes
        })
      } else {
        this.setState({ errorText: 'Unable to find Announcement details!' })
      }
      setTimeout(() => {
        this.setState({ errorText: '' })
      }, 4000)

      this.setState({ mountAnnouncementForEditLoading: false })
    }
  }

  updateAnnouncementDetails = async () => {
    const { announcementToEditDetails, dataBeforeEdit } = this.state
    this.setState({ addAnnouncementClicked: true })

    if (
        announcementToEditDetails.editorHtmlText === '' ||
        announcementToEditDetails.editorHtmlText === "<p></p>" ||
        announcementToEditDetails.endDate === null ||
        announcementToEditDetails.startDate === null
    ) return

    this.setState({ addAnnouncementLoading: true })

    const data = {
      announcementId: dataBeforeEdit.id,
      postData: {
        content: announcementToEditDetails.editorHtmlText,
        start_date: new Date(announcementToEditDetails.startDate).toLocaleDateString("en-ZA").replaceAll("/", "-") + "T00:00:00",
        end_date: new Date(announcementToEditDetails.endDate).toLocaleDateString("en-ZA").replaceAll("/", "-") + "T00:00:00"
      }
    }
    const updateAnnouncementRes = await this.props.updateAnnouncement(data)
    this.setState({ displayAddNewAnnouncementSection: false })
    if (updateAnnouncementRes && updateAnnouncementRes.msg)
      this.setState({ successText: updateAnnouncementRes.msg })
    else
      this.setState({ errorText: 'Update Unsuccessful!' })

    setTimeout(() => {
      this.setState({ successText: '', errorText: '' })
    }, 5000)

    this.setState({ addAnnouncementLoading: false })
    await this.loadAnnouncementData()
  }

  addNewAnnouncement = async () => {

    await this.setState({ mountAnnouncementForEditLoading: true })

    await this.setState(prevState => ({
      displayAddNewAnnouncementSection: false,
      dataBeforeEdit: null,
      editingAnnouncement: false,
      isDataSame: true,
      announcementToEditDetails: {
        ...this.state.announcementToEditDetails,
        editorHtmlText: '',
        startDate: null,
        endDate: null,
      }
    }))

    await this.setState({ mountAnnouncementForEditLoading: false, displayAddNewAnnouncementSection: true })
  }

  render() {
    const {
      itemDeleted,
      deleteLoading,
      searchString,
      filteredTableData,
      displayAddNewAnnouncementSection,
      announcementLoading,
      announcementToEditDetails,
      addAnnouncementClicked,
      addAnnouncementLoading,
      successText,
      mountAnnouncementForEditLoading,
      editingAnnouncement,
      isDataSame,
      entriesPerPage,
      currentPageNumber,
      maxPages
    } = this.state

    let startIndex = (currentPageNumber - 1) * entriesPerPage;
    const lastIndex = startIndex + entriesPerPage;
    let announcements =
        filteredTableData.length <= entriesPerPage ?
            filteredTableData.slice(startIndex, entriesPerPage)
            : filteredTableData.slice(startIndex, lastIndex)

    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu />
          <TopTab 
           activeTab="Announcement"
           tabCategoryName="contentManagment"
          />
          <div className="main-content  bg-white">
            <div className="user-data announcements-wrapper bg-grey-6  px-lg-5">
              
                <div className="upload-content-wrapper bg-white">
                  <h2 className="fs-34 mb-4">Announcement</h2>
                  {/* add announcement section */}
                  {
                    displayAddNewAnnouncementSection ?
                        mountAnnouncementForEditLoading ?
                            <ContentLoader color='text-warning' />
                            : <div className="top-section">
                              <Row className="mb-5 px-3">
                                <Col md={6} className="bg-grey-3">
                                  {/* text editor */}
                                  <TextEditor
                                      htmlData={announcementToEditDetails.editorHtmlText}
                                      getHtmlData={(data) => this.handleUpdateAnnouncementContent(data)}
                                      toobarSetting={toolbarConfig}
                                  />
                                  {
                                    addAnnouncementClicked &&
                                    (announcementToEditDetails.editorHtmlText === '' ||
                                        announcementToEditDetails.editorHtmlText === "<p></p>" ||
                                        announcementToEditDetails.endDate === null ||
                                        announcementToEditDetails.startDate === null) ?
                                        <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                          All fields are required!
                                        </p>
                                        : null
                                  }
                                </Col>
                                <Col
                                    md={6}
                                    className="d-flex justify-content-between flex-column"
                                >
                                  {/* datepicker */}
                                  <DateRange
                                      updateDate={(dateData) => this.updateDate(dateData)}
                                      startDate={this.state.announcementToEditDetails.startDate}
                                      endDate={this.state.announcementToEditDetails.endDate}
                                  />
                                  {/* add announcement action-btn */}
                                  <div className="action-btns">
                                    <Button
                                        className="rounded mr-3"
                                        onClick={editingAnnouncement ?
                                            this.updateAnnouncementDetails
                                            :this.handleAnnouncementSubmit}
                                        variant="info"
                                        disabled={editingAnnouncement && isDataSame}
                                    >
                                      {
                                        addAnnouncementLoading ?
                                            <ContentLoader />
                                            : 'Announce'
                                      }

                                    </Button>
                                    <Button
                                        className="rounded"
                                        variant="outline-dark"
                                        onClick={() => this.setState({ displayAddNewAnnouncementSection: false })}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                        : null
                  }

                  {/* search bar ========== */}
                  <div className="search-and-btn mb-3">
                    <FormGroup className="mb-0 mr-2 search-bar w-100">
                      <FormControl
                          className="text-grey-3 h-100"
                          onChange={this.handleSearch}
                          value={searchString}
                          placeholder="Search"
                      />
                    </FormGroup>
                    {
                      successText ?
                          <p className="fs-14 text-success font-source-sans text-left font-weight-semi">
                            {successText}
                          </p>
                          : null
                    }

                    {
                      itemDeleted ?
                          <p className="fs-14 text-success font-source-sans text-left font-weight-semi">
                            Announcement deleted successfully!
                          </p>
                          : null
                    }

                    <div className="new-content  mt-4 text-right">

                        <button
                            onClick={this.addNewAnnouncement}
                            className="btn btn-info py-3  rounded"
                        >
                          New announcement
                        </button>

                    </div>
                  </div>
                  {/* top-section-ends */}

                  {/* ------ pagination ---------- */}
                  <div className="count-filters-and-pagination d-flex justify-content-end mb-4">
                    <div className="count-filter d-flex align-items-center">
                      <p className="font-smallest opacity-half font-weight-semi mr-2 mb-0">
                        Entries per page :
                      </p>
                      <select
                          id="#"
                          className="border"
                          name="entriesPerPage"
                          value={entriesPerPage}
                          onChange={this.handleCapacityChange}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                      </select>
                    </div>
                    <Pagination className="table-pagination ml-2">
                      <Pagination.Prev
                          className="prev"
                          onClick={() => this.handlePageNumberChange("decrease")}
                      />
                      <Pagination.Item>{currentPageNumber}</Pagination.Item>
                      <Pagination.Item className="last-item">
                        {maxPages}
                      </Pagination.Item>
                      <Pagination.Next
                          className="next"
                          onClick={() => this.handlePageNumberChange("increase")}
                      />
                    </Pagination>
                  </div>
                  {/* ------ pagination ---------- */}

                  {/* announcement listing table */}
                  <Table striped bordered hover responsive className="text-black mt-5">
                    <thead className='bg-primary text-white'>
                      <tr>
                        <th>id</th>
                        <th>Announcement</th>
                        <th>Upload date</th>
                        <th>End Date</th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody className="font-smallest font-weight-semi">
                    {
                      announcementLoading ?
                          <div
                              className="position-absolute"
                              style={{
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                          >
                            <div
                                className="spinner-border position-absolute text-warning"
                                role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                          : announcements !== null &&
                          announcements.map(announcement =>
                              <tr key={announcement.id} className="rows">
                                <td className="font-weight-400">{announcement.id}</td>
                                <td className="annoucement-desc">{ ReactHtmlparser(announcement.content)}</td>
                                <td>{moment(announcement['start_date']).format('MM-DD-YYYY')}</td>
                                <td>{moment(announcement['end_date']).format('MM-DD-YYYY')}</td>
                                {/* edit icon */}
                                <td className="edit">
                                  <a onClick={() => this.mountAnnouncementDataForEditing(announcement.id)}>
                                    <img
                                        src={"assets/images/add-content.svg"}
                                        alt="Write"
                                    />
                                  </a>
                                </td>
                                {/* delete icon */}
                                <td>
                                  <a onClick={() => this.deleteActionModalHandler(announcement.id)}>
                                    <img
                                        src={"assets/images/delete-content.svg"}
                                        alt="Delete"
                                    />
                                  </a>
                                </td>
                              </tr>
                          )
                    }
                    </tbody>
                  </Table>
                </div>

              {/* delete announcement popup */}
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.deleteActionModal}
                onHide={this.deleteActionModalHandler}
                className="text-center delete-action-popup"
              >
                <Modal.Body className="delete-action-popup-content">
                  <p className="font-poppins fs-18 text-center font-weight-medium">
                    Are you sure you want to delete this Announcement?
                    All data will be lost.
                  </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
                  <Button
                    onClick={this.deleteActionModalHandler}
                    variant="outline-dark"
                    className="rounded"
                  >
                    Cancel
                  </Button>
                  <Button
                      variant="danger"
                      className="rounded"
                      onClick={this.handleAnnouncementDelete}
                  >
                    {
                      deleteLoading ?
                          <ContentLoader />
                          : 'Delete'
                    }
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAnnouncementData: (userType) => dispatch(get_announcement_table_list_action(userType)),
  deleteAnnouncement: (id) => dispatch(delete_announcement_action(id)),
  addNewAnnouncement: (data) => dispatch(add_new_announcement_action(data)),
  getSingleAnnouncementDetails: (id) => dispatch(get_single_announcement_details_action(id)),
  updateAnnouncement: (data) => dispatch(update_announcement_details_action(data))
})

export default connect(null, mapDispatchToProps)(Announcements);
