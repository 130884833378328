import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./UserProfile.scss";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import { connect } from "react-redux";
import { get_profile_content_action } from "../../../_actions/User/profile.action";
import {logout_user_action, set_client_token_for_notification_action} from '../../../_actions/Common';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
    };
  }

  routeHandler = () => {
    this.props.history.push("/edit-profile", {
      user: this.state.content,
    });
  };
  componentWillMount() {
    this.props.getProfileContent();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.getProfileContentData !== this.props.getProfileContentData) {
      this.setState({
        content: nextProps.getProfileContentData,
      });
    }
  };

  handleLogout = async (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.access_token) {
        const token = {
            refresh: user['refresh_token']
        }

        if (token) {
            // let fcmToken = await getToken()
            // if (fcmToken) {
            //     const data = {
            //         registration_id: fcmToken,
            //         type: "web",
            //         device_id: fcmToken.substr(0, 150),
            //         active: false
            //     }
            //     await this.props.setClientForNotification(data)
            // }
        }

        const logoutRes = await this.props.userLogout(token)
        this.props.history.push('/login')
    }
    this.setState({ loading: false })
}



  render() {
    let profileData = this.state && this.state.content;
    // first letter for first name
    let firstLetterFirstName =
      profileData && profileData.first_name && profileData.first_name.charAt(0);

    // first letter for last name
    let firstLetterLastName =
      profileData && profileData.last_name && profileData.last_name.charAt(0);

    return (
      <div className="common-dashboard user-profile">
        <Container className="p-0">
          <SidebarMenu />
          <div className="main-content bg-white">
            <Header />
            <div className="edit-profile-wrapper pt-5">
              <a
                href="/user-home-page"
                className="align-items-center d-none d-md-flex mt-5"
              >
                <img
                  src="/assets/images/back-arrow.svg"
                  alt="back"
                  className="ml-md-n2"
                />
                <span className="text-dark fs-16 font-weight-bold ml-1">
                  Back
                </span>
              </a>
              <Row className="d-flex align-items-center justify-content-center">
                <Col md={6} lg={4} className="mt-5 mt-md-0">
                  <div className="profile-content text-center mt-4">
                    <div className="profile-section font-weight-bold ">
                      <div className="profile-img m-auto">

                      {profileData && profileData.profile_image ? (
                          <img
                          src={profileData && profileData.profile_image}
                          alt="Profile Picture"
                          className="profile-pic rounded-circle img-fit  w-100 position-relative"
                          />
                        ) : (
                          <span className="user-initials bg-primary text-white d-flex align-items-center justify-content-center font-weight-bold rounded-circle">
                            {firstLetterFirstName} {firstLetterLastName}
                          </span>
                        )}


                      </div>
                      <h4 className="full-name fs-18 mt-4">
                        {profileData && profileData.first_name}{" "}
                        {profileData && profileData.last_name}
                      </h4>
                      <p className="fs-14 text-grey-4 mb-1">
                        {profileData && profileData.email}
                      </p>
                      <a
                        href=""
                        onClick={this.routeHandler}
                        className="fs-14 text-grey-3"
                      >
                        Edit Profile <span className="fa fa-pencil ml-2"></span>
                      </a>
                    </div>

                    <div className="option-wraper font-poppins fs-14 font-weight-semi mt-4  mb-4 mb-md-0 pb-2">
                      <a
                        href="/user-posts"
                        variant=""
                        className="py-2 px-4 bg-grey-4  text-left border-6 d-block text-dark"
                        block
                      >
                        My posts
                      </a>
                      <a
                        href="/saved-post"
                        variant=""
                        className="py-2 px-4 bg-grey-4  mt-3 text-left  border-6 d-block text-dark"
                        block
                      >
                        Saved Posts
                      </a>
                      <a
                        href="/grocery-lists"
                        variant=""
                        className="py-2 px-4 bg-grey-4  mt-3 text-left  border-6 d-block text-dark"
                        block
                      >
                        My Grocery Lists
                      </a>
                 
                      <a
                        href="/saved-content"
                        variant=""
                        className="py-2 px-4 bg-grey-4  mt-3 text-left  border-6 d-block text-dark"
                        block
                      >
                        Saved Content
                      </a>
                      <a
                        href="/help"
                        variant=""
                        className="py-2 px-4 bg-grey-4   mt-3 text-left  border-6 d-block text-dark"
                        block
                      >
                        Help
                      </a>
                      {profileData && profileData.membership_type == null ? 
                      <a
                        href="/premium-plans"  
                        variant=""
                        className="py-2 px-4 bg-grey-4  mt-3 text-primary text-left  border-6 d-block"
                        block
                      >
                        Premium Plans
                      </a>
                      : 
                      profileData && profileData.membership_type && profileData.membership_type.membership && profileData.membership_type.membership.membership_type == "monthly" ||
                      profileData && profileData.membership_type && profileData.membership_type.membership && profileData.membership_type.membership.membership_type == "annual"  ? 
                      <a
                        href="/user-plan-details"
                        variant=""
                        className="py-2 px-4 bg-grey-4  mt-3 text-primary text-left  border-6 d-block"
                        block
                      >
                        Premium Plans
                      </a>
                      : null
                      }

                      <a
                        href="#"
                        variant=""
                        className="py-2 px-4 bg-grey-4  mt-3 text-warning text-left  border-6 d-block"
                        block
                      >
                        Orders
                      </a>

                      <Button
                       onClick={this.handleLogout}
                        variant="warning"
                        className="py-2 mt-3 font-weight-bold font-montserrat  border-6 d-block"
                        block
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userLogout: (data) => dispatch(logout_user_action(data)),
  getProfileContent: () => dispatch(get_profile_content_action()),
  setClientForNotification: (data) => dispatch(set_client_token_for_notification_action(data))

});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
