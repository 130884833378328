export const groceryConstants = {
    GET_GROCERY_LIST_ACTION_REQUEST: 'GET_GROCERY_LIST_ACTION_REQUEST',
    GET_GROCERY_LIST_ACTION_SUCCESS: 'GET_GROCERY_LIST_ACTION_SUCCESS',
    GET_GROCERY_LIST_ACTION_FAILURE: 'GET_GROCERY_LIST_ACTION_FAILURE',

    GET_GROCERY_LIST_BYID_ACTION_REQUEST:"GET_GROCERY_LIST_BYID_ACTION_REQUEST",
    GET_GROCERY_LIST_BYID_ACTION_SUCCESS:"GET_GROCERY_LIST_BYID_ACTION_SUCCESS",
    GET_GROCERY_LIST_BYID_ACTION_FAILURE:"GET_GROCERY_LIST_BYID_ACTION_FAILURE",

    ADD_GROCERY_LIST_ACTION_REQUEST:'ADD_GROCERY_LIST_ACTION_REQUEST',
    ADD_GROCERY_LIST_ACTION_SUCCESS:'ADD_GROCERY_LIST_ACTION_SUCCESS',
    ADD_GROCERY_LIST_ACTION_FAILURE:'ADD_GROCERY_LIST_ACTION_FAILURE',

    ADD_ITEM_TO_GROCERY_LIST_ACTION_REQUEST:"ADD_ITEM_TO_GROCERY_LIST_ACTION_REQUEST",
    ADD_ITEM_TO_GROCERY_LIST_ACTION_SUCCESS:"ADD_ITEM_TO_GROCERY_LIST_ACTION_SUCCESS",
    ADD_ITEM_TO_GROCERY_LIST_ACTION_FAILURE:"ADD_ITEM_TO_GROCERY_LIST_ACTION_FAILURE",

    DELETE_ITEM_FROM_GROCERY_LIST_ACTION_REQUEST:"DELETE_ITEM_FROM_GROCERY_LIST_ACTION_REQUEST",
    DELETE_ITEM_FROM_GROCERY_LIST_ACTION_SUCCESS:"DELETE_ITEM_FROM_GROCERY_LIST_ACTION_SUCCESS",
    DELETE_ITEM_FROM_GROCERY_LIST_ACTION_FAILURE:"DELETE_ITEM_FROM_GROCERY_LIST_ACTION_FAILURE",

    TOGGELE_CHECKBOX_REQUEST:"TOGGELE_CHECKBOX_REQUEST",
    TOGGELE_CHECKBOX_SUCCESS:"TOGGELE_CHECKBOX_SUCCESS",
    TOGGELE_CHECKBOX_FAILURE:"TOGGELE_CHECKBOX_FAILURE",

    REMOVE_GROCERY_LIST_ACTION_REQUEST:"REMOVE_GROCERY_LIST_ACTION_REQUEST",
    REMOVE_GROCERY_LIST_ACTION_SUCCESS:"REMOVE_GROCERY_LIST_ACTION_SUCCESS",
    REMOVE_GROCERY_LIST_ACTION_FAILURE:"REMOVE_GROCERY_LIST_ACTION_FAILURE",

    ADD_GROCERY_ITEM_TO_LIST_ACTION_REQUEST:"ADD_GROCERY_ITEM_TO_LIST_ACTION_REQUEST",
    ADD_GROCERY_ITEM_TO_LIST_ACTION_SUCCESS:"ADD_GROCERY_ITEM_TO_LIST_ACTION_SUCCESS",
    ADD_GROCERY_ITEM_TO_LIST_ACTION_FAILURE:"ADD_GROCERY_ITEM_TO_LIST_ACTION_FAILURE"
}
