import React, { Component } from "react";
import { InputGroup, FormControl, Form } from "react-bootstrap";
import { connect } from "react-redux";
import "./CommentsSearch.scss";

import { add_comment_action } from "../../../../../../_actions/User/post.action";
import { get_profile_content_action } from "../../../../../../_actions/User/";
import Avatar from "../../../../../Common/Avatar";

class CommentsSearch extends Component {
  state = {
    image: null,
    mediaImg: null,
    mediaImgName: "",
    comment: "",
    addCommentUnsuccessful: false,
    userInfo: {},
  };

  addImageToComment = async (e) => {
    const file = e.target.files[0];

    this.setState((prevState) => ({
      image: file ? URL.createObjectURL(file) : prevState.image,
      mediaImg: file ? file : prevState.mediaImg,
      mediaImgName: file && file.name ? file.name : prevState.mediaImgName,
    }));
  };

  changeComment = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  submitComment = async (e) => {
    e.preventDefault();
    const { comment, mediaImgName, mediaImg } = this.state;

    if (!comment) return;

    const commentForm = new FormData();
    let contentType;
    if (!this.props.post["content_type"]) {
      contentType = "user_post";
      commentForm.append(
        "post",
        this.props && this.props.post && this.props.post.id
      );
      commentForm.append("comment_type", "user_post");
    } else {
      switch (this.props.post["content_type"]) {
        case "youtube-recipe":
          contentType = "youtube_recipe";
          commentForm.append(
            "youtube_recipe",
            this.props && this.props.post && this.props.post.id
          );
          break;
        case "episode":
          contentType = "episode";
          commentForm.append(
            "episode",
            this.props && this.props.post && this.props.post.id
          );
          break;
        case "video":
          contentType = "video";
          commentForm.append(
            "video",
            this.props && this.props.post && this.props.post.id
          );
          break;
        case "user_post":
          contentType = "user_post";
          commentForm.append(
            "user_post",
            this.props && this.props.post && this.props.post.id
          );
          break;
        default:
      }
      commentForm.append("comment_type", contentType);
    }
    commentForm.append("text", comment);
    if (mediaImg && mediaImgName) commentForm.append("image", mediaImg);

    const commentPostRes = await this.props.addCommentAction(commentForm);
    if (
      commentPostRes &&
      commentPostRes.msg &&
      commentPostRes.msg.toLowerCase().includes("successfully created")
    ) {
      this.setState(
        {
          image: null,
          mediaImg: null,
          mediaImgName: "",
          comment: "",
          addCommentUnsuccessful: false,
        },
        () => {
          this.props.refreshComments();
        }
      );
    } else {
      this.setState({ addCommentUnsuccessful: true });
    }
  };
  componentWillMount() {
    this.props.getProfileContent();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.getProfileContentData !== this.props.getProfileContentData) {
      this.setState({
        userInfo: nextProps.getProfileContentData,
      });
    }
  };
  render() {
    const { addCommentUnsuccessful, mediaImgName, comment, image } = this.state;
    let profileData = this.state && this.state.userInfo;
    // first letter for first name
    let firstLetterFirstName =
      profileData && profileData.first_name && profileData.first_name.charAt(0);

    // first letter for last name
    let firstLetterLastName =
      profileData && profileData.last_name && profileData.last_name.charAt(0);

    return (
      <>
        <div className="comment-search-wrapper d-flex align-items-center mt-5">
          <Avatar size={34} containerStyles={{marginRight: 8}} image={profileData && profileData.profile_image} username={profileData && profileData.first_name} />
          <Form onSubmit={this.submitComment}>
            <InputGroup className="comment-input bg-grey-5 border-radius-10 overflow-hidden align-items-center">
              <FormControl
                className="comment-box h-100 border-0 bg-transparent"
                placeholder="add comment"
                name="comment"
                value={comment}
                onChange={this.changeComment}
                autoComplete="off"
              />

              <label className="cursor-pointer p-2" htmlFor="attachment">
                <input
                  type="file"
                  className="d-none"
                  id="attachment"
                  accept="image/*"
                  onChange={this.addImageToComment}
                />
                <img
                  className="align-middle"
                  src={"/assets/images/file.png"}
                  alt="attachment"
                />
              </label>
            </InputGroup>

            {/* <InputGroup>
            <label className="cursor-pointer p-2" htmlFor="attachment">
              <input
                type="file"
                className="d-none"
                id="attachment"
                accept="image/*"
                onChange={this.addImageToComment}
              />
              {mediaImgName ? mediaImgName : null}
              show photo before upload
              {image ? (
                <img
                  src={image}
                  id="photo-id"
                  className="add-photo"
                  style={{
                    height: "100px",
                  }}
                  alt={mediaImgName}
                />
              ) : (
                <img
                  className="align-middle"
                  src={"/assets/images/file.png"}
                  alt="attachment"
                />
              )}
            </label>
          </InputGroup> */}
          </Form>
        </div>
        <div className="comment-img mt-3 ml-5 pl-2">
          {/* show photo before upload */}
          {image ? (
            <img
              src={image}
              id="photo-id"
              className="add-photo"
              style={{
                maxHeight: "100px",
              }}
              alt={mediaImgName}
            />
          ) : null}
          <p className=" font-smallest">
            {" "}
            {mediaImgName ? mediaImgName : null}
          </p>
        </div>
        {addCommentUnsuccessful ? (
          <p className="text-danger">
            <span className="fa fa-meh-o"></span> Unable to add comment! Try
            again
          </p>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statusDetail: state.get_status_by_id_reducer.data,
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addCommentAction: (data) => dispatch(add_comment_action(data)),
  getProfileContent: () => dispatch(get_profile_content_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsSearch);
