import React, { Component } from "react";
import { Container, Button, Row, Col,Modal } from "react-bootstrap";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import Header from "../common/Header/Header";
import "./UserPlanDetails.scss";
import { connect } from "react-redux";
import * as PaymentActions from "../../../_actions/User/payment.action";
import { get_profile_content_action } from "../../../_actions/User";
import moment from "moment";
import swal from 'sweetalert';
import ContentLoader from "../../Common/Loader/ContentLoader";

class UserPlanDetails extends Component {
  constructor(props){
    super(props);
    this.state={
         upgradeAmount:null,
         monthly:null,
         cancelSubscriptionModal: false,
         cancelSubscriptionLoading:false,
         loading:false
    }
  }
  componentDidMount = async ()=>{
    await this.props.getProfileContent();
    const {getProfileContentData}= this.props ;
    let membership_type = getProfileContentData && getProfileContentData.membership_type
    if(membership_type && membership_type.membership && membership_type.membership.membership_type == "monthly")
    {
      const res = await this.props.getUpgradeAmount();
      let amt = res && res.data && res.data.data;
      let remainingAmt = amt/100;
      this.setState({upgradeAmount:remainingAmt})
    }else{
      return
    }
       
  }
  handleBuy=async(plan)=>{
        this.setState({loading:!this.state.loading})
        let {upgradeAmount}=this.state;
        var plan={
          plan:plan,
          amount:plan=='upgrade'? upgradeAmount : null
        }
        localStorage.setItem("plan",JSON.stringify(plan))
        //this.props.history.push("/payment");

        const response = await this.props.upgradePayement();
        this.setState({loading:!this.state.loading})
        if (response) {
          await this.componentDidMount();
          swal(response && response.data && response.data.msg); 
        } 
  }
  routeHandler = () => {
    this.props.history.push('/user-profile');
  };


  cancelSubscriptionModalHandler = () => {
    this.setState(prevState => ({
      cancelSubscriptionModal: !prevState.cancelSubscriptionModal,
    }))
  };
  cancelSubscription=async ()=>{
    this.setState({cancelSubscriptionLoading:!this.state.cancelSubscriptionLoading})
    await this.props.cancelSubscription();
    this.setState({cancelSubscriptionLoading:!this.state.cancelSubscriptionLoading})
    this.cancelSubscriptionModalHandler();
    await this.props.getProfileContent();

  }
  render() {
    let {upgradeAmount,monthly,loading,cancelSubscriptionLoading}=this.state;
    const {getProfileContentData}= this.props ;
    let membership_type = getProfileContentData && getProfileContentData.membership_type
    
    return (
      <div className="premium-plans-wrapper common-dashboard">
        <Container className="p-0">
          <SidebarMenu />
          <Header />
          <div className="premium-plans-content bg-white main-content">
            <a href="" class="d-none d-md-flex align-items-center "
            onClick={this.routeHandler}
            >
              <img
                src="/assets/images/back-arrow.svg"
                alt="back"
                class="ml-n2"
              />
              <span class="text-dark fs-16 font-weight-bold ml-1">Back</span>
            </a>
            <h1 className="my-4">
              Premium plans for <br /> Premium Features
            </h1>

            <Row className="mt-5">
              <Col md={6} className="mb-4">
                <div className="plan-box cursor-pointer bg-grey rounded px-3 py-4 h-100 d-flex flex-column justify-content-between"
                >
                  <h1 className="mb-2">Currently subscribed to {' '}
                  {membership_type && membership_type.membership && membership_type.membership.membership_type} {' '}
                  plan</h1>
                  <h1 className="text-primary">$ {membership_type && membership_type.membership && membership_type.membership.price/100}</h1>
                  
                  <h5>
                       {membership_type && membership_type.end_date 
                            ? (<div>
                               Valid till {' '} 
                              { moment(membership_type && membership_type.end_date).format('LL')}
                              </div> )
                            : ""}
               
                          {membership_type && membership_type.recurring == true ? 
                            <>
                            <div className="my-2">
                             Automatic {membership_type && membership_type.membership && membership_type.membership.membership_type} {' '}
                              renewal has been applied for this plan.
                            </div>
                            <div className="btn btn-primary py-1 px-3 my-2 ml-sm-2 rounded" onClick={this.cancelSubscriptionModalHandler}>
                            Cancel {' '}
                            {membership_type && membership_type.membership && membership_type.membership.membership_type} {' '}
                            renewal
                            </div>
                            </>:null}
                  </h5>
                </div>
              </Col>

{membership_type && membership_type.membership && membership_type.membership.membership_type == "monthly" ?
        (
         <Col md={6} className="mb-4">
     
          <div onClick={()=>this.handleBuy("upgrade")} className="plan-box cursor-pointer bg-primary text-white rounded px-3 py-4 h-100 d-flex flex-column justify-content-between">
          {loading ? (
                          <ContentLoader color="text-white mx-3 my-3" />
                        ) 
                  : (<>
            <h1 className="mb-2 text-white" >
            Upgrade to Yearly plan
            </h1>
            <h1 className="mb-2 text-white">${upgradeAmount}</h1>
            </>
            )}
          </div>
           
   
         </Col>
       ) 
  :
  null
  } 
            
            </Row>
          </div>
        </Container>
       
        {/**************cancel renewal modal start********** */}
        <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cancelSubscriptionModal}
                onHide={this.cancelSubscriptionModalHandler}
                className="text-center delete-action-popup"
              >
                <Modal.Body className="delete-action-popup-content">
                  <p className="font-poppins fs-18 text-center font-weight-medium">
                    Are you sure you want to Cancel this renewal?
                    
                  </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
                  <Button
                    onClick={this.cancelSubscriptionModalHandler}
                    variant="outline-dark"
                    className="rounded"
                  >
                    No
                  </Button>
                  <Button
                      variant="danger"
                      className="rounded"
                      onClick={this.cancelSubscription}
                  >
                    {
                      cancelSubscriptionLoading ?
                          <ContentLoader />
                          : 'Yes'
                    } 
                  </Button>
                </Modal.Footer>
              </Modal>
            
            {/********cancel subscription ends here********/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getUpgradeAmount : ()=> dispatch(PaymentActions.getupgrade_amount_payment_action()),
  cancelSubscription:()=>dispatch(PaymentActions.cancel_subscription_action()),
  getProfileContent: () => dispatch(get_profile_content_action()),
  upgradePayement: () => dispatch(PaymentActions.upgrade_payment_action()),
});

export default connect(mapStateToProps,mapDispatchToProps)(UserPlanDetails);
