import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  FormControl,
  Button,
  Modal,
  Table,
  FormGroup,
  Pagination,
} from "react-bootstrap";

import "./UploadContentListing.scss";
import {
  delete_recipe_listing_action,
  get_recipe_listing_action,
} from "../../../../../_actions/Admin";
import ContentLoader from "../../../../Common/Loader/ContentLoader";
import moment from "moment";
import TopTab from "../../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../../common/SidebarMenu/AdminSidebarMenu";

class UploadContentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      content: [],
      deleteContent: {},
      deletedItem: false,
      isApiLoading: true,
      filteredContent: [],
      deleteActionModal: false,
      pagination: {
        entriesPerPage: 10,
        current: 1,
        pageSize: 10,
        maxPages: 0,
        lastPageItemIndex: 0,
        pageSizeOptions: ["10", "20", "30", "40"],
      },
    };
  }

  componentDidMount() {
    this.getListedContent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.listedContent !== this.props.listedContent) {
      if (
        this.props.listedContent.recipeListing &&
        this.props.listedContent.recipeListing.data &&
        this.props.listedContent.recipeListing.data.results &&
        this.props.listedContent.recipeListing.data.results.length
      ) {
        this.setState(
          (prevState) => ({
            content: this.props.listedContent.recipeListing.data.results,
            filteredContent:
              this.props.listedContent.recipeListing.data.results,
            pagination: {
              ...prevState.pagination,
              lastPageItemIndex: this.state.pagination.pageSize,
              total: this.props.listedContent.recipeListing.data.results.length,
            },
          }),
          () => this.setMaxPagesValue()
        );
      }
    }
    if (prevState.search !== this.state.search) {
      this.setState({
        filteredContent: this.state.search.length
          ? this.state.content.filter((contentItem) =>
              contentItem.content.title
                .toLowerCase()
                .includes(this.state.search.toLowerCase())
            )
          : this.state.content,
      });
    }
  }

  getListedContent = async () => {
    this.setState({ isApiLoading: true });
    await this.props.getContent();
    this.setState({ isApiLoading: false });
  };

  deleteActionModalHandler = (content) => {
    this.setState(
      {
        deleteActionModal: !this.state.deleteActionModal,
      },
      () => {
        if (this.state.deleteActionModal) {
          this.setState({ deleteContent: content });
          return;
        } else {
          this.setState({ deleteContent: {} });
        }
      }
    );
  };

  deleteContentHandler = async () => {
    const { deleteContent } = this.state;
    if (deleteContent) {
      const id = deleteContent.id;
      const type = deleteContent.content_type;
      await this.props.deleteContent(id, type);
      this.setState({ deletedItem: true, content: [] }, () => {
        this.getListedContent();
        this.deleteActionModalHandler();
        setTimeout(() => {
          this.setState({ deletedItem: false });
        }, 5000);
      });
    }
  };

  onEditClickHandler = (content) => {
    let type =
      content.content_type === "youtube-recipe"
        ? "recipe"
        : content.content_type === "video"
        ? "video"
        : "show";
    this.props.history.push(`/update-content/${type}/${content.id}`);
  };

  handleActiveContentChange = () => {
    this.props.history.push("/upload-new-content");
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  setMaxPagesValue = () => {
    // calculate max number of pages required to accommodate all the user details
    const { pagination, content } = this.state;
    const remainder = content.length % pagination.entriesPerPage;
    const quotient = content.length / pagination.entriesPerPage;

    let maxPagesNumber =
      remainder > 0 ? parseInt((quotient + 1).toString(), 10) : quotient;
    this.setState(
      (prevState) => ({
        pagination: { ...prevState.pagination, maxPages: maxPagesNumber },
      }),
      () => {
        // manage current page number if it exceeds the max page capacity.
        const { pagination } = this.state;
        if (pagination.current > pagination.maxPages)
          this.setState((prevState) => ({
            pagination: {
              ...prevState.pagination,
              maxPages: this.state.maxPages,
              current: 1,
            },
          }));
      }
    );
  };

  handlePageNumberChange = async (type) => {
    const { pagination } = this.state;
    if (type === "decrease") {
      if (pagination.current > 1)
        // decrease page number while it is greater than 0
        this.setState((prevState) => ({
          pagination: {
            ...prevState.pagination,
            current: prevState.pagination.current - 1,
          },
        }));
    } else if (type === "increase") {
      if (pagination.current < pagination.maxPages)
        // increase page number while it is greater than 0
        this.setState((prevState) => ({
          pagination: {
            ...prevState.pagination,
            current: prevState.pagination.current + 1,
          },
        }));
    }
  };

  onHandlePagination = (currentPage) => {
    this.setState((prevState) => ({
      pagination: { ...prevState.pagination, current: currentPage },
      filteredContent: this.state.content.splice(11, 5),
    }));
  };

  handleCapacityChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: parseInt(value) }, () => this.setMaxPagesValue());
  };

  render() {
    const {
      search,
      pagination,
      deletedItem,
      isApiLoading,
      deleteContent,
      filteredContent,
    } = this.state;

    let startIndex = (pagination.current - 1) * pagination.entriesPerPage;
    const lastIndex = startIndex + pagination.entriesPerPage;
    let content =
      filteredContent.length <= pagination.entriesPerPage
        ? filteredContent.slice(startIndex, pagination.entriesPerPage)
        : filteredContent.slice(startIndex, lastIndex);

    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu />
          <TopTab
            activeTab="Upload Content"
            tabCategoryName="contentManagment"
          />
          <div className="main-content  bg-white">
            <div className="user-data upload-content bg-grey-6 pt-md-4 mt-4">
              <Container className="p-0">
                <div className="upload-content-wrapper bg-white">
                  {/* search and  ========== */}
                  <div className="search-and-btn d-flex mb-3">
                    <FormGroup className="mb-0 mr-2 search-bar flex-grow-1">
                      <FormControl
                        value={search}
                        placeholder="Search"
                        className="text-grey-3 h-100"
                        onChange={(e) =>
                          this.handleChange("search", e.target.value)
                        }
                      />
                    </FormGroup>
                    <div className="new-content  ml-auto ">
                      <a className="">
                        <button
                          className="btn btn-info py-2  rounded"
                          onClick={() => {
                            this.props.history.push(
                              "/upload-new-content/new-content"
                            );
                          }}
                        >
                          New Content
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* ------ pagination ---------- */}
                  <div className="count-filters-and-pagination d-flex justify-content-end mb-4">
                    <div className="count-filter d-flex align-items-center">
                      <p className="font-smallest opacity-half font-weight-semi mr-2 mb-0">
                        Entries per page :
                      </p>
                      <select
                        id="#"
                        className="border"
                        name="entriesPerPage"
                        value={pagination.entriesPerPage}
                        onChange={this.handleCapacityChange}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                      </select>
                    </div>
                    <Pagination className="table-pagination ml-2">
                      <Pagination.Prev
                        className="prev"
                        onClick={() => this.handlePageNumberChange("decrease")}
                      />
                      <Pagination.Item>{pagination.current}</Pagination.Item>
                      <Pagination.Item className="last-item">
                        {pagination.maxPages}
                      </Pagination.Item>
                      <Pagination.Next
                        className="next"
                        onClick={() => this.handlePageNumberChange("increase")}
                      />
                    </Pagination>
                  </div>
                  {/* ------ pagination ---------- */}

                  {deletedItem ? (
                    <p className="fs-14 text-success-2 font-source-sans text-left font-weight-semi">
                      Content deleted successfully as requested!
                    </p>
                  ) : null}
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className="text-black mt-4"
                  >
                    <thead className="bg-primary text-white">
                      <tr className="text-nowrap">
                        <th>#</th>
                        <th>Title Image</th>
                        <th>Content Name</th>
                        <th>Type</th>
                        <th>No of Episode</th>
                        <th>Last Modified</th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody className="font-smallest font-weight-semi">
                      {isApiLoading ? (
                        <div
                          className="position-absolute"
                          style={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <div
                            className="spinner-border position-absolute text-warning"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : content.length ? (
                        content.map((contentItem, index) => (
                          <tr key={contentItem.id}>
                            <td className="font-weight-400">{index + 1}</td>
                            <td className="title-image-wrapper">
                              <img
                                src={
                                  contentItem.content["title_image"]
                                    ? contentItem.content["title_image"]
                                    : "assets/images/title-image-1.svg"
                                }
                                alt="image"
                                className="title-image"
                              />
                            </td>
                            <td className="w-25">
                              {contentItem.content.title}
                            </td>
                            <td>
                              {contentItem.content.content_type ===
                              "youtube-recipe"
                                ? "Recipe"
                                : contentItem.content.content_type === "video"
                                ? "Video"
                                : "Show"}
                            </td>
                            {/* THIS WILL BE DYNAMIC WHEN WE"LL ADD SHOWS */}
                            <td>
                              {contentItem.content.content_type === "show"
                                ? contentItem.content.episodes_count
                                : "NA"}
                            </td>
                            <td>
                              {moment(contentItem.content.updated).format(
                                "MM/DD/YYYY"
                              )}
                            </td>
                            <td>
                              <a
                                onClick={() =>
                                  this.onEditClickHandler(contentItem.content)
                                }
                              >
                                <img
                                  src={"assets/images/add-content.svg"}
                                  alt="Write"
                                />
                              </a>
                            </td>
                            <td>
                              <a
                                onClick={() =>
                                  this.deleteActionModalHandler(
                                    contentItem.content
                                  )
                                }
                              >
                                <img
                                  src="assets/images/delete-content.svg"
                                  alt="Delete"
                                />
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>No Data Found</div>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Container>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.deleteActionModal}
                onHide={this.deleteActionModalHandler}
                className="text-center delete-action-popup"
              >
                <Modal.Body className="delete-action-popup-content">
                  <p className="font-poppins fs-18 text-center font-weight-medium">
                    Are you sure you want to delete this Show? All the episodes
                    within the show will also be deleted. All data will be lost.
                  </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
                  <Button
                    onClick={this.deleteActionModalHandler}
                    variant="outline-dark"
                    className="rounded"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    className="rounded"
                    onClick={this.deleteContentHandler}
                  >
                    Delete{" "}
                    {deleteContent.content_type === "youtube-recipe"
                      ? "Recipe"
                      : deleteContent.content_type === "video"
                      ? "video"
                      : "Show"}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listedContent: state.get_recipe_listing_reducer,
    deletedContent: state.delete_recipe_listing_reducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getContent: () => dispatch(get_recipe_listing_action()),
  deleteContent: (id, type) => dispatch(delete_recipe_listing_action(id, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadContentListing);

