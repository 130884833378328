import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../common/Header/Header';
import SidebarMenu from '../common/SidebarMenu/SidebarMenu';
import Collection from './Collection/Collection';
import FlashSale from './FlashSale/FlashSale';
import './ShoppingHome.scss';

import Client from 'shopify-buy';
import config from '../../../Config/config';

const client = Client.buildClient({
    storefrontAccessToken: config.shopifyStore.storefrontAccessToken,
    domain: config.shopifyStore.domain,
});
class ShoppingHome extends Component {

    render() {
 
        return (
            <div className="common-dashboard shopping-home-wrapper">
                <Container className="p-0">
                    <SidebarMenu />
                    <div className="main-content bg-white position-relative">
                        <Header />
                        {/* flash sale slider */}
                        <FlashSale history={this.props.history}/>

                        {/* collections slider */}
                        <Collection client={client} history={this.props.history}/>
                    </div>
                </Container>
            </div>
        )
    }
}

export default ShoppingHome

