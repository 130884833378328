import React, { Component } from "react";
import Slider from "react-slick";
import { Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PopularShows.scss";
import ContentLoader from "../../../Common/Loader/ContentLoader";
class PopularShows extends Component {
  routeHandler = (showId) => {
    this.props.history.push(`/view-show/${showId}`);
  };
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            adaptiveHeight: true,
          },
        },
      ],
    };
    return (
      <div className="popular-shows-slider pt-sm-4  mx-n1">
        <h3 className="font-weight-semi d-inline-block pt-4  pt-4 mb-3  pl-2">
          Popular shows
        </h3>
        <a
          href={"/all-shows"}
          className="fs-18 font-weight-semi font-poppins text-primary pl-5"
        >
          view all
          <img src={"/assets/images/next-btn.svg"} className="ml-3" alt="" />
        </a>
        {this.props && this.props.showList && this.props.showList.length ? (
          <Slider {...settings}>
            {this.props.loading ? (
              <ContentLoader color="text-warning" />
            ) : (
              this.props &&
              this.props.showList &&
              this.props.showList.map((sDetails) => (
                <div
                  key={sDetails.id}
                  className="popular-shows-preview cursor-pointer px-2"
                >
                  <div
                    className="img-box border-6"
                    onClick={() =>
                      this.routeHandler(sDetails["show_details"].id)
                    }
                  >
                    <img
                      src={sDetails["cover_image"]}
                      alt="slider-img-1"
                      className="img-fit w-100"
                    />
                  </div>
                  <h4 className="title font-weight-semi my-2">
                    {sDetails["show_details"]["title"]}
                  </h4>
                </div>
              ))
            )}
          </Slider>
        ) : (
          <div className="col-12 mt-3">
            <img src={"/assets/images/noshows-home.png"} alt="" />
            <h4>No Shows Popular Yet</h4>
          </div>
        )}
      </div>
    );
  }
}
export default PopularShows;
