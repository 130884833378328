import React, { Component } from "react";

import { connect } from "react-redux";
import { Container } from "react-bootstrap";

import TopTab from "../../../common/TopTab/TopTab";
import UploadShows from "./UploadShows/UploadShows";
import UploadVideos from "./UploadVideos/UploadVideos";
import UploadRecipes from "./UploadRecipes/UploadRecipes";
import SearchBar from "../../../common/SearchBar/SearchBar";
import UploadEpisodes from "./UploadEpisodes/UploadEpisodes";
import AdminSidebarMenu from "../../../common/SidebarMenu/AdminSidebarMenu";
import { get_all_carousel_items_action } from "../../../../../_actions/Admin/carousel.action";
import { LoaderBasic } from "../../../common/Loader";

const tempDataWithPriorityForCarousel = [
  { priority: 1 },
  { priority: 2 },
  { priority: 3 },
  { priority: 4 },
  { priority: 5 },
];

const tempDataWithPriority = [
  { priority: 1 },
  { priority: 2 },
  { priority: 3 },
  { priority: 4 },
  { priority: 5 },
  { priority: 6 },
  { priority: 7 },
  { priority: 8 },
];

class CarouselHome extends Component {
  state = {
    carouselItems: [],
    isApiLoading: false,
    shows: tempDataWithPriority,
    videos: tempDataWithPriority,
    recipes: tempDataWithPriority,
    updates: tempDataWithPriorityForCarousel,
  };

  componentDidMount() {
    this.getAllCarouselItemsApi();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.carouselItems !== this.props.carouselItems) {
      if (this.props.carouselItems.length) {
        let PopularShows = [...this.state.shows];
        let PopularVideos = [...this.state.videos];
        let LatestUpdates = [...this.state.updates];
        let PopularRecipes = [...this.state.recipes];

        this.props.carouselItems.forEach((carouselItem, i) => {
          if (carouselItem.row_type == "youtube_recipe") {
            PopularRecipes = [...PopularRecipes, { ...carouselItem }];
          }
          if (carouselItem.row_type == "update") {
            LatestUpdates = [...LatestUpdates, { ...carouselItem }];
          }
          if (carouselItem.row_type == "video") {
            PopularVideos = [...PopularVideos, { ...carouselItem }];
          }
          if (carouselItem.row_type == "show") {
            PopularShows = [...PopularShows, { ...carouselItem }];
          }
        });

        this.setState((prevState) => ({
          shows: PopularShows.length
            ? this.filterDataWithPriority(PopularShows)
            : [...prevState.shows],
          videos: PopularVideos.length
            ? this.filterDataWithPriority(PopularVideos)
            : [...prevState.videos],
          updates: LatestUpdates.length
            ? this.filterDataWithPriority(LatestUpdates)
            : [...prevState.updates],
          recipes: PopularRecipes.length
            ? this.filterDataWithPriority(PopularRecipes)
            : [...prevState.recipes],
        }));
      }
    }
  }

  filterDataWithPriority = (data) => {
    let newArray = [];

    // Declaring an empty object
    let uniqueObject = {};
    let objTitle;

    // Loop for the array elements
    for (let i in data) {
      // Extracting the title
      objTitle = data[i]["priority"];

      // Using the title as the index
      uniqueObject[objTitle] = data[i];
    }

    // Loop to push unique object into array
    for (let j in uniqueObject) {
      newArray.push(uniqueObject[j]);
    }
    return newArray;
  };

  getAllCarouselItemsApi = async () => {
    this.setState({ isApiLoading: true });
    await this.props.getAllCarouselItems();
    this.setState({ isApiLoading: false });
  };

  onAddUpdateClickHandler = (type, priority, dashboardItemType) => {
    this.props.history.push(`/carousel-listing-management/${type}`, {
      priority,
      dashboardItemType 
    })
  }

  render() {
    
    const { shows, videos, recipes, updates, isApiLoading } = this.state;

    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu />
          <TopTab activeTab="Carousel" tabCategoryName="contentManagment" />
          <div className="main-content  bg-white">
            {isApiLoading ? (
              <LoaderBasic />
            ) : (
              <div className="carousel-wrapper mt-0">
                <SearchBar />
                <UploadEpisodes updates={updates} addUpdateClickHandler={this.onAddUpdateClickHandler} />
                <UploadVideos videos={videos} addUpdateClickHandler={this.onAddUpdateClickHandler} />
                <UploadShows shows={shows} addUpdateClickHandler={this.onAddUpdateClickHandler} />
                <UploadRecipes recipes={recipes} addUpdateClickHandler={this.onAddUpdateClickHandler} />
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.carousel_items_reducer.error,
    carouselItems: state.carousel_items_reducer.carouselItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllCarouselItems: () => dispatch(get_all_carousel_items_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselHome);
