import React, { Component } from "react";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import "./CommentsCard.scss";
import {
  delete_comment_by_id_action,
  report_item_action,
} from "../../../../../../_actions/User/post.action";
import swal from "sweetalert";
import Avatar from "../../../../../Common/Avatar";
import { isEmptyObject } from "../../../../../../_helpers/validators";
class CommentsCard extends Component {
  state = {
    deleteActionModal: false,
    reportActionModal: false,
    reportListingActionModal: false,
    reportCommentId: null,
    deleteCommentId: null,
    deleteCommentError: false,
    reportReason: null,
  };

  deleteActionModalHandler = () => {
    this.setState({
      deleteActionModal: !this.state.deleteActionModal,
    });
  };

  deleteAction = async () => {
    try {
      await this.props.deleteCommentById(this.state.deleteCommentId);
      this.props.refreshComments();
      this.deleteActionModalHandler();
    } catch (e) {
      this.setState({ deleteCommentError: true });
    }
  };

  reportActionModalHandler = () => {
    this.setState({
      reportActionModal: !this.state.reportActionModal,
    });
  };
  reportListingActionModalHandler = () => {
    this.setState({
      reportListingActionModal: !this.state.reportListingActionModal,
      reportActionModal: !this.state.reportActionModal,
    });
  };

  reportPost = async () => {
    let data = {
      comment: this.state.reportCommentId,
      report_type: this.state.reportReason,
    };
    const res = await this.props.reportItem(data);
    await this.reportActionModalHandler();
    swal(res && res.msg);
  };

  reportReason = (reason) => {
    this.setState({ reportReason: reason });
  };

  render() {
    const { comments } = this.props;
    const currentUserId = JSON.parse(localStorage.getItem("user"))["user"].id;
    return (
      <>
        {comments && comments.length > 0 ? (
          comments.map((comment) => (
            <div key={comment.id}>
              <div className="comment-card-box d-flex flex-column mt-4 pr-4">
                <div className="top d-flex align-items-center justify-content-between">
                  <div className="user-info flex-grow-1 d-flex align-items-center">
                    {!isEmptyObject(comment.user_details)  ? (
                      <Avatar
                        size={34}
                        containerStyles={{marginRight: 8}}
                        image={comment.user_details.profile_image}
                        username={comment.user_details.first_name}
                      />
                    ) : null}
                    <span className="font-smallest font-weight-bold">
                      {comment &&
                        comment["user_details"] &&
                        comment["user_details"]["first_name"]}
                    </span>
                  </div>
                  {/* dropdown */}

                  <div className="report  position-relative">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=" "
                        id="dropdown-basic"
                        className="p-2"
                      >
                        <img
                          src={"/assets/images/menu.png"}
                          alt="report-action"
                          className="cursor-pointer"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="bg-grey-2">
                        {comment["user_details"].id === currentUserId ? (
                          <Dropdown.Item
                            href="#"
                            className="bg-grey-2 py-0 pt-0"
                            onClick={() => {
                              this.setState({ deleteCommentId: comment.id });
                              this.deleteActionModalHandler();
                            }}
                          >
                            <p className="font-weight-bold mb-0 text-capitalize">
                              {this.props.delete}
                            </p>
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            href="#"
                            className="bg-grey-2 py-0 pt-0"
                            onClick={() => {
                              this.setState({ reportCommentId: comment.id });
                              this.reportActionModalHandler();
                            }}
                          >
                            <p className="font-weight-bold mb-0 text-capitalize">
                              {this.props.report}
                            </p>
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {/*            : null
                                        }
 */}
                </div>
                <p className="user-comment font-smallest text-grey-4 font-weight-semi px-3 pt-2">
                  {comment.text}
                </p>
                {comment && comment["image"] ? (
                  <img
                    className="comment-img mt-3 img-fit pl-3"
                    src={comment["image"]}
                    alt=""
                  />
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <p className="text-warning">No comments found! Be the first one.</p>
        )}

        {/* Report Post Popup ======== */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.reportActionModal}
          onHide={this.reportActionModalHandler}
          className="text-center delete-action-popup px-4"
        >
          <Modal.Body className="delete-action-popup-content pt-3">
            <h6 className="text-center py-3">
              Are you sure you want to Report this comment ?
            </h6>
          </Modal.Body>
          <Modal.Footer className="justify-content-md-between justify-content-center border-top-0 fs-14 font-weight-semi px-5 pb-3">
            <Button
              onClick={() => {
                this.reportListingActionModalHandler();
                this.reportActionModalHandler();
              }}
              variant="btn btn-dark"
              className="rounded px-5 py-2"
            >
              Yes
            </Button>
            <Button
              variant="btn btn-light"
              className="rounded px-5 py-2"
              onClick={this.reportActionModalHandler}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Report Post Popup ======== */}

        {/* Report listing Post Popup ======== */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.reportListingActionModal}
          onHide={this.reportListingActionModalHandler}
          className="text-center delete-action-popup px-4"
        >
          <Modal.Body className="delete-action-popup-content pt-3">
            <h6 className="text-center py-3">
              Why are you reporting this post?
            </h6>

            <ul>
              <li
                className="cursor-pointer"
                onClick={async () => {
                  await this.reportReason("It's spam");
                  await this.reportPost();
                  await this.reportListingActionModalHandler();
                }}
              >
                It's spam
              </li>
              <li
                className="cursor-pointer"
                onClick={async () => {
                  await this.reportReason("Intellectual property violation");
                  await this.reportPost();
                  await this.reportListingActionModalHandler();
                }}
              >
                Intellectual property violation
              </li>
              <li
                className="cursor-pointer"
                onClick={async () => {
                  await this.reportReason("Bullying or harassment");
                  await this.reportPost();
                  await this.reportListingActionModalHandler();
                }}
              >
                Bullying or harassment
              </li>
              <li
                className="cursor-pointer"
                onClick={async () => {
                  await this.reportReason("Nudity or sexual activity");
                  await this.reportPost();
                  await this.reportListingActionModalHandler();
                }}
              >
                Nudity or sexual activity
              </li>
              <li
                className="cursor-pointer"
                onClick={async () => {
                  await this.reportReason("Something else");
                  await this.reportPost();
                  await this.reportListingActionModalHandler();
                }}
              >
                Something else
              </li>
            </ul>
            <br />
          </Modal.Body>
        </Modal>
        {/* Report listing Post Popup ======== */}

        {/* Delete post and comment popup */}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.deleteActionModal}
          onHide={this.deleteActionModalHandler}
          className="text-center delete-action-popup px-4"
        >
          <Modal.Body className="delete-action-popup-content pt-3">
            <h6 className="text-center py-3">
              Are you sure you want to delete this comment from your post?
            </h6>
          </Modal.Body>
          <Modal.Footer className="justify-content-between justify-content-center border-top-0 fs-14 font-weight-semi px-5 pb-3">
            <Button
              className="rounded px-5 py-2"
              onClick={() => this.deleteAction()}
              variant="btn btn-dark"
            >
              Yes
            </Button>
            <Button
              className="rounded px-5 py-2"
              onClick={this.deleteActionModalHandler}
              variant="btn btn-light"
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteCommentById: (id) => dispatch(delete_comment_by_id_action(id)),
  reportItem: (data) => dispatch(report_item_action(data)),
});

export default connect(null, mapDispatchToProps)(CommentsCard);
