import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./UploadVideos.scss";
import UploadCarouselSlide from '../../../../common/UploadCarouselSlide/UploadCarouselSlide';


const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,

      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        adaptiveHeight: true,

      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

export default ({ videos, addUpdateClickHandler }) => {
  return (
    <div className="popular-video-slider pt-sm-4">
      <h3 className="font-weight-semi d-inline-block pt-4 mb-3 pl-2">Upload Videos</h3>
      <Slider {...settings} className="videos">
        {videos && videos.length ? videos.map(video => (
          <div className="recipe-item" key={video.priority} onClick={() => addUpdateClickHandler('video', video.priority, video.id ? video.id : 'new')}>
            <UploadCarouselSlide buttonTitle="Add Video" imgPreview={video.cover_image ? video.cover_image : null} />
            <h4 className="title font-weight-semi my-2 ml-2">{video.video_details && video.video_details.title ? video.video_details.title : 'Video Title'}</h4>
          </div>
        )): null}
      </Slider>
    </div>
  );
};