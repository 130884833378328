/* import React, { Component } from "react";
import Flowplayer from "@flowplayer/react-flowplayer";
import OVPPlugin from "@flowplayer/player/plugins/ovp";
import HLSPlugin from "@flowplayer/player/plugins/hls";
import flowplayer from "@flowplayer/player";

flowplayer(HLSPlugin, OVPPlugin);

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      videoId: "",
    };
  }

  render() {
      let videoData = this.props && this.props.videoInfo;
      const token = JSON.parse(localStorage.getItem('user'));
      return (
          <>
              {
                  videoData ?
                      <Flowplayer
                          token={token.access_token}
                          src={
                              videoData &&
                              videoData['encodings'] &&
                              videoData['encodings'][0] &&
                              videoData['encodings'][0]['video_file_url']
                          }
                          poster={
                              videoData &&
                              videoData['images'] &&
                              videoData['images'][0] &&
                              videoData['images'][0]['url']
                          }
                      />
                      : this.props.videoId ?
                      <Flowplayer
                          src={this.props.videoId}
                      />
                      : null
              }

          </>
      );
  }
}

export default VideoPlayer;
 */

import React, { useEffect, useRef} from "react";
import Hls from 'hls.js';

export default function VideoPlayer(props) {
  const videoRef = useRef(null);
  const id=props && props.playbackId;
  const src = `https://stream.mux.com/${props.playbackId}.m3u8`;
  
  useEffect(() => {
    let hls;
    if (videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively

        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
    
        
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef]);

  return (
      <div>
      <div>
      
      </div>
            <video
            controls
            ref={videoRef}
            style={{ width: "100%", maxWidth: "100%", maxHeight: '480px' }}
            />
      </div>

  );
}