const dev = {
    apiUrl: process.env.REACT_APP_BACKEND_API_URL,
    // auth endpoints
    auth: {
        login: "/users/rest-auth/login/",
        signup: "/users/rest-auth/registration/",
        googleAuth: "/users/rest-auth/google/",
        facebookAuth: "/users/rest-auth/facebook/",
        logout: "/users/rest-auth/logout/",
        handleFCMToken: "/notifier/onesignal-register/",
        resetPassword: "/users/password-reset-confirm/",
        forgotPassword: "/users/rest-auth/password/reset/",
    },

    //For user related APIs
    users: {
        getUserDetails: "/users/all-users/",
        getCsvData: "/users/export_users/",
        getCarouselContents: "/admin/carousel-content/",
    },

    //For carousel items
    carousel: {
        carouselItems: "/admin/carousel-content/",
        dashboardItems: "/admin/dashboard-items/",
        dashboardItemsById: "/admin/dashboard-items/:id/",
        publishedContent: "/admin/all-published-content/",
    },

    //For reports
    reports: {
        reportedPostById: "/user/posts/:id/",
        reportItems: "/user/report-item-list/",
        reportItemsSearch: "/user/report-item-search/",
        reportAction: "/user/reported-item-action/:id/",
        reportedCommentById: "/user/comment-with-details/:id/",
    },

    //For content management APIs
    content: {
        saveRecipeVideo: "/admin/youtube-recipe/",
        uploadVideoContent: "/admin/video/",
        getVideoDetails: "/admin/video/:id/",
        updateVideoContent: "/admin/video/:id/",
        updateRecipeContent: "/admin/youtube-recipe/",
        addConnectedRecipe: "/admin/connected-recipe/",
        getSavedRecipeVideo: "/admin/youtube-recipe/:id/",
        editConnectedRecipe: "/admin/connected-recipe/:id/",
        getConnectedRecipeById: "/admin/connected-recipe/:id/",
        getRecipeListing: "admin/all-content/",
        editRecipeListing: "",
        deleteRecipeListing: "/admin/:type/:id/",
        uploadShowContent: "/admin/show/",
        uploadEpisodeContent: "/admin/episode/",
        getSavedShow: "/admin/show/:id/",
        updateShow: "/admin/show/:id/",
        getEpisodeContent: "/admin/episode/:id/",
        getUserSideEpisodeContent: "/admin/user/episode/:id/",
        updateEpisodeContent: "/admin/episode/:id/",
        deleteEpisodeContent: "/admin/episode/:id/",
        fetchFaqTableData: "/faqs/user-faqs/",
        deleteFaqTopic: "/faqs/faq-topic/",
        getTopicDetails: "/faqs/faq-topic-details/",
        updateTopic: "/faqs/faq-topic/",
        updateSubTopic: "/faqs/faq-subtopic/",
        newTopicAddition: "/faqs/faq-subtopic/",
        deleteSubtopic: "/faqs/faq-subtopic/",
        createFaq: "/faqs/faq-topic/",
        announcementApi: "/announcements/admin/",
        userSideAnnouncements: "/announcements/"
    },

    // user faqs
    userfaq: {
        getFAQContent: "/faqs/user-faqs/",
    },
    // user recipes
    userRecipe: {
        getRecipeContent: "/admin/user/youtube-recipe/105/",
        getRecipeById: "/admin/user/youtube-recipe/:id/",
        getAllRecipe: "/admin/user/youtube-recipe/",
    },
    // user videos
    userVideo: {
        getVideoContent: "/admin/user/video/67/",
        getVideoById: "/admin/user/video/:id/",
        getAllVideo: "/admin/user/video/",
    },
    // user shows
    userShow: {
        getAllShows: "admin/user/show/",
        singleShowDetails: '/admin/user/show/'
    },

    //user Grocery
    userGrocery:{
        getAllgrocery:"/grocery/list/",
        addGroceryList:"/grocery/list/",
        getGroceryListById:"/grocery/list/:id/",
        addItemToGroceryList:"/grocery/item/",
        deleteItemFromGroceryList:"/grocery/item/:id/",
        toggleCheckbox:"/grocery/item/:id/",
        removeGroceryList:"/grocery/list/:id/",
        addGroceryItemToList:"/grocery/list/:id/"
    },

    // user profile
    userProfile: {
        profileDetails: "/users/user-detail/",
        editProfileImg: "/users/user-profile/",
        changePassword: "/users/rest-auth/password/change/",
    },
    // usera header search
    userHeaderSearch: {
        headerSearchResult: "/admin/carousel-content/",
        videoSearchResult: "/admin/user/video/",
        showSearchResult: "admin/user/show/",
        recipeSearchResult: "/admin/user/youtube-recipe/",
    },

    //user payment
    userPayment:{
        singleProductPaymentDetail:'/payments/single-product/',
        subscriptionPaymentDetail:'/payments/order-subscription/',
        subscriptionCancelDetail:'/payments/cancel-subscription/',
        upgradePaymentDetail:'/payments/remaining-amount/',
        postUpgradePaymentDetail:"/payments/upgrade-subscription/",
        purchasedProductLastMonthDetail:'/users/purchased-lastmonth/'
    },
    // firebaseConfig: {
    //     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    //     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    //     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    //     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    //     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    //     appId: process.env.REACT_APP_FIREBASE_APP_ID
    // },
    //notification
    notification: {
        getNotifications: '/notifier/notifications/'
    },
    // post
    post: {
        postRoute: '/user/posts/',
        like: '/user/like-unlike/',
        comment: '/user/comment/',
        bookmark: '/user/bookmark/',
        savedPosts: '/user/saved-posts/',
        savedContents:'/user/saved-content/',
        itemReport:'/user/report-item/',
    },
    //livestream
    livestream:{
        playbackId:'/livestream/current/',
        addComment:'/livestream/create-comment/'
    },

    //Shopify
    shopifyStore:{
        storefrontAccessToken: process.env.REACT_APP_STOREFRONT_ACCESS_TOKEN,
        domain: process.env.REACT_APP_STOREFRONT_DOMAIN,
    }
};

const prod = {
    // all prod url's will be here
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config,
};