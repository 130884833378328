import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import {get_grocery_list_action,remove_grocery_list_action} from "../../../../_actions/User/grocery.action";
import { connect } from "react-redux";

class GroceryListCard extends Component {
  routeHandler = (id) => {
    this.props.history.push(`/view-grocery-list/${id}`);
  };
  constructor(props) {
    super(props);
    this.state = {
      removeListModal: false,
      deleteListId:null
    };
  }

  removeListModalHandler = (id) => (event) => {
    event.stopPropagation();
    this.setState({
      removeListModal: !this.state.removeListModal,deleteListId:id
    });
  };

  onDeleteList = async () => {
      
      await this.props.removeGroceryList(this.state.deleteListId)
      await this.props.getGroceryList();
      this.setState({deleteListId:null});
  }
  disableRemoveListModalHandler = () => {
    this.setState({
      removeListModal: !this.state.removeListModal,deleteListId:null
    });
  };
  render() {
    
    const listDetail = this.props && this.props.item;
    const groceryItems=this.props && this.props.item && this.props.item.grocery_items;
    
    return (
      <div className="grocery-list-card">
        <div
          className="grocey-list-content cursor-pointer"
         onClick={()=>this.routeHandler(listDetail.id)}
        >
          <div className="list bg-grey p-3 border-radius-10 mb-4">
            <h5 className="font-weight-bold">List Name : {' '}
           {listDetail?listDetail.list_name:null} 
            </h5>
            <div className="grocery-item p-2">
            {/*   <h6 className="fs-14 font-weight-semi mb-2">
              Fruits
              </h6> */}
              <ul className="text-grey-2 fs-small pl-2">
              {groceryItems?
               groceryItems.map((item)=>(<>
                <li> {item.item_name}</li>
               </>))
               :null
               }
               
              </ul>
            </div>

          {/*   <div className="grocery-item p-2">
              <h6 className="fs-14 font-weight-semi mb-2">Fruits</h6>
              <ul className="text-grey-2 fs-small pl-2">
                <li>Apple</li>
                <li>Banana</li>
                <li>Grapes</li>
                <li>Papaya</li>
              </ul>
            </div> */}

            <a
              href="#"
              onClick={this.removeListModalHandler(listDetail.id)}
              className="remove-list d-flex justify-content-end"
            >
              <span className="fa fa-minus-circle"  ></span>
            </a>
          </div>
        </div>

        {/* delete list popup  */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.removeListModal}
          onHide={this.removeListModalHandler}
          className="text-center delete-action-popup"
        >
          <Modal.Body className="delete-action-popup-content">
            <p className="font-poppins fs-18 text-center font-weight-medium">
              Are you sure you want to remove “List Name” ?{" "}
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
            <Button
              onClick={this.onDeleteList}
              variant="btn btn-warning"
              className="rounded"
            >
              Yes
            </Button>

            <Button
              onClick={this.disableRemoveListModalHandler}
              variant="btn btn-primary"
              className="rounded ml-5"
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
   groceryList: state.get_grocery_list_reducer.data,   
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGroceryList: () => dispatch(get_grocery_list_action()),
  removeGroceryList:(id)=>dispatch(remove_grocery_list_action(id)),
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroceryListCard);


