import {faqConstants} from "../../_constants/User";


const initialState = {}
export const get_faq_content_reducer = (state = initialState, action) => {
    switch (action.type) {
        case faqConstants.GET_FAQ_CONTENT_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case faqConstants.GET_FAQ_CONTENT_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case faqConstants.GET_FAQ_CONTENT_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}








