import React, { Component } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import VideoPlayer from '../common/VideoPlayer/VideoPlayer'
import {
    get_current_livestream_playbackid_action,
    add_comment_live_stream_action
  } from "../../../_actions/User/livestream.action";
  import { Form,Button } from 'react-bootstrap';

class JoinLiveStream extends Component {
constructor(props){
    super(props);
    this.state={
        playbackId:null,
        streamId:null,
        comment:""       
    }
}
componentDidMount=async()=>{
    await this.props.currentLivestreamPlayBackId();
    this.setState({
        playbackId : this.props && this.props.playbackId && this.props.playbackId.playback_id,
        streamId : this.props && this.props.playbackId && this.props.playbackId.livestream_id
    })
}

handleChange=(e)=>{
this.setState({comment:e.target.value})
}
handleSubmit=async()=>{
const user = JSON.parse(localStorage.getItem('user'))
    const data={
        "stream_id" : this.state.streamId,
        "text" :this.state.comment,
        "user": user && user.user && user.user.id
    }
    const res=await this.props.addCommentLiveStream(data);   
}

render() {
    const {playbackId} = this.state;
    return (    
        <div className="common-dashboard status-post-wrapper post-wrapper">
            <Container className="p-0">
                <SidebarMenu
                    alertNewNotification={(payload) =>
                    this.openNewNotification(payload)
                    }
                    history={this.props.history}
                />
            <div className="main-content bg-white">
                <Header />
                <Row className="mt-4">
                    <Col md={{ span: 10, offset: 1 }}>
                    {
                       this.state && this.state.playbackId ?
                       (<VideoPlayer playbackId={this.state && this.state.playbackId}></VideoPlayer>)
                       :null
                    }
                    <Form.Group className="mb-0 search-bar flex-grow-1">
                        <Form.Control 
                            type="text" 
                            value={this.state.comment}
                            placeholder="Post Comment" 
                            onChange={(e)=>this.handleChange(e)} 
                            className="search-bar bg-grey-2 h-500">
                        </Form.Control>
                        <br/>
                        <Button variant="primary" onClick={this.handleSubmit}>Post</Button>
                    </Form.Group>
                    </Col>
                </Row>               
            </div>
            </Container>
        </div>
    )
    }
}

const mapStateToProps = (state) => {
    return {
     playbackId:state.get_current_livestream_playbackid_reducer &&
                state.get_current_livestream_playbackid_reducer.data &&
                state.get_current_livestream_playbackid_reducer.data.data, 
    
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
   currentLivestreamPlayBackId:()=>dispatch(get_current_livestream_playbackid_action()),
   addCommentLiveStream:(data)=>dispatch(add_comment_live_stream_action(data))
  });

export default connect(mapStateToProps,mapDispatchToProps)(JoinLiveStream);