export const postConstants = {
    ADD_POST_ACTION_REQUEST: 'ADD_POST_ACTION_REQUEST',
    ADD_POST_ACTION_SUCCESS: 'ADD_POST_ACTION_SUCCESS',
    ADD_POST_ACTION_FAILURE: 'ADD_POST_ACTION_FAILURE',

    UPDATE_POST_ACTION_REQUEST: 'UPDATE_POST_ACTION_REQUEST',
    UPDATE_POST_ACTION_SUCCESS: 'UPDATE_POST_ACTION_SUCCESS',
    UPDATE_POST_ACTION_FAILURE: 'UPDATE_POST_ACTION_FAILURE',

    GET_ALL_POSTS_ACTIONS_REQUEST: 'GET_ALL_POSTS_ACTIONS_REQUEST',
    GET_ALL_POSTS_ACTIONS_SUCCESS: 'GET_ALL_POSTS_ACTIONS_SUCCESS',
    GET_ALL_POSTS_ACTIONS_FAILURE: 'GET_ALL_POSTS_ACTIONS_FAILURE',

    GET_POST_BY_ID_ACTION_REQUEST: 'GET_POST_BY_ID_ACTION_REQUEST',
    GET_POST_BY_ID_ACTION_SUCCESS: 'GET_POST_BY_ID_ACTION_SUCCESS',
    GET_POST_BY_ID_ACTION_FAILURE: 'GET_POST_BY_ID_ACTION_FAILURE',

    DELETE_POST_BY_ID_ACTION_REQUEST: 'DELETE_POST_BY_ID_ACTION_REQUEST',
    DELETE_POST_BY_ID_ACTION_SUCCESS: 'DELETE_POST_BY_ID_ACTION_SUCCESS',
    DELETE_POST_BY_ID_ACTION_FAILURE: 'DELETE_POST_BY_ID_ACTION_FAILURE',

    GET_STATUS_BY_ID_ACTION_REQUEST: 'GET_STATUS_BY_ID_ACTION_REQUEST',
    GET_STATUS_BY_ID_ACTION_SUCCESS: 'GET_STATUS_BY_ID_ACTION_SUCCESS',
    GET_STATUS_BY_ID_ACTION_FAILURE: 'GET_STATUS_BY_ID_ACTION_FAILURE',

    HIT_LIKE_BY_ID_ACTION_REQUEST: 'HIT_LIKE_BY_ID_ACTION_REQUEST',
    HIT_LIKE_BY_ID_ACTION_SUCCESS: 'HIT_LIKE_BY_ID_ACTION_SUCCESS',
    HIT_LIKE_BY_ID_ACTION_FAILURE: 'HIT_LIKE_BY_ID_ACTION_FAILURE',

    ADD_COMMENT_BY_ID_ACTION_REQUEST: 'ADD_COMMENT_BY_ID_ACTION_REQUEST',
    ADD_COMMENT_BY_ID_ACTION_SUCCESS: 'ADD_COMMENT_BY_ID_ACTION_SUCCESS',
    ADD_COMMENT_BY_ID_ACTION_FAILURE: 'ADD_COMMENT_BY_ID_ACTION_FAILURE',

    GET_COMMENTS_BY_ID_ACTION_REQUEST: 'GET_COMMENTS_BY_ID_ACTION_REQUEST',
    GET_COMMENTS_BY_ID_ACTION_SUCCESS: 'GET_COMMENTS_BY_ID_ACTION_SUCCESS',
    GET_COMMENTS_BY_ID_ACTION_FAILURE: 'GET_COMMENTS_BY_ID_ACTION_FAILURE',

    DELETE_COMMENTS_BY_ID_ACTION_REQUEST: 'DELETE_COMMENTS_BY_ID_ACTION_REQUEST',
    DELETE_COMMENTS_BY_ID_ACTION_SUCCESS: 'DELETE_COMMENTS_BY_ID_ACTION_SUCCESS',
    DELETE_COMMENTS_BY_ID_ACTION_FAILURE: 'DELETE_COMMENTS_BY_ID_ACTION_FAILURE',

    BOOKMARK_POST_BY_ID_ACTION_REQUEST: 'BOOKMARK_POST_BY_ID_ACTION_REQUEST',
    BOOKMARK_POST_BY_ID_ACTION_SUCCESS: 'BOOKMARK_POST_BY_ID_ACTION_SUCCESS',
    BOOKMARK_POST_BY_ID_ACTION_FAILURE: 'BOOKMARK_POST_BY_ID_ACTION_FAILURE',

    GET_SAVED_POSTS_ACTION_REQUEST: 'GET_SAVED_POSTS_ACTION_REQUEST',
    GET_SAVED_POSTS_ACTION_SUCCESS: 'GET_SAVED_POSTS_ACTION_SUCCESS',
    GET_SAVED_POSTS_ACTION_FAILURE: 'GET_SAVED_POSTS_ACTION_FAILURE',

    GET_SAVED_CONTENTS_ACTION_REQUEST:"GET_SAVED_CONTENTS_ACTION_REQUEST",
    GET_SAVED_CONTENTS_ACTION_SUCCESS:'GET_SAVED_CONTENTS_ACTION_SUCCESS',
    GET_SAVED_CONTENTS_ACTION_FAILURE:'GET_SAVED_CONTENTS_ACTION_FAILURE',

    REPORT_ITEM_ACTION_REQUEST:"REPORT_ITEM_ACTION_REQUEST",
    REPORT_ITEM_ACTION_SUCCESS:"REPORT_ITEM_ACTION_SUCCESS",
    REPORT_ITEM_ACTION_FAILURE:"REPORT_ITEM_ACTION_FAILURE"
}