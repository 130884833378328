import {showConstants} from "../../_constants/User/show.constants";

const initialState = {};

export const get_all_user_shows_reducer = (state = initialState, action) => {
    switch (action.type) {
        case showConstants.GET_ALL_SHOW_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case showConstants.GET_ALL_SHOW_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload,
            };
        case showConstants.GET_ALL_SHOW_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload,
            };
        default:
            return state;
    }
};

export const get_show_details_reducer = (state = initialState, action) => {
    switch (action.type) {
        case showConstants.GET_SHOW_DETAILS_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case showConstants.GET_SHOW_DETAILS_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload,
            };
        case showConstants.GET_SHOW_DETAILS_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload,
            };
        default:
            return state;
    }
};