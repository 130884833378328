import OneSignal from "react-onesignal";

export const notificationListener = () => {
    window.OneSignal.push(function() {
        window.OneSignal.on('notificationDisplay', function(event) {
          console.warn('OneSignal notification displayed:', event);
        });
      });
}

export const getOneSignalPlayerId = async () => {
  const playerId = await OneSignal.getUserId();
  return playerId;
};

export const initOneSignal = () => {
  OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID });
};

export const addTagOnOneSignal = (key, value) => {
  OneSignal.sendTag(key, `${value}`);
};

export const removeTagFromOneSignal = (key) => {
  OneSignal.deleteTag(key);
};
