import React from 'react';
import './Loader.scss'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default ({size, color}) => {
    
    return(
        <div className="loader">
            <div className="spinner-border text-warning"
                 role="status"
            >
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}