import React, { Component } from 'react';
import './RecipeNotes.scss';

class RecipeNotes extends Component {
    render() {

        let notesData=this.props && this.props.notes;
        return (
            <>
                {/* recipe notes box */}
                <div className='recipe-notes'>
                    <h6 className="recipe-heading bg-primary rounded-pill d-inline-block text-white text-center py-2 mt-4">Notes</h6>
                    
                    <div 
                     className="font-smallest text-grey-4 font-weight-semi bg-grey-7 rounded mt-3 p-3"
                     dangerouslySetInnerHTML={{
                        __html: notesData,
                    }}
                    />
                </div>
                {/* recipe notes box Ends */}

            </>
        )
    }
}


export default RecipeNotes;