import axios from "axios";
import Config from "../Config/config";
const baseURL = Config.apiUrl;

const axiosInstance = axios.create({
    baseURL: baseURL
});

axiosInstance.interceptors.request.use(
    function (config) {
        const token = JSON.parse(localStorage.getItem('user'));
        const Access_Token = token && token.access_token ? token.access_token : '';
        config.headers.Authorization  = Access_Token && Access_Token.length ? `Bearer ${Access_Token}` : null
        // you can also do other modification in config
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status === 401) {
            alert("You are not authorized");
        }
        return response;
    },
    (error) => {
        if (
            error.response &&
            error.response.data &&
            error.response.data.status === 401
        ) {
            localStorage.removeItem("user");
            window.location.reload();
            return Promise.reject(error.response && error.response.data);
        }
        return Promise.reject(error.response.data);
    }
);
export default axiosInstance;