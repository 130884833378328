import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import CommentsRecipeTabs from "../common/CommentsRecipeTabs/CommentsRecipeTabs";
import "./ViewRecipe.scss";
import { connect } from "react-redux";
import { get_recipe_by_id_action } from "../../../_actions/User";
import ContentLoader from "../../Common/Loader/ContentLoader";
import {
  add_bookmark_to_post_action,
  hit_like_action,
} from "../../../_actions/User/post.action";
import ReactPlayer from "react-player";
class ViewRecipe extends Component {
  state = {
    content: [],
    recipeId: "",
    loading: false,
  };

  componentDidMount = () => {
    this.mountRecipe();
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.getRecipeContentData !== this.props.getRecipeContentData) {
      this.setState({
        content: nextProps.getRecipeContentData,
      });
    }
  };

  mountRecipe = async () => {
    await this.setState({ loading: true });

    window.scrollTo(0, 0);
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      await this.setState(
        { recipeId: this.props.match.params.id },
        async () => {
          await this.props.getRecipeContent(this.state.recipeId);
        }
      );
    }

    await this.setState({ loading: false });
  };

  likeRecipe = async (id) => {
    if (!id) return;
    const data = {
      content_type: "youtube_recipe",
      id,
    };
    await this.props.hitLike(data);
    await this.props.getRecipeContent(id);
  };

  bookmarkRecipe = async (id) => {
    if (!id) return;
    const data = {
      content_type: "youtube_recipe",
      id,
    };
    await this.props.addBookmarkAction(data);
    await this.props.getRecipeContent(id);
  };

  render() {
    let getRecipeData =
      this.state && this.state.content && this.state.content.data;
    const userObject = JSON.parse(localStorage.getItem("user"));

    return (
      <div className="common-dashboard recipe-desc-wrapper">
        <Container className="p-0">
          <SidebarMenu />
          <div className="main-content bg-white position-relative pt-2">
            <Header />
            {/* video and suggestions */}

            {!getRecipeData ? (
              <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                <ContentLoader color="text-warning" />
              </div>
            ) : (
              <>
                <h2 className="fs-36 recipe-top-heading mb-3">
                  {getRecipeData && getRecipeData.title}
                </h2>
                <Row>
                  <Col lg={4}>
                    <img
                      src={getRecipeData && getRecipeData["title_image"]}
                      alt="recipe-img"
                      className="recipe-image border-6 img-fit w-100 mb-4 mb-lg-0"
                    />
                  </Col>
                  {/* you tube video box */}
                  <Col lg={8}>
                    {getRecipeData && getRecipeData["youtube_link"] !== null ? (
                      <div className="video-box">
                        <ReactPlayer
                         className="w-100 h-auto"
                          url={getRecipeData && getRecipeData["youtube_link"]}
                          playing='false'
                          controls='true'
                      
                        />

                        {/* <iframe
                          src={getRecipeData && getRecipeData["youtube_link"]}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          className="border-6"
                          allowFullScreen
                        /> */}
                      </div>
                    ) : null}
                  </Col>
                </Row>

                {/* episode name and user action */}
                <div className="bottom d-flex align-items-center my-4">
                  <ul className="user-action-btn d-flex">
                    {/*like icon */}
                    <li className="mr-4">
                      <a>
                        {getRecipeData &&
                        getRecipeData["likes"] &&
                        getRecipeData["likes"].includes(userObject.user.id) ? (
                          <span
                            onClick={() =>
                              this.likeRecipe(getRecipeData && getRecipeData.id)
                            }
                          >
                            <i
                              className="fa fa-heart text-danger fs-18"
                              aria-hidden="true"
                            />
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              this.likeRecipe(getRecipeData && getRecipeData.id)
                            }
                          >
                            <i
                              className="fa fa-heart-o text-black fs-18"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </a>
                    </li>
                    {/* share icon */}
                    <li className="mr-4">
                      <a>
                        <img src={"/assets/images/share.png"} alt="share" />
                      </a>
                    </li>
                    {/* bookmark icon */}
                    <li className="mr-4">
                      <a>
                        {getRecipeData &&
                        getRecipeData["bookmarks"] &&
                        getRecipeData["bookmarks"].includes(
                          userObject.user.id
                        ) ? (
                          <span
                            onClick={() =>
                              this.bookmarkRecipe(
                                getRecipeData && getRecipeData.id
                              )
                            }
                          >
                            <i
                              className="fa fa-bookmark text-warning fs-18"
                              aria-hidden="true"
                            />
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              this.bookmarkRecipe(
                                getRecipeData && getRecipeData.id
                              )
                            }
                          >
                            <i
                              className="fa fa-bookmark-o text-black fs-18"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </a>
                    </li>
                  </ul>
                </div>
                {/* Recipe & comments tab*/}
                <div className="recipe-desc-wrapper">
                  {this.state.loading && !getRecipeData ? (
                    <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                      <ContentLoader color="text-warning" />
                    </div>
                  ) : (
                    <div className="recipe-desc-wrapper">
                      <CommentsRecipeTabs details={getRecipeData} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getRecipeContentData: state.get_recipe_content_reducer.data,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getRecipeContent: (id) => dispatch(get_recipe_by_id_action(id)),
  hitLike: (data) => dispatch(hit_like_action(data)),
  addBookmarkAction: (data) => dispatch(add_bookmark_to_post_action(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRecipe);
