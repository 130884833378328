import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "../_components/Common/Login/Login";
import SignUp from "../_components/Common/SignUp/SignUp";
import { initOneSignal } from "../Config/onesignalService";
import ForgotPassword from "../_components/Common/ForgotPassword/ForgotPassword";
import ResetPassword from "../_components/Common/ResetPassword/ResetPassword";
import CreateAccount from "../_components/Common/CreateAccount/CreateAccount";
import WelcomeScreen from "../_components/Common/WelcomeScreen/WelcomeScreen";
import { PrivateRoute } from "../_helpers/PrivateRoute";
import { AdminRoute } from "../_helpers/AdminRoute";
import { ProtectedRoute } from "../_helpers/ProtectedRoute";
// import AdminHome from '../_components/Admin/AdminHome/AdminHome';
// import ContentManagement from "../_components/Admin/ContentManagement/ContentManagement";
import HomePage from "../_components/User/HomePage/HomePage";
import AllVideos from "../_components/User/AllVideos/AllVideos";
import AllShows from "../_components/User/AllShows/AllShows";
import AllRecipes from "../_components/User/AllRecipes/AllRecipes";
import ViewRecipe from "../_components/User/ViewRecipe/ViewRecipe";
import LiveStreamHome from "../_components/Admin/LiveStreamHome/LiveStreamHome";
import LiveStream from "../_components/Admin/LiveStream/LiveStream";
import ViewVideo from "../_components/User/ViewVideo/ViewVideo";
import ShoppingHome from "../_components/User/ShoppingHome/ShoppingHome";
import CollectionCategories from "../_components/User/CollectionCategories/CollectionCategories";
import ViewShow from "../_components/User/ViewShow/ViewShow";
import WatchShow from "../_components/User/ViewShow/WatchShow/WatchShow";
import Help from "../_components/User/Help/Help";
import UserProfile from "../_components/User/UserProfile/UserProfile";
import EditProfile from "../_components/User/EditProfile/EditProfile";
import ReportedPhoto from "../_components/Admin/ReportedPhoto/ReportedPhoto";
import ReportedVideo from "../_components/Admin/ReportedVideo/ReportedVideo";
import ReportedComment from "../_components/Admin/ReportedComment/ReportedComment";
import ReportedRecipe from "../_components/Admin/ReportedRecipe/ReportedRecipe";
import ReportedStatus from "../_components/Admin/ReportedStatus/ReportedStatus";
import ChangePassword from "../_components/User/ChangePassword/ChangePassword";
import ChatBoard from "../_components/User/ChatBoard/ChatBoard";
import GroceryLists from "../_components/User/GroceryLists/GroceryLists";
import ViewGroceryList from "../_components/User/ViewGroceryList/ViewGroceryList";
import SavedVideo from "../_components/User/SavedVideo/SavedVideo";
import ProductDisplay from "../_components/User/ProductDisplay/ProductDisplay";
import AddPost from "../_components/User/AddPost/AddPost";
import UserLiveFeed from "../_components/User/UserLiveFeed/UserLiveFeed";
import Notifications from "../_components/User/Notifications/Notifications";
import UserPosts from "../_components/User/UserPosts/UserPosts";
import ViewPost from "../_components/User/ViewPost/ViewPost";
import StatusPost from "../_components/User/StatusPost/StatusPost";
import PremiumPlans from "../_components/User/PremiumPlans/PremiumPlans";
import UserPlanDetails from "../_components/User/UserPlanDetails/UserPlanDetails";
import AddGroceryList from "../_components/User/AddGroceryList/AddGroceryList";
import CarouselHome from "../_components/Admin/ContentManagement/Carousel/CarouselHome/CarouselHome";
import CarouselListing from "../_components/Admin/ContentManagement/Carousel/CarouselListing/CarouselListing";
import Announcements from "../_components/Admin/ContentManagement/Announcements/Announcements";
import FaqsListing from "../_components/Admin/ContentManagement/Faqs/FaqsListing/FaqsListing";
import FaqsTopic from "../_components/Admin/ContentManagement/Faqs/FaqsTopic/FaqsTopic";
import UploadContentListing from "../_components/Admin/ContentManagement/UploadContent/UploadContentListing/UploadContentListing";
import UploadNewContent from "../_components/Admin/ContentManagement/UploadContent/UploadNewContent/UploadNewContent";
import UploadNewEpisode from "../_components/Admin/ContentManagement/UploadContent/UploadNewEpisode/UploadNewEpisode";
import UploadRecipeInstructions from "../_components/Admin/ContentManagement/UploadContent/UploadRecipeInstructions/UploadRecipeInstructions";
import UserData from "../_components/Admin/AdminHome/UserData/UserData";
import ReportsReview from "../_components/Admin/AdminHome/ReportsReview/ReportsReview";
import SearchResults from "../_components/User/SearchResults/SearchResults";
import Payment from "../_components/User/CheckoutFlow/Payment/Payment";
import JoinLiveStream from "../_components/User/JoinLiveStream/JoinLiveStream";
class App extends React.Component {
  
  // componentDidMount() {
  //   initOneSignal();
  // }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            {/* ================= Common Routes=================*/}
            <ProtectedRoute path="/login" component={Login} />
            <ProtectedRoute path="/sign-up" component={SignUp} />
            <ProtectedRoute
              path="/forgot-password"
              component={ForgotPassword}
            />
            <ProtectedRoute path="/reset-password" component={ResetPassword} />
            <ProtectedRoute path="/create-account" component={CreateAccount} />
            <Route path="/welcome-screen" component={WelcomeScreen} />
            <Route
              path="/v1/users/password-reset-confirm/:uid/:token/"
              component={ResetPassword}
            />
            {/* ==================Admin Routes=================*/}
            <AdminRoute path="/admin-home-page" component={UserData} />
            {/* <AdminRoute path='/content-management' component={ContentManagement} /> */}
            <AdminRoute path="/livestream-home" component={LiveStreamHome} />
            <AdminRoute path="/livestream" component={LiveStream} />
            <AdminRoute path="/reported-photo/:id" component={ReportedPhoto} />
            <AdminRoute path="/reported-video/:id" component={ReportedVideo} />
            <AdminRoute
              path="/reported-comment/:id"
              component={ReportedComment}
            />
            <AdminRoute
              path="/reported-recipe/:id"
              component={ReportedRecipe}
            />
            <AdminRoute
              path="/reported-status/:id"
              component={ReportedStatus}
            />
            <AdminRoute path="/carousel-management" component={CarouselHome} />
            {/**show content creation */}
            <AdminRoute
              path="/upload-new-content/:contentType"
              component={UploadNewContent}
            />
            <AdminRoute
              path="/update-show-content/:contentType/:contentId"
              component={UploadNewContent}
            />
            <AdminRoute
              path="/upload-new-episode"
              component={UploadNewEpisode}
            />
            <AdminRoute
              path="/upload-episode-content/:contentType/:contentId"
              component={UploadNewEpisode}
            />
            <AdminRoute
              path="/update-episode-content/:contentType/:contentId"
              component={UploadNewEpisode}
            />

            <AdminRoute
              path="/carousel-listing-management/:type"
              component={CarouselListing}
            />
            <AdminRoute
              path="/announcements-management"
              component={Announcements}
            />

            {/* BY IDs */}
            <AdminRoute path="/faqs-new-topic/:topicId" component={FaqsTopic} />
            <AdminRoute
              path="/faqs-listing-management"
              component={FaqsListing}
            />
            <AdminRoute
              path="/upload-content-listing"
              component={UploadContentListing}
            />
            <AdminRoute
              path="/update-content/:contentType/:contentId"
              component={UploadNewContent}
            />
            <AdminRoute
              path="/upload-new-content/:contentType"
              component={UploadNewContent}
            />
            <AdminRoute
              path="/upload-new-episode"
              component={UploadNewEpisode}
            />
            <AdminRoute
              path="/upload-recipe-instructions/:contentType/:contentId/:recipeType"
              component={UploadRecipeInstructions}
            />
            <AdminRoute
              path="/edit-recipe-instructions/:contentType/:contentId/:recipeType/:recipeId"
              component={UploadRecipeInstructions}
            />
            <AdminRoute path="/user-reports-review" component={ReportsReview} />
            <AdminRoute
              path="/announcements-management"
              component={Announcements}
            />

            {/* ==================User Routes=================*/}
            <PrivateRoute path="/user-home-page" component={HomePage} />
            <PrivateRoute path="/all-videos" component={AllVideos} />
            <PrivateRoute path="/all-shows" component={AllShows} />
            <PrivateRoute path="/all-recipes" component={AllRecipes} />

            <PrivateRoute path="/recipe/:id/" component={ViewRecipe} />

            <PrivateRoute path="/video/:id/" component={ViewVideo} />
            <PrivateRoute path="/shopping-home" component={ShoppingHome} />
            <PrivateRoute
              path="/collections"
              component={CollectionCategories}
            />
            <PrivateRoute path="/view-show/:showId" component={ViewShow} />
            <PrivateRoute path="/saved-content" component={SavedVideo} />
            <PrivateRoute path="/saved-post" component={SavedVideo} />

            <PrivateRoute path="/watch-show/:id" component={WatchShow} />
            <PrivateRoute path="/chatboard/:postType" component={ChatBoard} />
            <PrivateRoute path="/chatboard" component={ChatBoard} />
            <PrivateRoute path="/grocery-lists" component={GroceryLists} />
            <PrivateRoute
              path="/view-grocery-list"
              component={ViewGroceryList}
            />
            <PrivateRoute
              path="/view-grocery-list/:id"
              component={ViewGroceryList}
            />
            <PrivateRoute path="/add-grocery-list" component={AddGroceryList} />
            <PrivateRoute path="/help" component={Help} />
            <PrivateRoute path="/product-display" component={ProductDisplay} />
            <PrivateRoute path="/user-profile" component={UserProfile} />
            <PrivateRoute path="/edit-profile" component={EditProfile} />
            <PrivateRoute path="/change-password" component={ChangePassword} />
            <PrivateRoute path="/notifications" component={Notifications} />
            <PrivateRoute path="/user-posts/:postType" component={UserPosts} />
            <PrivateRoute path="/user-posts" component={UserPosts} />
            <PrivateRoute path="/view-post/:id" component={ViewPost} />
            {/* for viewing post other than status */}
            <PrivateRoute path="/post-status/:id" component={StatusPost} />
            {/* for viewing status post */}
            <PrivateRoute path="/user-live-feed" component={UserLiveFeed} />
            <PrivateRoute path="/premium-plans" component={PremiumPlans} />
            <PrivateRoute
              path="/user-plan-details"
              component={UserPlanDetails}
            />
            <PrivateRoute path="/add-post/:id" component={AddPost} />
            <PrivateRoute path="/search-results" component={SearchResults} />
            <PrivateRoute path="/payment" component={Payment} />

            <PrivateRoute path="/join-livestream" component={JoinLiveStream} />
            {/* ================== Rediret Route =================*/}
            <Redirect to="/welcome-screen" />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default App;
