import React, { Component } from 'react';
import './RecipeInstructions.scss';

class RecipeInstructions extends Component {
    render() {
        let instructionsInfo = this.props && this.props.instructions;
        return (
            <>
                {/* recipe instruction box */}
                <div className='recipe-instruction'>
                    <h6 className="recipe-heading bg-primary rounded-pill d-inline-block text-white text-center py-2 mt-4">Instructions</h6>
                    <div className="text-grey-4 fs-14 font-weight-semi mt-3 "
                        dangerouslySetInnerHTML={{
                            __html: instructionsInfo,
                        }}
                    >




                    </div>
                </div>
            </>
        )
    }
}


export default RecipeInstructions;