import React, { Component } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { Container, Row, Form, Col } from "react-bootstrap";
import Header from "../../User/common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import NewEpisodes from "../common/NewEpisodes/NewEpisodes";
import {
  get_all_user_recipes_action,
  get_carousel_content_action,
  recipe_search_action
} from "../../../_actions/User";
import ContentLoader from "../../Common/Loader/ContentLoader";
import "./AllRecipes.scss";
import TopNotification from "../common/TopNotification/TopNotification";
import { notification } from "antd";
import ReactHtmlparser from "react-html-parser";
import { get_announcement_table_list_action } from "../../../_actions/Admin";

class AllRecipes extends Component {
  state = {
    recipeLoading: false,
    recipeData: [],
    newAddition: null,
    loading: false,
    searchResultData: [],
    searchStatus: false,
    searchEnabled: false,
    updatingAnnouncements: false,
    topNotifications: [],
    pagination: {
      pageSize: 25,
      current: 1,
      total: 0,
    },
  };

  componentDidMount() {
    this.mountRecipeData();
    this.setContents();
    this.mountOrUpdateAnnouncements();
    this.setUserData();
  }

  setUserData = async () => {
    this.setState({ loadingUserData: true });
    // get all user details and set data, prev and next link in state

    const userDetailsRes = await this.props.getAllUserSideRecipes();
    console.log("results",userDetailsRes )
    if (userDetailsRes) {
      this.setState(
        {
          userData: userDetailsRes.data.results,
          userArray: userDetailsRes.data.results,
          nextDataLink: userDetailsRes.data.next,
          prevDataLink: userDetailsRes.data.previous
        },
        () => {
          this.setMaxPagesValue();
        }
      );
    }
    this.setState({ loadingUserData: false });
  };

  setMaxPagesValue = () => {
    // calculate max number of pages required to accommodate all the user details
    const { entriesPerPage, userData } = this.state;
    const remainder = userData.length % entriesPerPage;
    const quotient = userData.length / entriesPerPage;
    let maxPagesNumber =
      remainder > 0 ? parseInt((quotient + 1).toString(), 10) : quotient;
    this.setState({ maxPages: maxPagesNumber }, () => {
      // manage current page number if it exceeds the max page capacity.
      const { currentPageNumber, maxPages } = this.state;
      if (currentPageNumber > maxPages)
        this.setState({ currentPageNumber: this.state.maxPages });
      if (currentPageNumber < 1) this.setState({ currentPageNumber: 1 });
    });
  };

  handlePageNumberChange = async (type) => {
    const {
      currentPageNumber,
      prevDataLink,
      nextDataLink,
      userData,
      maxPages,
    } = this.state;
    if (type === "decrease") {
      if (currentPageNumber > 1)
        // decrease page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
      else {
        // hit the prev data api to fetch data of users
        if (prevDataLink !== null) {
          this.setState({ loadingUserData: true });
          const prevUserDataRes = await this.props.getPrevUserDetails(
            prevDataLink
          );
          if (prevUserDataRes) {
            const prevUserDataArray = prevUserDataRes.results;
            prevUserDataArray.push(...userData);
            this.setState({
              userData: prevUserDataArray,
              prevDataLink: prevUserDataRes.previous,
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    } else if (type === "increase") {
      if (currentPageNumber < maxPages)
        // increase page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
      else {
        // hit the next data api to fetch data of users
        if (nextDataLink !== null) {
          this.setState({ loadingUserData: true });
          const nextUserDataRes = await this.props.getNextUserDetails(
            nextDataLink
          );
          if (nextUserDataRes) {
            const nextUserDataArray = userData;
            nextUserDataArray.push(...nextUserDataRes.results);
            this.setState({
              userData: nextUserDataArray,
              nextDataLink: nextUserDataRes.next,
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    }
  };
  handlePageNumberChange = async (type) => {
    const {
      currentPageNumber,
      prevDataLink,
      nextDataLink,
      userData,
      maxPages,
    } = this.state;
    if (type === "decrease") {
      if (currentPageNumber > 1)
        // decrease page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
      else {
        // hit the prev data api to fetch data of users
        if (prevDataLink !== null) {
          this.setState({ loadingUserData: true });
          const prevUserDataRes = await this.props.getPrevUserDetails(
            prevDataLink
          );
          if (prevUserDataRes) {
            const prevUserDataArray = prevUserDataRes.results;
            prevUserDataArray.push(...userData);
            this.setState({
              userData: prevUserDataArray,
              prevDataLink: prevUserDataRes.previous,
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    } else if (type === "increase") {
      if (currentPageNumber < maxPages)
        // increase page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
      else {
        // hit the next data api to fetch data of users
        if (nextDataLink !== null) {
          this.setState({ loadingUserData: true });
          const nextUserDataRes = await this.props.getNextUserDetails(
            nextDataLink
          );
          if (nextUserDataRes) {
            const nextUserDataArray = userData;
            nextUserDataArray.push(...nextUserDataRes.results);
            this.setState({
              userData: nextUserDataArray,
              nextDataLink: nextUserDataRes.next,
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    }
  };
  mountRecipeData = async () => {
    const { pagination } = this.state;
    this.setState({ recipeLoading: true });

    const getAllRecipeRes = await this.props.getAllUserSideRecipes(
      pagination.current
    );
    if (getAllRecipeRes && getAllRecipeRes.data && getAllRecipeRes.data.results)
      this.setState((prevState) => ({
        recipeData: getAllRecipeRes.data.results,
        pagination: {
          ...prevState.pagination,
          total: getAllRecipeRes.data.count,
        },
      }));

    this.setState({ recipeLoading: false });
  };

  setContents = async () => {
    this.setState({ loading: true });
    const carouselContentRes = await this.props.getCarouselContent();

    let LatestUpdated = [];
    if (carouselContentRes.data && carouselContentRes.data.length) {
      carouselContentRes.data.forEach((contentItem) => {
        if (
          contentItem["row_type"] === "update" &&
          contentItem["content_type"] &&
          contentItem["content_type"] === "youtube-recipe"
        ) {
          LatestUpdated = [...LatestUpdated, { ...contentItem }];
        }
      });
    }
    this.setState({
      newAddition: LatestUpdated.length
        ? LatestUpdated.sort((a, b) => a.priority - b.priority)
        : []
    });
    this.setState({ loading: false });
  };

  routeHandler = (id) => {
    this.props.history.push(`/recipe/${id}`);
  };

  // search handler ===========
  searchHandler = async (e) => {
    let searchText = e.target.value;
    let searchKeyword = { search: searchText };
    {
      searchText !== ""
        ? this.setState({ searchEnabled: true })
        : this.setState({ searchEnabled: false });
    }

    let searchResult = await this.props.recipeSearchResultData(searchKeyword);
    let searchResultData =
      searchResult && searchResult.data && searchResult.data.results;

    if (
      searchResult.data.count > 0 &&
      searchResultData &&
      searchResultData.length
    ) {
      this.setState({ searchResultData: searchResultData });
      this.setState({ searchStatus: true });
      return;
    } else {
      this.setState({ searchStatus: false });
    }
  };

  mountOrUpdateAnnouncements = async () => {
    await this.setState({ updatingAnnouncements: true, topNotifications: [] });

    // re-fetch announcement details and show it again
    const getAnnouncementDetailsRes = await this.props.getAnnouncementData(
      "user"
    );
    if (
      getAnnouncementDetailsRes &&
      getAnnouncementDetailsRes.type &&
      getAnnouncementDetailsRes.type === "user"
    ) {
      if (
        getAnnouncementDetailsRes &&
        getAnnouncementDetailsRes.response &&
        getAnnouncementDetailsRes.response.data
      ) {
        this.setState({
          topNotifications: getAnnouncementDetailsRes.response.data
        });
      }
    }

    this.setState({ updatingAnnouncements: false });
  };

  openNewNotification = (payload) => {
    notification["info"]({
      message: ReactHtmlparser(payload.body),
      description: ReactHtmlparser(payload.title)
    });
    this.mountOrUpdateAnnouncements();
  };

  onHandlePaginationChange = (current) => {
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, current } }),
      () => {
        this.mountRecipeData();
      }
    );
  };

  render() {
    const { recipeLoading, recipeData, searchResultData, pagination } =
      this.state;
    return (
      <div className="common-dashboard">
        <Container className="p-0">
          <SidebarMenu
            alertNewNotification={(payload) =>
              this.openNewNotification(payload)
            }
            history={this.props.history}
          />
          <div className="main-content bg-white position-relative">
            <Header searchType="recipes" />
            {/* search handler */}
            <div className="top-search-wrapper flex-grow-1 mb-4 mr-0 mr-sm-2 mr-md-5">
              <Form.Group className="mb-0 h-100">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className="search-bar bg-grey-4 border-6 pl-4"
                  onChange={(e) => this.searchHandler(e)}
                />
              </Form.Group>
            </div>

            {/* Announcement notifications */}
            {this.state.topNotifications.length > 0 ? (
              <TopNotification
                updatingAnnouncements={this.state.updatingAnnouncements}
                notifications={
                  this.state.topNotifications.length > 0
                    ? this.state.topNotifications
                    : []
                }
              />
            ) : this.state.updatingAnnouncements ? (
              <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                <ContentLoader color="text-warning" />
              </div>
            ) : (
              <p className="text-info">No notifications to show!</p>
            )}

            {/* all video wrapper */}
            <div className="all-recipe-wrapper mt-3 mt-md-5">
              <NewEpisodes
                loading={this.state.loading}
                newVideos={this.state.newAddition}
                history={this.props.history}
              />
              <h3 className="font-weight-semi pt-4 mb-3">All Recipes</h3>
              

              {/* searched recipes */}
          
              {this.state.searchEnabled ? (
                <Row>
                  {!this.state.searchStatus ? (
                    <div className="text-center p-4 w-100">
                      <img
                        src={"/assets/images/error.png"}
                        alt="no videos"
                        className="p-3"
                      />
                      <p className="text-danger font-weight-bold">
                        Oops! Result Not Found
                      </p>
                    </div>
                  ) : (
                    searchResultData &&
                    searchResultData.map((recipe) => (
                      <Col key={recipe.id} className="col-6 col-md-3 mb-5">
                        <div
                          className="video-box cursor-pointer"
                          onClick={() => this.routeHandler(recipe.content.id)}
                        >
                          
                           {recipe.content["title_image"] ? (
                            <img
                              src={recipe.content["title_image"]}
                              alt="slider-img-1"
                              className="border-6 img-fit w-100"
                            />
                          ) : (
                            <img
                              src={"../assets/images/placeholder-recipe.jpg"}
                              alt="slider-img-1"
                              className="border-6 img-fit w-100"
                            />
                          )}
                          <h4 className="title font-weight-semi my-2">
                            {recipe.content.title}
                          </h4>
                        </div>
                      </Col>
                    ))
                  )}
                </Row>
              ) : (
                <Row>
                  {recipeLoading ? (
                    <ContentLoader color="text-warning mx-auto mt-5" />
                  ) : recipeData && recipeData.length ? (
                    recipeData.map((recipe) => (
                      <Col key={recipe.id} className="col-6 col-md-3 mb-5">
                        <div
                          className="recipe-box cursor-pointer"
                          onClick={() => this.routeHandler(recipe.id)}
                        >
                          {recipe["title_image"] ? (
                            <img
                              src={recipe["title_image"]}
                              alt="slider-img-1"
                              className="border-6 img-fit w-100"
                            />
                          ) : (
                            <img
                              src={"../assets/images/placeholder-recipe.jpg"}
                              alt="slider-img-1"
                              className="border-6 img-fit w-100"
                            />
                          )}

                          <h4 className="title font-weight-semi mb-0">
                            {recipe["title"]}
                          </h4>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div className="col-12 mt-4 py-3 py-md-4 text-center ">
                      <img
                        src={"/assets/images/norecipes-home.png"}
                        alt="no recipes"
                        className="border border-danger rounded"
                      />
                      <h4 className="text-danger">No Recipes Yet</h4>
                    </div>
                  )}
                
                </Row>
                
              )}
                <Pagination
                current={pagination.current}
                showSizeChanger={false}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={this.onHandlePaginationChange}
              />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUserSideRecipes: (page) => dispatch(get_all_user_recipes_action(page)),
  getCarouselContent: () => dispatch(get_carousel_content_action()),
  recipeSearchResultData: (data) => dispatch(recipe_search_action(data)),
  getAnnouncementData: (userType) =>
    dispatch(get_announcement_table_list_action(userType))
});

export default connect(null, mapDispatchToProps)(AllRecipes);
