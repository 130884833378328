import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import ReactHtmlparser from "react-html-parser";

import "./Notifications.scss";
import Header from "../../User/common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import { get_notification_action } from "../../../_actions/User/notification.action";
import ContentLoader from "../../Common/Loader/ContentLoader";
import FullPageLoader from "../../Common/Loader/FullPageLoader";

class Notifications extends Component {
  state = {
    loading: false,
    notifications: [],
  };
  componentDidMount() {
    this.mountNotifications();
  }

  mountNotifications = async () => {
    this.setState({ loading: true });

    const notificationRes = await this.props.mountNotification();

    if (notificationRes)
      this.setState({
        notifications:
          notificationRes &&
          notificationRes.data &&
          notificationRes.data.results,
      });

    this.setState({ loading: false });
  };
  notificationRedirectionHandler = (data) => {
    let post = data.notification;
    let notificationtType = data.notification.type;

    // if notification is relative to comment on post or post like
    if (
      notificationtType === "post_comment" ||
      notificationtType === "post_like"
    ) {
      let postType = post.related_post_details.post_type;
      let postId = post.related_post;
      if (postType === "status") {
        this.props.history.push({
          pathname: `/post-status/${postId}`,
          state: { backPageUrl: "userNotifications" },
        });
      } else {
        this.props.history.push({
          pathname: `/view-post/${postId}`,
          state: { backPageUrl: "userNotifications" },
        });
      }
    }

    // if notification is an annoucment
    if (notificationtType === "announcement") {
      let postType = post.related_content_details.published_item_type;
      let postId = post.related_content_details.published_item_id;
      if (postType === "video") {
        this.props.history.push(`/video/${postId}`);
      } else if (postType === "episode") {
        this.props.history.push(`/watch-show/${postId}`);
      } else if (postType === "youtube-recipe") {
        this.props.history.push(`/recipe/${postId}`);
      } else if (postType === "show") {
        this.props.history.push(`/view-show/${postId}`);
      }
    }
  };
  render() {
    const { loading, notifications } = this.state;
    
    return (
      <div className="common-dashboard user-notification-wrapper">
        <Container className="p-0">
          <SidebarMenu />
          <div className="main-content bg-white position-relative">
            <Header />
            <div className="notifications">
              <a href={"/user-home-page"} className="d-flex align-items-center mt-2">
                <img
                  src={"/assets/images/back-arrow.svg"}
                  alt="back"
                  className="ml-n2"
                />
                <span className="text-dark fs-16 font-weight-bold ml-1">
                  Back
                </span>
              </a>
              <Row className="mt-4">
                <Col md={8} className="ml-md-3 font-weight-bold">
                

                  {loading ? (
                    <FullPageLoader color={"text-warning"} />
                  ) : (
                    notifications &&
                    notifications.map((notification) => (
                      <div
                        key={notification.id}
                        className="notification-box d-flex align-items-center justify-content-between p-2 p-lg-3  bg-grey-4 border-6 mb-3 cursor-pointer"
                        onClick={() =>
                          this.notificationRedirectionHandler({ notification })
                        }
                      >
                        {console.log("notification", notification)}
                      {notification && notification.message === "Chef is going live"? null : (<>
                        <p className="mb-0">
                          {ReactHtmlparser(notification.message)}
                          {notification &&
                            notification.related_content_details &&
                            notification.related_content_details
                              .announcement_item_details &&
                            notification.related_content_details
                              .announcement_item_details.title ? (
                              <em>
                                {" "}
                                - "
                                {notification &&
                                  notification.related_content_details &&
                                  notification.related_content_details
                                    .announcement_item_details &&
                                  notification.related_content_details
                                    .announcement_item_details.title}
                                "
                              </em>
                            ) : null}
                        </p></>)}
                        {notification &&
                          notification.related_post_details &&
                          notification.related_post_details.image ? (
                            <div className="related-img ml-2">
                              <img
                                src={
                                  notification &&
                                  notification.related_post_details &&
                                  notification.related_post_details.image
                                }
                                alt="post images"
                                className="rounded"
                              />
                            </div>
                          ) : null}

                          {notification &&
                          notification.related_content_details &&
                          notification.related_content_details
                            .announcement_item_details &&
                          notification.related_content_details
                            .announcement_item_details.title_image ? (
                            <div className="related-img ml-2">
                              <img
                                src={
                                  notification &&
                                  notification.related_content_details &&
                                  notification.related_content_details
                                    .announcement_item_details &&
                                  notification.related_content_details
                                    .announcement_item_details.title_image
                                }
                                alt="post images"
                                className="rounded"
                              />
                            </div>
                          ) : null}
                      </div>
                    ))
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  mountNotification: () => dispatch(get_notification_action()),
});

export default connect(null, mapDispatchToProps)(Notifications);
