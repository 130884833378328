import React, {Component} from "react";

import swal from "sweetalert";
import {connect} from "react-redux";
import {Button, Form, Modal} from "react-bootstrap";

import "./UploadRecipeContent.scss";
import ContentLoader from "../../../../../Common/Loader/ContentLoader";
import {isEmptyObject, validateUrl,} from "../../../../../../_helpers/validators";
import {
  delete_connected_recipe_action,
  delete_saved_recipe_changes_action,
  get_saved_recipe_changes_action,
  publish_recipe_video_action,
  save_recipe_video_action, update_recipe_details_action,
} from "../../../../../../_actions/Admin";

class UploadRecipeContent extends Component {
  state = {
    title: this.props.recipeDetails !== null ? this.props.recipeDetails.title : '',
    recipes: [],
    mediaImg: null,
    mediaImgUrl: this.props.recipeDetails !== null ? this.props.recipeDetails['title_image'] : null,
    errorText: "",
    youtubeUrl: this.props.recipeDetails ? this.props.recipeDetails['youtube_link'] : '',
    mediaImgName: '',
    successMessage: "",
    recipeIdToDelete: null,
    isApiLoading: false,
    deleteRecipeModal: false,
    deleteConnectedRecipe: false,
    inputError: { type: "", text: "" },
    isChangesSaved: this.props.recipeDetails !== null,
    loading: false,
    publishLoading: false
  };

  //Handle change for inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "youtubeUrl") {
        if (!validateUrl(value) && this.state.youtubeUrl.length) {
          this.setState({
            inputError: { type: name, text: `Please enter a valid url!` },
          });
          return;
        } else {
          this.setState({ inputError: { type: "", text: `` } });
        }
      }

      if (name === "title" && !this.state.title.length) {
        this.setState({
          inputError: { type: "title", text: `Title is required!` },
        });
      } else {
        this.setState({ inputError: { type: "", text: `` } });
      }
    });
  };

  //Handle change for image input
  handleMediaImgChange = (e) => {
    const file = e.target.files[0];

    this.setState((prevState) => ({
      mediaImg: file ? file : prevState.mediaImg,
      mediaImgName: file && file.name ? file.name : prevState.mediaImgName,
    }));
  };

  //On click of save changes
  onSaveChanges = async () => {
    const { title, mediaImg, youtubeUrl, mediaImgUrl } = this.state;

    if (!title.length || !mediaImg && !mediaImgUrl) {
      this.setState({
        errorText: "Title, Media and Youtube link fields are mandatory!",
      });
      return;
    }

    this.setState({ isApiLoading: true });

    const formData = new FormData();
    formData.append("title", title);
    if (mediaImg) {
      formData.append("title_image", mediaImg);
    }
    formData.append("youtube_link", youtubeUrl);
    formData.append("is_publish", 'false');

    if (this.props.recipeDetails) {

      const updateRecipeRes = await this.props.updateRecipeDetails(this.props.recipeDetails.id, formData)
      if (updateRecipeRes) {
        this.setState({ successMessage: `Recipe updated Successfully!` })
        window.location.reload()
      } else
        this.setState({ errorText: `Recipe Update Unsuccessful!` })

    } else {

      const saveRecipeRes = await this.props.onSaveRecipeVideo(formData);
      if (saveRecipeRes) {
        this.setState({
          isChangesSaved: true,
          successMessage: `Recipe uploaded Successfully!`,
          mediaImgUrl: saveRecipeRes.data['title_image']
        })
        this.props.history.push(`/update-content/recipe/${saveRecipeRes.data.id}`)
        window.location.reload()
      } else
        this.setState({ errorText: `Recipe upload Unsuccessful!` })

    }

    this.setState({ isApiLoading: false });
  };

  onAddRecipeClick = () => {
    const { isChangesSaved } = this.state;
    const { recipeDetails } = this.props

    if (!isChangesSaved) {
      this.setState({
        errorText: "You will have to save changes before adding recipes!",
      });
      return;
    }

    if (isChangesSaved && recipeDetails !== null)
      this.props.history.push(`/upload-recipe-instructions/recipe/${recipeDetails.id}/new-recipe`)
  };

  toggleDeleteSavedRecipe = (e, recipeId) => {
    this.setState((prevState) => ({
      deleteRecipeModal: !prevState.deleteRecipeModal,
      recipeIdToDelete: recipeId
    }));
  };

  onDeleteSavedRecipeClick = async () => {
    const { isChangesSaved, recipeIdToDelete } = this.state;

    if (isChangesSaved && recipeIdToDelete) {
      this.setState({ loading: true })
      const data = {
        delete_connected_recipe: [recipeIdToDelete]
      }
      await this.props.onDeleteConnectedRecipe(this.props.recipeDetails.id, data);
      this.setState(prevState => ({ deleteRecipeModal: !prevState.deleteRecipeModal, loading: !prevState.loading }))
      window.location.reload()
    }
  };

  onPublishRecipeClick = async () => {
    const { title, mediaImg, youtubeUrl, mediaImgName,  isChangesSaved } = this.state;
    
    if (!title.length) {
      this.setState({
        errorText: "Title field are mandatory!",
      });
      return;
    }

    this.setState({ publishLoading: true })
    const formData = new FormData();
    formData.append("title", title);
    if(mediaImgName.length) {
      formData.append("title_image", mediaImg);
    }
    formData.append("youtube_link", youtubeUrl);
    formData.append("is_publish", 'true');
    const publishRes = await this.props.onPublishRecipe(this.props.recipeDetails.id, formData);
    this.setState({ publishLoading: false })
    if (publishRes) {
      swal('Recipe Published Successfully!')
      this.props.history.push('/upload-content-listing')
    }
  };

  render() {
    //Importing required state
    const {
      title,
      errorText,
      inputError,
      youtubeUrl,
      isApiLoading,
      mediaImgName,
      isChangesSaved,
      successMessage,
      mediaImgUrl,
      loading,
      publishLoading
    } = this.state;

      let savedContent = JSON.parse(localStorage.getItem("contentRecipe"));
    savedContent =
      savedContent === null ||
      savedContent === undefined ||
      (savedContent &&
        savedContent.contentType &&
        savedContent.contentType !== "youtube_recipe")
        ? {}
        : savedContent;

    return (
      <div className="upload-recipe-content">
        <div className="form-outer bg-grey-6 fs-16 font-weight-semi">
          <div>
            <Form.Group className="bg-grey-4 p-2 text-black fs-18 rounded font-weight-bold">
              <Form.Control
                type="text"
                name="title"
                value={title}
                placeholder="Title"
                onChange={this.handleChange}
                className={`font-weight-semi fs-16 text-black ${
                  inputError.type === "title" ? "input-invalid" : ""
                }`}
              />
              {inputError.type === "title" && inputError.text.length && (
                <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                  {inputError.text}
                </p>
              )}
            </Form.Group>

            <Form.Group className="bg-grey-4 p-3">
              <Form.Label className="fs-14 font-weight-bold text-grey-5 mb-3">
                Media
              </Form.Label>
              <label className="btn btn-block font-weight-semi text-grey-5 bg-white rounded">
                <input
                  type="file"
                  className="d-none invisible"
                  id="titleimge"
                  accept="image/*"
                  onChange={this.handleMediaImgChange}
                />
                {
                  mediaImgName
                      ? mediaImgName
                      : "Add title Image"
                }
              </label>
              {
                mediaImgUrl ?
                    <img
                        src={mediaImgUrl}
                        alt="upload image"
                    />
                    : null

              }
              <Form.Control
                type="text"
                name="youtubeUrl"
                onChange={this.handleChange}
                value={youtubeUrl}
                placeholder="Paste YouTube video link here"
                className={`bg-white mt-2 text-grey-5 font-weight-semi py-3 h-auto ${
                  inputError.type === "youtubeUrl" ? "input-invalid" : ""
                }`}
              />
              {/* error message for wrong youtube url */}
              {inputError.type === "youtubeUrl" && inputError.text.length && (
                <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                  {inputError.text}
                </p>
              )}
            </Form.Group>

            <div className="form-outer p-3  bg-grey-4 fs-16 font-weight-semi">
              <p className="fs-14 font-weight-bold text-grey-5 mb-3">Recipe</p>

              <Form.Group>
                <ul>
                  {
                    this.props.recipeDetails &&
                    this.props.recipeDetails['connected_recipe'] &&
                    this.props.recipeDetails['connected_recipe'].length ?
                        this.props.recipeDetails['connected_recipe'].map((recipe) => (
                            <li
                                className="d-flex mb-3"
                                key={recipe.id}
                            >
                              <button
                                  onClick={() => this.props.history.push(`/edit-recipe-instructions/recipe/${this.props.recipeDetails.id}/edit-recipe/${recipe.id}`)}
                                  className="btn btn-block bg-white font-weight-semi py-2 mr-2 border-info rounded text-grey-5 text-left"
                              >
                                {recipe.name}
                              </button>
                              <a
                                  href="#"
                                  className="ml-2 d-flex"
                                  onClick={(e) => this.toggleDeleteSavedRecipe(e, recipe.id)}
                              >
                                <img
                                    src={"/assets/images/delete-content.svg"}
                                    alt="delete"
                                />
                              </a>
                            </li>
                        ))
                        : null
                  }
                </ul>
              </Form.Group>

              <a
                style={{ cursor: "pointer" }}
                onClick={this.onAddRecipeClick}
                className="text-info d-block mt-4"
              >
                +{" "}
                {this.props.recipeDetails &&
                this.props.recipeDetails.connected_recipe &&
                this.props.recipeDetails.connected_recipe.length ?
                    "Add more"
                    : "Add Recipe"}
              </a>
            </div>

            {errorText.length ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                {errorText}
              </p>
            ) : (
              ""
            )}
            {successMessage.length ? (
              <p className="fs-14 text-success-2 font-source-sans text-left font-weight-semi">
                {successMessage}
              </p>
            ) : (
              ""
            )}
            <div className="action fs-14 d-flex justify-content-end mt-3 flex-wrap">
              {
                !isChangesSaved ?
                    <button
                        className="btn btn-outline-dark py-2 px-4 font-weight-semi my-2 rounded"
                        onClick={() => {
                          localStorage.removeItem("contentRecipe");
                          this.props.history.push('/upload-content-listing')
                        }}
                    >
                      Cancel
                    </button>
                    : null
              }
              {/* THIS BUTTON IS NOT IN USE <button className="btn btn-warning py-2 px-4 font-weight-semi ml-3  my-2 rounded">Save & Exit</button> */}
              {
                !isChangesSaved ?
                    <button
                        className="btn btn-info py-2 px-4 font-weight-semi ml-3  my-2  rounded"
                        disabled={ !title.length || !this.state.mediaImg && !this.state.mediaImgUrl }
                        onClick={this.onSaveChanges}
                    >
                      {isApiLoading ? <ContentLoader color="text-white" /> : "Save Changes"}
                    </button>
                    : null
              }

              {
                isChangesSaved ?
                    <button
                        className="btn btn-outline-dark py-2 px-4 font-weight-400 my-2 rounded ml-3"
                        onClick={() => {
                          localStorage.removeItem("contentRecipe");
                          this.props.history.push('/upload-content-listing')
                        }}
                    >
                      Exit
                    </button>
                    : null
              }
              {
                isChangesSaved ?
                    <button
                        className="btn btn-success py-2 px-4 font-weight-semi ml-3 my-2 rounded"
                        disabled={
                          isChangesSaved &&
                          this.props &&
                          this.props.recipeDetails &&
                          this.props.recipeDetails.connected_recipe &&
                          this.props.recipeDetails.connected_recipe.length === 0
                        }
                        onClick={this.onPublishRecipeClick}
                    >
                      {
                        publishLoading ?
                            <ContentLoader />
                            : 'Publish Recipe'
                      }
                    </button>
                    : null
              }

              {/* ================Changes after save- Ends========== */}
            </div>
          </div>
        </div>

        {/* FOR DELETING saved youtube RECIPE */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.deleteRecipeModal}
          onHide={this.toggleDeleteSavedRecipe}
          className="text-center delete-action-popup"
        >
          <Modal.Body className="delete-action-popup-content">
            <p className="font-poppins fs-18 text-center font-weight-medium">
              Are you sure you want to delete this saved recipe?
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
            <Button
              onClick={this.toggleDeleteSavedRecipe}
              variant="outline-dark"
              className="rounded"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              className="rounded"
              onClick={this.onDeleteSavedRecipeClick}
            >
              {
                  loading ?
                      <ContentLoader />
                      : 'Delete'
              }
            </Button>
          </Modal.Footer>
        </Modal>

        {/*/!* FOR DELETING CONNECTED RECIPE *!/*/}
        {/*<Modal*/}
        {/*  size="md"*/}
        {/*  aria-labelledby="contained-modal-title-vcenter"*/}
        {/*  centered*/}
        {/*  show={this.state.deleteConnectedRecipe}*/}
        {/*  onHide={this.onToggleDeleteConnectedRecipe}*/}
        {/*  className="text-center delete-action-popup"*/}
        {/*>*/}
        {/*  <Modal.Body className="delete-action-popup-content">*/}
        {/*    <p className="font-poppins fs-18 text-center font-weight-medium">*/}
        {/*      Are you sure you want to delete this recipe?*/}
        {/*    </p>*/}
        {/*  </Modal.Body>*/}
        {/*  <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">*/}
        {/*    <Button*/}
        {/*      onClick={this.toggleDeleteSavedRecipe}*/}
        {/*      variant="outline-dark"*/}
        {/*      className="rounded"*/}
        {/*    >*/}
        {/*      Cancel*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      variant="danger"*/}
        {/*      className="rounded"*/}
        {/*      onClick={(e) => this.onDeleteConnectedRecipeClick(e)}*/}
        {/*    >*/}
        {/*      Delete*/}
        {/*    </Button>*/}
        {/*  </Modal.Footer>*/}
        {/*</Modal>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    savedContent: state.content_manager_reducer,
    saveRecipeVideo: state.add_recipe_video_content_reducer,
    publishedContent: state.publish_recipe_video_content_reducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSaveRecipeVideo: (data) => dispatch(save_recipe_video_action(data)),
  getSavedRecipeVideo: (id) => dispatch(get_saved_recipe_changes_action(id)),
  onDeleteSavedVideo: (id) => dispatch(delete_saved_recipe_changes_action(id)),
  onPublishRecipe: (id, data) => dispatch(publish_recipe_video_action(id, data)),
  onDeleteConnectedRecipe: (id, data) => dispatch(delete_connected_recipe_action(id, data)),
  updateRecipeDetails: (id, data) => dispatch(update_recipe_details_action(id, data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadRecipeContent);