export const headerSearchConstants = {
    HEADER_SEARCH_ACTION_REQUEST: 'HEADER_SEARCH_ACTION_REQUEST',
    HEADER_SEARCH_ACTION_SUCCESS: 'HEADER_SEARCH_ACTION_SUCCESS',
    HEADER_SEARCH_ACTION_FAILURE: 'HEADER_SEARCH_ACTION_FAILURE',

    VIDEO_SEARCH_ACTION_REQUEST: 'VIDEO_SEARCH_ACTION_REQUEST',
    VIDEO_SEARCH_ACTION_SUCCESS: 'VIDEO_SEARCH_ACTION_SUCCESS',
    VIDEO_SEARCH_ACTION_FAILURE: 'VIDEO_SEARCH_ACTION_FAILURE',

    RECIPE_SEARCH_ACTION_REQUEST: 'RECIPE_SEARCH_ACTION_REQUEST',
    RECIPE_SEARCH_ACTION_SUCCESS: 'RECIPE_SEARCH_ACTION_SUCCESS',
    RECIPE_SEARCH_ACTION_FAILURE: 'RECIPE_SEARCH_ACTION_FAILURE',

    SHOW_SEARCH_ACTION_REQUEST: 'SHOW_SEARCH_ACTION_REQUEST',
    SHOW_SEARCH_ACTION_SUCCESS: 'SHOW_SEARCH_ACTION_SUCCESS',
    SHOW_SEARCH_ACTION_FAILURE: 'SHOW_SEARCH_ACTION_FAILURE',
}