import React, { Component } from "react";
import { Container, Row, Tabs, Tab, Col } from "react-bootstrap";
import SideBarMenu from "../common/SidebarMenu/SidebarMenu";
import Header from "../common/Header/Header";
import "./CollectionCategories.scss";
import CollectionCard from "./CollectionCard/CollectionCard";
import Client from "shopify-buy";

import Products from "../../User/ShopifyStore/Products";
import Cart from "../../User/ShopifyStore/Cart";

import "../../User/ShopifyStore/shopifystore.scss";
import ContentLoader from "../../Common/Loader/ContentLoader";
import config from '../../../Config/config';
class CollectionCategories extends Component {
  constructor() {
    super();
    this.state = {
      isCartOpen: false,
      checkout: { lineItems: [] },
      collections: [],
      currentCollection: [],
      currentCollectionId: localStorage.getItem("currentCollectionId"),
      productsForCollection: [],
      nextPageOfProducts: [],
      shop: {},
      client: Client.buildClient({
        storefrontAccessToken: config.shopifyStore.storefrontAccessToken,
        domain: config.shopifyStore.domain,
      }),
      key: localStorage.getItem("currentCollectionHandle"),
      isApiLoading:false,
      fetchingNextProducts:false,
      disableLoadMore:false,
      showMessage:""
    };

    this.handleCartClose = this.handleCartClose.bind(this);
    this.addVariantToCart = this.addVariantToCart.bind(this);
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
  }

  componentWillMount() {
    this.state.client.checkout.create().then((res) => {
      this.setState({
        checkout: res,
      });
    });

    this.setState({isApiLoading:true},()=>{
    this.state.client.collection.fetchAllWithProducts().then((collections) => {
      const renderCollections=collections.filter((collection)=>collection.handle=='t-shirt'|| 
      collection.handle=='cook-books' ||
      collection.handle=='apron' || 
      collection.handle=='hoodies-1' || 
      collection.handle=='candles');

      // Do something with the collections
      this.setState({
       // collections: collections,
       isApiLoading:false,
       collections:renderCollections
      });
    });
  })
  }
  /***************************cart code starts as follows*********************** */
  addVariantToCart(variantId, quantity) {
    this.setState({
      isCartOpen: true,
    });

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;

    return this.state.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id;
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];

    return this.state.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id;

    return this.state.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  handleCartClose() {
    this.setState({
      isCartOpen: false,
    });
  }

  /***************************cart code ends here*********************** */
  nextProducts = (prevCollection) => {
    this.setState({fetchingNextProducts:true},()=>{
    this.setState({ productsForCollection: prevCollection }, () => {
      this.state.client
        .fetchNextPage(this.state.productsForCollection)
        .then((nextPageOfProducts) => {
          this.setState(
            { nextPageOfProducts: nextPageOfProducts.model,fetchingNextProducts:false },
            () => {
              const { productsForCollection, nextPageOfProducts } = this.state;
              if(nextPageOfProducts[nextPageOfProducts && nextPageOfProducts.length - 1].hasNextPage ==true){
                this.setState({
                  productsForCollection: [
                    ...productsForCollection,
                    ...nextPageOfProducts,
                  ],
                });
              }else{
                this.setState({
                  productsForCollection: [
                    ...productsForCollection,
                    ...nextPageOfProducts,
                  ],
                  disableLoadMore:true,
                  showMessage:"No more products to show..."
                });
              }
      
            }
          );
        });
    });
  })
  };

  handleOnSelect = (k) => {
    this.setState({isApiLoading:true},()=>{
    this.state.client.collection.fetchByHandle(k).then((res) => {
      localStorage.setItem("currentCollectionId", res && res.id);
      localStorage.setItem("currentCollectionTitle", res && res.title);
      localStorage.setItem("currentCollectionHandle", res && res.handle);
      this.setState({
        productsForCollection: res.products,
        currentCollection: res,
        key: res.handle,
        currentCollectionId: res.id,
        isApiLoading:false
      });
    });
  })
  };
  render() {
    const {
      productsForCollection
    } = this.state;

    return (
      <div className="common-dashboard collections-category-wrapper">
        <Container className="p-0">
          <SideBarMenu />
          <div className="main-content bg-white position-relative">
            <Header />
            {/* Collection tabs */}
            <div className="collection-wrapper pl-3">
              {/*    <header>
          {!this.state.isCartOpen &&
            <div className="App__view-cart-wrapper">
              <button className="App__view-cart"
               onClick={()=> this.setState({isCartOpen: true})}>
                  <img
                  src="/assets/images/cart.svg"
                  alt="Shopping-cart"
                  className="mr-3"
                />
               </button>
            </div>
          }
        </header> */}
              {/**all collection code starts here****************** */}
              {this.state.isApiLoading ?
                       <div
                        style={{
                          margin: "50px auto 0",
                          textAlign: "center",
                        }}
                      >
                        <ContentLoader color="text-primary" />
                      </div>
                      :
              <Tabs
                className="comments-recipe-tab"
                //defaultActiveKey="All-T-Shirts"
                activeKey={this.state.key}
                onSelect={(k) => this.handleOnSelect(k)}
                id="CollectionsTab"
              >
           
               { this.state.collections.map((collection) => (
                  <Tab
                    eventKey={collection && collection.handle}
                    title={collection && collection.title}
                  >
                  {this.state.isApiLoading ?
                       <div
                        style={{
                          margin: "50px auto 0",
                          textAlign: "center",
                        }}
                      >
                        <ContentLoader color="text-primary" />
                      </div>
                      :
                    <Products
                      products={
                        productsForCollection &&
                        productsForCollection.length > 0
                          ? productsForCollection
                          : collection.products
                      }
                      client={this.state.client}
                      addVariantToCart={this.addVariantToCart}
                    />}
                  {/*   {collection.products && collection.products.length >= 20 ? */}
                  {collection.products[collection.products && collection.products.length - 1]
                    && collection.products[collection.products && collection.products.length - 1].hasNextPage ? 
                     (
                      <div className="text-center">
                      {this.state.disableLoadMore?(<div>{this.state.showMessage}</div>) :(
                        <button
                          className="btn btn-warning py-2 px-3"
                          onClick={() => this.nextProducts(collection.products)}
                        >
                        {this.state.fetchingNextProducts?
                          <ContentLoader />
                        : <div>Load more</div> 
                        }
                        </button>
                        )}
                      </div>
                    ) : collection.products &&
                      collection.products.length == 0 ? (
                      
                      <div className="text-center p-4 mt-4 d-flex justify-content-center">
                        <img
                          src={"/assets/images/no-products.png"}
                          alt="no recipes"
                       
                        />
                      </div>
                      
                    ) : null}
                    <Cart
                      checkout={this.state.checkout}
                      isCartOpen={this.state.isCartOpen}
                      handleCartClose={this.handleCartClose}
                      updateQuantityInCart={this.updateQuantityInCart}
                      removeLineItemInCart={this.removeLineItemInCart}
                    />
                  </Tab>
                ))}
                
              </Tabs>}
              

              {/************************all collection code ends here****************** */}
              {/*  <Tabs
                className="comments-recipe-tab"
                defaultActiveKey={currentCollection && currentCollection.title}
                id="CollectionsTab"
              > */}
              {/*======================== hoodies list========================*/}
              {/*         <Tab eventKey={currentCollection && currentCollection.title}
                      title={currentCollection && currentCollection.title}>
                  <Row>
                  <Products
                    products={this.state.productsForCollection}
                    client={this.state.client}
                    addVariantToCart={this.addVariantToCart}
                  /> 
                <button onClick={this.nextProducts}>Load more</button> 

                <Cart
                  checkout={this.state.checkout}
                  isCartOpen={this.state.isCartOpen}
                  handleCartClose={this.handleCartClose}
                  updateQuantityInCart={this.updateQuantityInCart}
                  removeLineItemInCart={this.removeLineItemInCart}
                />
                  </Row>
            </Tab> */}

              {/**=========================hoodies list ends here========================= */}

              {/* 
                <Tab eventKey="T-Shirt" title="T-Shirt">
                  <Row>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/t-shirt-1.svg"
                        productname="Not Upset Tee"
                        productprice="$69"
                      />
                    </Col>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/t-shirt-1.svg"
                        productname="Not Upset Tee"
                        productprice="$69"
                      />
                    </Col>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/t-shirt-1.svg"
                        productname="Not Upset Tee"
                        productprice="$69"
                      />
                    </Col>
                  </Row>
                </Tab>

                
                <Tab eventKey="CookBooks" title="CookBooks">
                  <Row>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/cookbook-1.svg"
                        productname=" Everyday Keto Cookbook-PHYSICAL COOKBOOK"
                        productprice="$69"
                      />
                    </Col>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/cookbook-1.svg"
                        productname=" Everyday Keto Cookbook-PHYSICAL COOKBOOK"
                        productprice="$69"
                      />
                    </Col>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/cookbook-1.svg"
                        productname=" Everyday Keto Cookbook-PHYSICAL COOKBOOK"
                        productprice="$69"
                      />
                    </Col>
                  </Row>
                </Tab>

                
                <Tab eventKey="Aprons" title="Aprons">
                  <Row>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/cookbook-1.svg"
                        productname=" Everyday Keto Cookbook-PHYSICAL COOKBOOK"
                        productprice="$69"
                      />
                    </Col>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/cookbook-1.svg"
                        productname=" Everyday Keto Cookbook-PHYSICAL COOKBOOK"
                        productprice="$69"
                      />
                    </Col>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/cookbook-1.svg"
                        productname=" Everyday Keto Cookbook-PHYSICAL COOKBOOK"
                        productprice="$69"
                      />
                    </Col>
                  </Row>
                </Tab>

                
                <Tab eventKey="Candles" title="Candles">
                  <Row>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/candle.svg"
                        productname=" Candle"
                        productprice="$25"
                      />
                    </Col>
                    <Col col={6} lg={3}>
                      <CollectionCard
                        productimg="/assets/images/candle.svg"
                        productname=" Candle"
                        productprice="$25"
                      />
                    </Col>
                  </Row>
                </Tab>  */}
              {/*  </Tabs> */}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default CollectionCategories;
