import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import * as PaymentActions from "../../../../_actions/User/payment.action";
import "./CheckoutForm.scss";
import swal from "sweetalert";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import { withRouter } from "react-router-dom";
import { history } from "../../../../_helpers/history";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const handleCheck = () => {
    setChecked(!checked);
  };
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const planType = JSON.parse(localStorage.getItem("plan"));
  const productDetail = JSON.parse(localStorage.getItem("productDetail"));

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    const userDetail = JSON.parse(localStorage.getItem("user"));
    //customer: userDetail && userDetail.user && userDetail.user.email
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: userDetail && userDetail.user && userDetail.user.email,
      },
    });

    if (error) {
      setLoading(false)
      return;
    } else {
      
      const productDetail = JSON.parse(localStorage.getItem("productDetail"));
      const planType = JSON.parse(localStorage.getItem("plan"));
    
      if (planType && planType.plan == "per_product") {
        var payload = {
          payment_method_id: paymentMethod.id,
          product_type: productDetail && productDetail.content_type,
          product_id: productDetail && productDetail.id,
        };

        const response = await props.singleProductPayment(payload);
        setLoading(false);

        if (response) {
          /*           localStorage.removeItem("productDetail");
                    localStorage.removeItem("plan"); */
          props.history.goBack();
          swal(response && response.data && response.data.msg);
        }
      } else if (planType && planType.plan == "upgrade") {
        const response = await props.upgradePayement();
        setLoading(false);
        if (response) {
          /*   localStorage.removeItem("productDetail");
                  localStorage.removeItem("plan"); */
          props.history.push("/user-plan-details");
          swal(response && response.data && response.data.msg);
        }
      } else {
        var payload = {
          payment_method_id: paymentMethod.id,
          plan: planType && planType.plan,
          automatic: checked == true ? "on" : "off",
        };

        const response = await props.subscriptionProductPayment(payload);
        setLoading(false);

        if (response) {
          props.history.push("/user-plan-details");
          swal(response && response.data && response.data.msg);
        }
      }
    }
  };

  return (
    <div className="checkout-wrapper d-flex justify-content-center align-items-center">
      <div className="checkout-inner ">
        <div className="container px-0">
          <div className="row no-gutters bg-white">
            <div className="col-md-6">
              <div className="left">
                <img
                  className="w-100"
                  src="../../../../assets/images/make-payment-img.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="right py-5 px-lg-0 mx-auto">
                <h4 className="plan-type text-uppercase text-primary text-center border-bottom  mb-5 pb-2">
                  {planType && planType.plan !== "per_product" ? (
                    <>{planType && planType.plan}</>
                  ) : (
                    <>
                      {productDetail
                        ? productDetail && productDetail.content_type
                        : null}
                    </>
                  )}
                </h4>

                <h3 className="text-primary mb-4 pb-md-3">
                  Add your card details
                </h3>
                <form onSubmit={handleSubmit}>
                  <CardElement className="mb-4" />

                  {planType.plan !== "upgrade" &&
                  planType.plan !== "per_product" ? (
                    <>
                      <label
                        htmlFor="checkbox"
                        className="custom-control custom-checkbox text-primary font-smallest font-weight-medium"
                        onChange={handleCheck}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkbox"
                          name=""
                          checked={checked == true ? true : false}
                        />
                        <span
                          className="custom-control-label cursor-pointer"
                          htmlFor="checkbox"
                        ></span>
                        Apply automatic {planType && planType.plan} renewal for
                        this plan.
                      </label>
                    </>
                  ) : null}
                  <div className="text-center mt-4">
                    <div className=" rounded-pill  d-flex bg-grey-5 align-items-center ">
                      <button
                        type="submit"
                        className="btn btn-primary text-white py-sm-3 px-sm-5 flex-grow-1"
                        disabled={!stripe}
                      >
                        {loading ? (
                          <ContentLoader color="text-white" />
                        ) : (
                          <span> Make Payment</span>
                        )}
                      </button>
                      <span className="d-inline-block text-primary font-weight-bold fs-24 py-sm-3 px-4">
                      ${planType && planType.amount}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="left">
          <div className="overlay bg-primary"></div>
          <div className="services-availed">
            <h2 className="text-white mb-5">Plan Availed</h2>
            <div className="service ">
              <div className="service-img">
                <img src="../../../../assets/images/bg-login.svg" alt="" />
              </div>

              {planType && planType.plan !== "per_product" ? (
                <>
                  <div className="m-2">{planType && planType.plan}</div>
                </>
              ) : (
                <>
                  <div className="m-2">
                    {productDetail
                      ? productDetail && productDetail.content_type
                      : null}
                  </div>
                </>
              )}
            </div>
            <div className="total">
              <span>Total Cost </span>

              <span>${planType && planType.amount} </span>
            </div>
          </div>
        </div>
        <div className="right">
          <h2 className="bg-red text-white p-3">Payment</h2>
          <div className="payment-section">
            <div className="payment-options-img">
              <img src="images/pay_by_cards.jpg" alt="" />
            </div>
            <form onSubmit={handleSubmit}>
              <CardElement />
              <button
                type="submit"
                className="btn bg-primary text-white"
                disabled={!stripe}
              >
                {loading ? (
                  <ContentLoader color="text-white" />
                ) : (
                  <span> Make Payment</span>
                )}
              </button>

              {planType.plan !== "upgrade" &&
              planType.plan !== "per_product" ? (
                <>
                  <label
                    htmlFor="checkbox"
                    className="custom-control custom-checkbox "
                    onChange={handleCheck}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkbox"
                      name=""
                      checked={checked == true ? true : false}
                    />
                    <span
                      className="custom-control-label cursor-pointer"
                      for="checkbox"
                    ></span>
                    Apply automatic {planType && planType.plan} renewal for this
                    plan.
                  </label>
                </>
              ) : null}
            </form>
          </div>
        </div> */}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({
  singleProductPayment: (payload) =>
    dispatch(PaymentActions.single_product_payment_action(payload)),
  subscriptionProductPayment: (payload) =>
    dispatch(PaymentActions.subscription_payment_action(payload)),
  upgradePayement: () => dispatch(PaymentActions.upgrade_payment_action()),
});

const connectedCheckoutForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
);
export { connectedCheckoutForm as CheckoutForm };
