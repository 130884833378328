import { profileConstants } from "../../_constants/User";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";
import Password from "antd/lib/input/Password";

// get profile content from backend
export const get_profile_content_action = () => async (dispatch) => {
  dispatch({
    type: profileConstants.GET_PROFILE_CONTENT_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.userProfile.profileDetails);
    if (response.status) {
      dispatch({
        type: profileConstants.GET_PROFILE_CONTENT_ACTION_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: profileConstants.GET_PROFILE_CONTENT_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

// edit user profile Name
export const edit_user_name_action = (data) => async (dispatch) => {
  dispatch({
    type: profileConstants.EDIT_USER_NAME_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(
      config.userProfile.profileDetails,
      data
    );
    if (response.status) {
      dispatch({
        type: profileConstants.EDIT_USER_NAME_ACTION_SUCCESS,
        payload: response.data.msg,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: profileConstants.EDIT_USER_NAME_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

// edit user profile Image
export const edit_user_image_action = (data) => async (dispatch) => {
  dispatch({
    type: profileConstants.EDIT_PROFILE_IMAGE_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(
      config.userProfile.editProfileImg,
      data
    );
    if (response.status) {
      dispatch({
        type: profileConstants.EDIT_PROFILE_IMAGE_ACTION_SUCCESS,
        payload: response.data.msg,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: profileConstants.EDIT_PROFILE_IMAGE_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

// changes user Password
export const change_password_action = (data) => async (dispatch) => {
  dispatch({
    type: profileConstants.CHANGE_PASSWORD_ACTION_REQUEST
  });
  try {
    const response = await axiosInstance.post(
      config.userProfile.changePassword,
      data
    );
    if (response.status) {
      dispatch({
        type: profileConstants.CHANGE_PASSWORD_ACTION_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: profileConstants.CHANGE_PASSWORD_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};
