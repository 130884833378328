export const reportsConstants = {

    GET_ALL_REPORTED_CONTENT_ACTION_REQUEST: 'GET_ALL_REPORTED_CONTENT_ACTION_REQUEST',
    GET_ALL_REPORTED_CONTENT_ACTION_SUCCESS: 'GET_ALL_REPORTED_CONTENT_ACTION_SUCCESS',
    GET_ALL_REPORTED_CONTENT_ACTION_FAILURE: 'GET_ALL_REPORTED_CONTENT_ACTION_FAILURE',

    GET_REPORTED_POST_ACTION_REQUEST: 'GET_REPORTED_POST_ACTION_REQUEST',
    GET_REPORTED_POST_ACTION_SUCCESS: 'GET_REPORTED_POST_ACTION_SUCCESS',
    GET_REPORTED_POST_ACTION_FAILURE: 'GET_REPORTED_POST_ACTION_FAILURE',

    GET_REPORTED_COMMENT_ACTION_REQUEST: 'GET_REPORTED_COMMENT_ACTION_REQUEST',
    GET_REPORTED_COMMENT_ACTION_SUCCESS: 'GET_REPORTED_COMMENT_ACTION_SUCCESS',
    GET_REPORTED_COMMENT_ACTION_FAILURE: 'GET_REPORTED_COMMENT_ACTION_FAILURE',

    TAKE_ON_REPORT_ACTION_REQUEST: 'TAKE_ON_REPORT_ACTION_REQUEST',
    TAKE_ON_REPORT_ACTION_SUCCESS: 'TAKE_ON_REPORT_ACTION_SUCCESS',
    TAKE_ON_REPORT_ACTION_FAILURE: 'TAKE_ON_REPORT_ACTION_FAILURE',

}