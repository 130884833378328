import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PopularVideos.scss";
import ContentLoader from "../../../Common/Loader/ContentLoader";

class PopularVideos extends Component {
  routeHandler = (id) => {
    this.props.history.push(`/video/${id}`);
  };
  
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className="popular-video-slider pt-sm-4 mx-n1">
        <h3 className="font-weight-semi d-inline-block pt-4 mb-3 pl-2">
          Popular Videos
        </h3>
        <a
          href={"/all-videos"}
          className="fs-18 font-weight-semi font-poppins text-primary pl-5"
        >
          view all{" "}
          <img src={"/assets/images/next-btn.svg"} className="ml-3" alt="" />{" "}
        </a>

        {this.props && this.props.videoList && this.props.videoList.length ? (
          <Slider {...settings}>
            {this.props.loading ? (
              <ContentLoader color="text-warning" />
            ) : (
              this.props &&
              this.props.videoList &&
              this.props.videoList.map((vDetails) => (
                <div
                  key={vDetails.id}
                  className="popular-video-preview cursor-pointer px-2"
                >
                  <div
                    className="img-box border-6"
                    onClick={() =>
                      this.routeHandler(vDetails["video_details"]["id"])
                    }
                  >
                    <img
                      src={vDetails["cover_image"]}
                      alt="slider-img-1"
                      className="img-fit w-100"
                    />
                    {vDetails &&
                    vDetails.video_details &&
                    vDetails.video_details.is_premium ? (
                      <span className="d-flex align-items-center bg-warning px-2 text-white premium-tag position-absolute">
                        <img
                          src={"../assets/images/royal-crown.png"}
                          alt="crown"
                          className="mr-1"
                          style={{maxWidth:'15px'}}
                        />
                        Premium
                      </span>
                    ) : null}
                  </div>
                  <h4 className="title font-weight-semi my-2">
                    {vDetails["video_details"]["title"]}
                  </h4>
                </div>
              ))
            )}
          </Slider>
        ) : (
          <div className="col-12 mt-3">
            <img src={"/assets/images/novideos-home.png"} alt="" />
            <h4>No Populars Videos Yet</h4>
          </div>
        )}
      </div>
    );
  }
}

export default PopularVideos;
