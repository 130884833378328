import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { connect } from 'react-redux'
import TextEditor from "../../Common/TextEditor/TextEditor";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import "./AddPost.scss";
import {add_post_action, get_status_by_id_action, update_post_by_id_action} from "../../../_actions/User/post.action";
import ContentLoader from "../../Common/Loader/ContentLoader";
import {notification} from "antd";
import ReactHtmlparser from "react-html-parser";

const toolbarConfig = {
  options: ["inline", "list"],
  inline: {
    inDropdown: false,
    bold: { className: "bg-grey-7 px-2" },
    italic: { className: "bg-grey-7 px-2" },
    underline: { className: "bg-grey-7 px-2" },
    strikethrough: { className: "d-none" },
    monospace: { className: "d-none" },
    superscript: { className: "d-none" },
    subscript: { className: "d-none" },
  },
  list: {
    inDropdown: false,
    options: ["unordered", "ordered", "indent", "outdent"],
    unordered: { className: "bg-grey-7 px-2" },
    ordered: { className: "bg-grey-7 px-2" },
    indent: { className: "d-none" },
    outdent: { className: "d-none" },
  },
};

class AddPost extends Component {
  state = {
    postType: "",
    statusText: "",
    desc: "",
    recipeName: "",
    editorText: "",
    submitTriggered: false,
    mediaImg: null,
    mediaImgName: '',
    image: null,
    mediaVideo: null,
    mediaVideoName: '',
    submittingDataLoader: false,
    submitUnsuccessful: false,
    postLoading: false,
    postDataBeforeEdit: null,
    clearInstruction: false,
    areChangesPresent: false,
    errorMessage: ''
  };

  componentDidMount() { this.mountPostData() }

  mountPostData = async () => {
    if (this.props.match.params.id === "add-new") return

    this.setState({ postLoading: true })

    const getPostRes = await this.props.getPostData(
        this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
    )

    if (
        getPostRes &&
        getPostRes.data
    ) {
      await this.setState({
        postDataBeforeEdit: getPostRes.data,
        postType: getPostRes.data['post_type'],
        statusText: getPostRes.data['status_text'],
        desc: getPostRes.data['description'],
        recipeName: getPostRes.data['recipe_name'],
        editorText: getPostRes.data['instructions'],
        image: getPostRes.data['image'],
        mediaVideo: getPostRes.data['video']
      })
    } else
      this.props.history.push('/add-post/add-new')

    this.setState({ postLoading: false })
  }

  OnChangeSelect = (event) => {
    if (this.props.match.params.id !== "add-new") return

    let getValue = event.target.value;
    this.setState({
      postType: getValue,
      statusText: "",
      desc: "",
      recipeName: "",
      editorText: "",
      submitTriggered: false,
      mediaImg: null,
      mediaImgName: '',
      image: null,
      mediaVideo: null,
      mediaVideoName: '',
      submittingDataLoader: false,
      submitUnsuccessful: false,
      clearInstruction: true,
      areChangesPresent: false
    }, () => {
      this.setState({ clearInstruction: false })
    });
  };

  onChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({ [name]: value }, () => {
      if (this.state.postDataBeforeEdit)
        this.checkForChanges()
    })
  }

  handleUpdateEditorText = (data) => {
    this.setState({ editorText: data }, () => {
      if (this.state.postDataBeforeEdit)
        this.checkForChanges()
    })
  }

  checkForChanges = () => {
    const {
      postDataBeforeEdit,
      statusText,
      recipeName,
      editorText,
      postType,
      image,
      desc
    } = this.state

    if (
        postType !== postDataBeforeEdit['post_type'] ||
        editorText !== postDataBeforeEdit['instructions'] ||
        desc !== postDataBeforeEdit['description'] ||
        statusText !== postDataBeforeEdit['status_text'] ||
        recipeName !== postDataBeforeEdit['recipe_name'] ||
        image !== postDataBeforeEdit['image']
    ) this.setState({ areChangesPresent: true })
    else this.setState({ areChangesPresent: false })
  }

  //Handle change for image input
  handleMediaImgChange = (e) => {
    const file = e.target.files[0];

    this.setState((prevState) => ({
      image: URL.createObjectURL(file),
      mediaImg: file ? file : prevState.mediaImg,
      mediaImgName: file && file.name ? file.name : prevState.mediaImgName,
    }), () => {
      if (this.state.postDataBeforeEdit)
        this.checkForChanges()
    });
  };

  //Handle change for Video input
  handleMediaVideoChange = (e) => {
    const file = e.target.files[0];

    this.setState((prevState) => ({
      mediaVideo: file ? file : prevState.mediaVideo,
      mediaVideoName: file && file.name ? file.name : prevState.mediaVideoName,
    }), () => {
      if (this.state.postDataBeforeEdit)
        this.checkForChanges()
    });
  };

  handlePostSubmit = async (type) => {
    this.setState({ submitTriggered: true })

    const {
      postDataBeforeEdit,
      mediaVideoName,
      mediaImgName,
      recipeName,
      editorText,
      mediaVideo,
      statusText,
      postType,
      mediaImg,
      desc,
    } = this.state

    if (!postType) return

    const detailsForm = new FormData();
    detailsForm.append("post_type", postType.toLowerCase())
    let redirectRoute = ''

    switch (postType) {
      case "photo":

        if (!postDataBeforeEdit && (!mediaImg || !mediaImgName || !desc)) return

          if (mediaImg && mediaImgName)
            detailsForm.append("image", mediaImg)
        detailsForm.append("description", desc)
          redirectRoute = "Photos"
        break;

      case "video":
        if (!postDataBeforeEdit && (!mediaVideo || !mediaVideoName || !desc)) return

        if (mediaVideo && mediaVideoName)
          detailsForm.append("video", mediaVideo)
        detailsForm.append("description", desc)
        redirectRoute = "Videos"
        break;

      case "recipe":
        if (!postDataBeforeEdit && (!recipeName || !mediaImg || !mediaImgName || !editorText)) return

        detailsForm.append("recipe_name", recipeName)
        if (mediaImg && mediaImgName)
          detailsForm.append("image", mediaImg)
        detailsForm.append("instructions", editorText)
        redirectRoute = "Recipe"
        break;

      case "status":
        if (!postDataBeforeEdit && (!statusText.length)) return
        detailsForm.append("status_text", statusText)
        redirectRoute = "Status"
        break;

      default:
    }

    if (type === "update") {
      await this.updatePostSubmit(detailsForm, redirectRoute)
      return
    }

    this.setState({ submittingDataLoader: true })

    const addPostRes = await this.props.addPost(detailsForm)
    if (
        addPostRes &&
        addPostRes.msg &&
        addPostRes.msg.toLowerCase().includes("successfully created")
    ) {
      this.props.history.push(`/user-posts/${redirectRoute}`)
    } else
      this.setState({ submitUnsuccessful: true, errorMessage: addPostRes && addPostRes.msg })

    this.setState({ submittingDataLoader: false })
  }

  updatePostSubmit = async (formData, redirectRoute) => {
    this.setState({ submittingDataLoader: true })

    const updatePostRes = await this.props.updatePost(formData, this.props.match.params.id)
    if (
        updatePostRes &&
        updatePostRes.msg &&
        updatePostRes.msg.toLowerCase().includes("successfully")
    ) this.props.history.push(`/user-posts/${redirectRoute}`)
    else
      this.setState({ submitUnsuccessful: true, errorMessage: updatePostRes && updatePostRes.msg })

    this.setState({ submittingDataLoader: false })
  }

  requiredFieldFlag = () => {
    return (
        <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
          Field required!
        </p>
    )
  }

  openNewNotification = (payload) => {
    notification['info']({
      message: ReactHtmlparser(payload.body),
      description: ReactHtmlparser(payload.title)
    })
  }

  render() {
    const {
      submittingDataLoader,
      postDataBeforeEdit,
      submitUnsuccessful,
      areChangesPresent,
      clearInstruction,
      submitTriggered,
      mediaVideoName,
      errorMessage,
      mediaImgName,
      postLoading,
      mediaVideo,
      recipeName,
      editorText,
      statusText,
      mediaImg,
      postType,
      image,
      desc
    } = this.state

    return (
      <div className="common-dashboard upload-content-options">
        <Container className="p-0">
          <SidebarMenu
              alertNewNotification={(payload) => this.openNewNotification(payload)}
              history={this.props.history}
          />
          <div className="main-content bg-white">
            <div className="upload-content-options-wrapper ">
              <Row className="no-gutters">
                <Col md={6}>
                  <div className="select-wrapper">

                    {/* ------heading----- */}
                    <h2 className=" top-heading text-center">
                      <div className="py-4 bg-white "> Chef Whitelabel</div>
                    </h2>

                    {/* --------back-option------ */}
                    <a
                        className="d-flex align-items-center mt-5 pt-5"
                    >
                      <img
                        src={"/assets/images/back-arrow.svg"}
                        alt="back"
                        className="ml-n2"
                      />
                      <span
                          onClick={
                            postDataBeforeEdit ?
                                () => this.props.history.push("/user-posts")
                                : () => this.props.history.push("/user-home-page")
                          }
                          className="text-dark fs-16 font-weight-bold ml-1"
                      >
                        Back
                      </span>
                    </a>

                    {
                      postLoading ?
                          <div style={{
                            margin: "50px auto 0",
                            textAlign: 'center'
                          }}>
                            <ContentLoader color='text-warning'/>
                          </div>
                          : <div className="select-options-content  mx-auto py-5 ">
                            <Form>

                              {/* select post type */}
                              <Form.Group controlId="">
                                <Form.Control
                                    as="select"
                                    className="h-100 p-3"
                                    value={postType ? postType : null}
                                    onChange={this.OnChangeSelect}
                                    disabled={this.props.match.params.id !== "add-new"}
                                >
                                  <option
                                      disabled
                                      selected={!postType}
                                      hidden
                                  >
                                    Select Type
                                  </option>
                                  <option
                                      className="photo"
                                      value="photo"
                                      name="photo"
                                  >
                                    Photo
                                  </option>
                                  <option
                                      className="video"
                                      name="video"
                                      value="video"
                                  >
                                    Video
                                  </option>
                                  <option
                                      className="recipe"
                                      name="recipe"
                                      value="recipe"
                                  >
                                    Recipe
                                  </option>
                                  <option
                                      className="status"
                                      name="status"
                                      value="status"
                                  >
                                    Status
                                  </option>
                                </Form.Control>
                              </Form.Group>
                              {/* handle field required alert */}
                              {
                                submitTriggered &&
                                !postType ?
                                    this.requiredFieldFlag()
                                    : null
                              }

                              {/* -------add/remove "d-none" to enable and disable the recipe name  field */}

                              <Form.Group
                                  controlId=""
                                  className={postType === "recipe" ? "" : "d-none"}
                              >
                                <Form.Control
                                    type="text"
                                    className="p-3 h-100"
                                    name="recipeName"
                                    value={recipeName}
                                    onChange={this.onChange}
                                    placeholder="Recipe Name"
                                    required={postType === "recipe"}
                                />
                                {/* handle field required alert */}
                                {
                                  submitTriggered &&
                                  postType === "recipe" &&
                                  !recipeName ?
                                      this.requiredFieldFlag()
                                      : null
                                }
                              </Form.Group>


                              {/* photo field */}
                              <Form.Group
                                  className={
                                    postType === "photo" ||
                                    postType === "recipe" ?
                                        ""
                                        : "d-none"
                                  }
                              >
                                <label
                                    className="btn btn-block bg-grey add-file pl-3 pb-5 text-left rounded"
                                    htmlFor="titleImg"
                                >
                                  <input
                                      type="file"
                                      className="d-none"
                                      id="titleImg"
                                      accept="image/*"
                                      onChange={this.handleMediaImgChange}
                                      required={postType === "photo"}
                                  />
                                  {
                                    mediaImgName ?
                                        mediaImgName
                                        : postDataBeforeEdit ?
                                        "Update photo"
                                        :'Add Photo'
                                  }
                                  {/* show photo before upload */}
                                  {
                                    image ?
                                        <img
                                            src={image}
                                            id="photo-id"
                                            className="add-photo"
                                            style={{
                                              height: "100px",
                                            }}
                                            alt={mediaImgName}
                                        />
                                        : <img
                                            src={"/assets/images/add-photo.svg"}
                                            alt=""
                                            className="add-photo"
                                        />
                                  }
                                </label>
                                {/* handle field required alert */}
                                {
                                  submitTriggered &&
                                  (
                                      postType === "photo" ||
                                      postType === "recipe"
                                  ) &&
                                  !mediaImg &&
                                  !mediaImgName ?
                                      this.requiredFieldFlag()
                                      : null
                                }
                              </Form.Group>

                              {/* video field */}
                              <Form.Group
                                  className={
                                    postType === "video" ?
                                        ""
                                        : "d-none"
                                  }
                              >
                                <label
                                    htmlFor="titleVideo"
                                    className="btn btn-block bg-grey add-file pl-3 pb-5 text-left rounded"
                                >
                                  <input
                                      type="file"
                                      className="d-none"
                                      id="titleVideo"
                                      accept="video/*"
                                      onChange={this.handleMediaVideoChange}
                                      required={postType === "video"}
                                  />
                                  {
                                    mediaVideoName ?
                                        mediaVideoName
                                        : postDataBeforeEdit ?
                                        "Update Video"
                                        : 'Add Video'
                                  }
                                  <img
                                      src={"/assets/images/add-video.svg"}
                                      alt=""
                                      className="add-photo"
                                  />
                                </label>
                                {/* handle field required alert */}
                                {
                                  submitTriggered &&
                                  postType === "video" &&
                                  !mediaVideo &&
                                  !mediaVideoName ?
                                      this.requiredFieldFlag()
                                      : null
                                }
                              </Form.Group>

                              {/* description field */}
                              <Form.Group
                                  className={
                                    postType === "photo" ||
                                    postType === "video" ?
                                        ""
                                        : "d-none"
                                  }
                              >
                                <Form.Control
                                    as="textarea"
                                    className="pl-3 bg-white text-area "
                                    placeholder="Description"
                                    name="desc"
                                    value={desc}
                                    onChange={this.onChange}
                                    required={
                                      postType === "photo" ||
                                      postType === "video"
                                    }
                                />
                                {/* handle field required alert */}
                                {
                                  submitTriggered &&
                                  (
                                      postType === "photo" ||
                                      postType === "video"
                                  ) &&
                                  !desc ?
                                      this.requiredFieldFlag()
                                      : null
                                }
                              </Form.Group>

                              {/* -------add/remove "d-none" to enable and disable the status field */}
                              <Form.Group
                                  controlId=""
                                  className={
                                    postType === "status" ? "" : "d-none"
                                  }
                              >
                                <Form.Control
                                    as="textarea"
                                    className="pl-3 bg-white text-area "
                                    placeholder="status"
                                    name="statusText"
                                    value={statusText}
                                    onChange={this.onChange}
                                    required={postType === "status"}
                                />
                                {/* handle field required alert */}
                                {
                                  submitTriggered &&
                                  postType === "status" &&
                                  !statusText ?
                                      this.requiredFieldFlag()
                                      : null
                                }
                              </Form.Group>

                              {/* -------add/remove "d-none" to enable and disable the text editor field */}
                              <div
                                  className={
                                    postType === "recipe"
                                        ? "text-editor-wrapper bg-grey-3 px-2"
                                        : "text-editor-wrapper bg-grey-3 px-2 d-none"
                                  }
                              >
                                {/* text editor component */}
                                <TextEditor
                                    clearInstruction={clearInstruction}
                                    htmlData={editorText ? editorText : null}
                                    getHtmlData={(data) => this.handleUpdateEditorText(data)}
                                    toobarSetting={toolbarConfig}
                                    required={postType === "recipe"}
                                />
                                {/* handle field required alert */}
                                {
                                  submitTriggered &&
                                  editorText === "" ||
                                  editorText === "<p></p>" ?
                                      this.requiredFieldFlag()
                                      : null
                                }
                              </div>

                              {/* Submit button */}
                              <div className="action-done text-center mt-4 mt-md-5">
                                <button
                                    type='button'
                                    className="btn btn-primary py-2 px-4"
                                    onClick={
                                      postDataBeforeEdit ?
                                          () => this.handlePostSubmit("update")
                                          : () => this.handlePostSubmit("add")
                                    }
                                    disabled={postDataBeforeEdit && !areChangesPresent}
                                >
                                  {
                                    submittingDataLoader ?
                                        <ContentLoader />
                                        : postDataBeforeEdit ?
                                        'Save Changes'
                                        : 'Done'
                                  }
                                </button>
                              </div>
                            </Form>
                            {
                              submitUnsuccessful && errorMessage?
                                  <p className="fs-14 text-center text-danger-2 font-source-sans text-left font-weight-semi">
                                    errorMessage
                                  </p>
                                  : null
                            }
                          </div>
                    }
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-none d-md-block display-img">
                    <img
                      src={"/assets/images/upload-content-side-wrapper.svg"}
                      alt="slider-img-1"
                      className="img-fit w-100 side-banner-image"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addPost: (data) => dispatch(add_post_action(data)),
  getPostData: (id) => dispatch(get_status_by_id_action(id)),
  updatePost: (data, postId) => dispatch(update_post_by_id_action(data, postId))
})

export default connect(null, mapDispatchToProps)(AddPost);
