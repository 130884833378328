import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import CommentsCard from "../common/CommentsRecipeTabs/Comments/CommentsCard/CommentsCard";
import ContentBox from "../common/ContentBox/ContentBox";
import {
  delete_post_by_id_action,
  get_comments_by_id_action,
  get_status_by_id_action,
  hit_like_action,
  report_item_action,
} from "../../../_actions/User/post.action";
import "./StatusPost.scss";
import ContentLoader from "../../Common/Loader/ContentLoader";
import CommentsSearch from "../common/CommentsRecipeTabs/Comments/CommentsSearch/CommentsSearch";
import swal from "sweetalert";

class StatusPost extends Component {
  state = {
    postLoading: false,
    status: [],
    commentsLoading: false,
    comments: [],
    gotCommentError: false,
    reportReason: null,
  };

  componentDidMount() {
    this.mountStatusData();
    this.mountComments();
  }

  mountStatusData = async () => {
    this.setState({ postLoading: true });

    const statusRes = await this.props.getStatus(
      this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
    );

    if (statusRes && statusRes.data) {
      await this.setState({
        status: statusRes.data,
      });
    } else this.props.history.push("/user-posts");

    this.setState({ postLoading: false });
  };

  mountComments = async () => {
    this.setState({ commentsLoading: true });

    const statusId =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;

    const data = {
      comment_type: "user_post",
    };
    const getCommentsRes = await this.props.getCommentsAction(data, statusId);
    if (getCommentsRes && getCommentsRes.data) {
      this.setState({
        comments: getCommentsRes.data,
        gotCommentError: false,
      });
    } else this.setState({ gotCommentError: true });

    this.setState({ commentsLoading: false });
  };

  deleteStatus = async (id) => {
    if (!id) return;
    await this.props.deleteStatusById(id);
    this.props.history.push("/user-posts/Status");
  };

  likeStatus = async (id) => {
    if (!id) return;
    const data = {
      content_type: "user_post",
      id,
    };
    await this.props.hitLike(data);
  };

  refreshComments = async () => {
    await this.mountComments();
  };
  handleBack = () => {
    let state = this.props && this.props.location && this.props.location.state;
    if (state && state.detail) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/user-posts/Status");
    }
  };
  goBack = () => {
    if (this.props.location.state.backPageUrl === "chatboard") {
      this.props.history.push("/chatboard/Status");
    } else if (this.props.location.state.backPageUrl === "userNotifications") {
      this.props.history.push(`/notifications`);
    } else {
      this.props.history.push("/user-posts/Status");
    }
  };

  reportPost = async (id, type) => {
    let data = {
      post: id,
      report_type: this.state.reportReason,
    };
    const res = await this.props.reportItem(data);
    swal(res && res.msg);
  };
  reportReason = (reason) => {
    this.setState({ reportReason: reason });
  };
  render() {
    const { postLoading, status, comments, commentsLoading, gotCommentError } =
      this.state;
    const currentUserId = JSON.parse(localStorage.getItem("user")).user.id;

    return (
      <div className="common-dashboard status-post-wrapper post-wrapper">
        <Container className="p-0">
          <SidebarMenu
            alertNewNotification={(payload) =>
              this.openNewNotification(payload)
            }
            history={this.props.history}
          />

          <div className="main-content bg-white">
            <Header />

            {/*==================== cookbook details page============= */}
            {postLoading ? (
              <div
                style={{
                  margin: "50px auto 0",
                  textAlign: "center",
                }}
              >
                <ContentLoader color="text-warning" />
              </div>
            ) : status && Object.keys(status).length > 0 ? (
              <div className="post">
                {/* bk button */}
                <a className="align-items-center d-none d-md-flex">
                  <img
                    src={"/assets/images/back-arrow.svg"}
                    className="ml-md-n2"
                    onClick={() =>
                      this.props.history.push("/user-posts/Status")
                    }
                    alt="back"
                  />
                  <span
                    className="text-dark fs-16 font-weight-bold ml-1"
                    // onClick={() =>
                    //   this.props.history.push("/user-posts/Status")
                    // }
                    onClick={() => this.goBack()}
                  >
                    Back
                  </span>
                </a>
                <Row className="mt-4">
                  <Col md={{ span: 10, offset: 1 }}>
                    <div className="chatboard-status bg-grey border-radius-10 p-4">
                      <ContentBox
                        post={status}
                        likesArray={status["likes"]}
                        likesCount={status["likes_count"]}
                        bookmarkArray={status["bookmarks"]}
                        userimg={
                          status &&
                          status["user_details"] &&
                          status["user_details"]["profile_image"]
                        }
                        username={
                          status &&
                          status["user_details"] &&
                          status["user_details"]["first_name"]
                        }
                        statusText={status && status["status_text"]}
                        commentsCount={
                          `${status && status["comments_count"]}` + " comments"
                        }
                        delete={
                          status["user_details"].id === currentUserId
                            ? "Delete"
                            : null
                        }
                        deletePost={() => this.deleteStatus(status.id)}
                        edit={
                          status["user_details"].id === currentUserId
                            ? "Edit"
                            : null
                        }
                        editPost={() =>
                          this.props.history.push(`/add-post/${status.id}`)
                        }
                        report={
                          status["user_details"].id !== currentUserId
                            ? "Report"
                            : null
                        }
                        reportPost={() =>  this.reportPost(status.id)}
                        reportReason={this.reportReason}
                      />
                    </div>
                  </Col>
                  {/* comments section */}
                  <Col md={{ span: 8, offset: 2 }}>
                    <CommentsSearch
                      post={status}
                      refreshComments={() => this.refreshComments()}
                    />

                    {commentsLoading ? (
                      <div
                        className="text-center"
                        style={{ maxWidth: "515px" }}
                      >
                        <ContentLoader color="text-warning" />
                      </div>
                    ) : gotCommentError ? (
                      <p className="text-warning">
                        Cannot fetch comments! refresh the page.
                      </p>
                    ) : (
                      <>
                        <CommentsCard
                          refreshComments={() => this.refreshComments()}
                          comments={comments}
                          delete="Delete"
                          report="Report"
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            ) : (
              <p className="text-danger">Status No Found!</p>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getStatus: (id) => dispatch(get_status_by_id_action(id)),
  deleteStatusById: (id) => dispatch(delete_post_by_id_action(id)),
  hitLike: (data) => dispatch(hit_like_action(data)),
  getCommentsAction: (data, id) =>
    dispatch(get_comments_by_id_action(data, id)),
  reportItem: (data) => dispatch(report_item_action(data)),
});
export default connect(null, mapDispatchToProps)(StatusPost);
