import swal from "sweetalert";
import config from "../../Config/config";
import { contentConstants } from "../../_constants";
import axiosInstance from "../../_helpers/axiosInstance";

// Get saved recipe content
export const get_saved_recipe_changes_action = (id) => async (dispatch) => {
    dispatch({
        type: contentConstants.GET_SAVED_CHANGES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.content.getSavedRecipeVideo.replace(':id', id))
        if (response.status) {
            dispatch({
                type: contentConstants.GET_SAVED_CHANGES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.GET_SAVED_CHANGES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// Get saved video
export const get_saved_video_changes_action = (id) => async (dispatch) => {
    dispatch({
        type: contentConstants.GET_SAVED_VIDEO_CHANGES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.content.getVideoDetails.replace(':id', id))
        if (response.status) {
            dispatch({
                type: contentConstants.GET_SAVED_VIDEO_CHANGES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.GET_SAVED_VIDEO_CHANGES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// Add recipe content
export const save_recipe_video_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.SAVE_RECIPE_CHANGES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.content.saveRecipeVideo, data)
        if (response.status) {
            dispatch({
                type: contentConstants.SAVE_RECIPE_CHANGES_ACTION_SUCCESS,
                payload: response.data
            })

            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.SAVE_RECIPE_CHANGES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// Add recipe content
export const publish_recipe_video_action = (id, data) => async (dispatch) => {
    dispatch({
        type: contentConstants.PUBLISH_RECIPE_CHANGES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.put(config.content.getSavedRecipeVideo.replace(':id', id), data)
        if (response.status) {
            dispatch({
                type: contentConstants.PUBLISH_RECIPE_CHANGES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.PUBLISH_RECIPE_CHANGES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// Delete saved recipe content
export const delete_saved_recipe_changes_action = (id) => async (dispatch) => {
    dispatch({
        type: contentConstants.DELETE_RECIPE_CHANGES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(config.content.getSavedRecipeVideo.replace(':id', id))
        if (response.status) {
            dispatch({
                type: contentConstants.DELETE_RECIPE_CHANGES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg || 'Something went wrong!')
    }
}


// Add connected recipe content
export const add_connected_recipe_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.SAVE_CONNECTED_RECIPE_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.content.addConnectedRecipe, data)
        if (response.status) {
            dispatch({
                type: contentConstants.SAVE_CONNECTED_RECIPE_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.SAVE_CONNECTED_RECIPE_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// Delete connected recipe content
export const delete_connected_recipe_action = (id, data) => async (dispatch) => {
    dispatch({
        type: contentConstants.DELETE_CONNECTED_RECIPE_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.put(config.content.getSavedRecipeVideo.replace(':id', id), data)
        if (response.status) {
            dispatch({
                type: contentConstants.DELETE_CONNECTED_RECIPE_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg || 'Something went wrong!')
    }
}

// Edit connected recipe content
export const edit_connected_recipe_action = (id, data) => async (dispatch) => {
    dispatch({
        type: contentConstants.SAVE_CONNECTED_RECIPE_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.put(config.content.editConnectedRecipe.replace(':id', id), data)
        if (response.status) {
            dispatch({
                type: contentConstants.SAVE_CONNECTED_RECIPE_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.SAVE_CONNECTED_RECIPE_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// Get connected recipe content
export const get_connected_recipe_action = (id) => async (dispatch) => {
    dispatch({
        type: contentConstants.GET_CONNECTED_RECIPE_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.content.getConnectedRecipeById.replace(':id', id))
        if (response.status) {
            dispatch({
                type: contentConstants.GET_CONNECTED_RECIPE_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.GET_CONNECTED_RECIPE_BY_ID_ACTION_SUCCESS,
            payload: e.msg
        })
    }
}

// Get Recipe Lisitng 
export const get_recipe_listing_action = () => async (dispatch) => {
    dispatch({
        type: contentConstants.GET_RECIPE_LISTING_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.content.getRecipeListing)
        if (response.status) {
            dispatch({
                type: contentConstants.GET_RECIPE_LISTING_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg || 'Something went wrong!')
        dispatch({
            type: contentConstants.GET_RECIPE_LISTING_FAILURE,
            payload: e.msg
        })
    }
}

// Edit Recipe Listing
export const edit_recipe_listing_action = () => async (dispatch) => {
    dispatch({
        type: contentConstants.EDIT_RECIPE_LISTING_REQUEST
    })
    try {
        const response = await axiosInstance.put(config.content.editRecipeListing)
        if (response.status) {
            dispatch({
                type: contentConstants.EDIT_RECIPE_LISTING_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.EDIT_RECIPE_LISTING_FAILURE,
            payload: e.msg
        })
    }
}

// Delete Recipe Listing
export const delete_recipe_listing_action = (id, type) => async (dispatch) => {
    dispatch({
        type: contentConstants.DELETE_RECIPE_LISTING_REQUEST
    })
    try {
        const response = await axiosInstance.delete(config.content.deleteRecipeListing.replace(':type', type).replace(':id', id))
        if (response.status) {
            dispatch({
                type: contentConstants.DELETE_RECIPE_LISTING_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.DELETE_RECIPE_LISTING_FAILURE,
            payload: e.msg
        })
    }
}

// CREATE FAQ WITH SUBTOPICS
export const upload_faq_question_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.CREATE_FAQ_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.content.createFaq, data)
        if (response && response.status) {
            dispatch({
                type: contentConstants.CREATE_FAQ_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.CREATE_FAQ_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// UPDATE FAQ TOPIC AND SUB TOPIC
export const update_faq_question_action = (data, topicId) => async (dispatch) => {
    dispatch({
        type: contentConstants.UPDATE_FAQ_TOPIC_ACTION_REQUEST
    })
    try {
        //  update request for topic
        if (!data.isTopicSame) {
            const topicUpdateResponse = await axiosInstance.patch(`${config.content.updateTopic}${topicId}/`, data.topicUpdateObj)
            if (topicUpdateResponse &&
                topicUpdateResponse.data &&
                topicUpdateResponse.data.msg &&
                !topicUpdateResponse.data.msg.toLowerCase().includes('updated successfully')
            )
                return {
                    status: false,
                    msg: 'Topic name same. No data Updated'
                }
            else return {
                status: true,
                msg: 'Topic Update Successful!'
            }
        }

        if (data.isSubTopicSame && data.isAnswerSame)
            return {
                status: false,
                msg: 'No Changes. Fields Not Updated.'
            }
        // update request for sub topics
        let subTopicUpdateArray = data.subTopicUpdateArrayApis.map(async api => {
            return axiosInstance.patch(api[0], api[1])
                .then(res => { return res.data })
                .catch(e => { return e.data })
        })

        return Promise.all(subTopicUpdateArray).then((res) => {
            res.forEach(msg => {
                if (msg && msg.toString() !== 'Updated Successfully') {
                    return {
                        status: false,
                        msg: 'Update Unsuccessful'
                    }
                }
            })
            dispatch({
                type: contentConstants.UPDATE_FAQ_TOPIC_ACTION_SUCCESS,
                payload: res
            })
            return {
                status: true,
                msg: 'FAQ Upload Successful!'
            }
        })
    } catch (e) {
        dispatch({
            type: contentConstants.UPDATE_FAQ_TOPIC_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// FETCH FAQ TABLE LISTINGS
export const load_faq_table_list_action = () => async (dispatch) => {
    dispatch({
        type: contentConstants.GET_FAQ_TABLE_DATA_LIST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.content.fetchFaqTableData)
        if (response.status) {
            dispatch({
                type: contentConstants.GET_FAQ_TABLE_DATA_LIST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.GET_FAQ_TABLE_DATA_LIST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// DELETE FAQ TOPIC
export const delete_faq_topic_action = (id) => async (dispatch) => {
    dispatch({
        type: contentConstants.DELETE_FAQ_TOPIC_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(`${config.content.deleteFaqTopic}${id}/`)
        if (response.status) {
            dispatch({
                type: contentConstants.DELETE_FAQ_TOPIC_ACTION_SUCCESS,
                payload: response.data
            })
            return true
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.DELETE_FAQ_TOPIC_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// FETCH FAQ TOPIC WITH ALL SUBTOPICS
export const fetch_faq_topic_details_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.GET_FAQ_TOPIC_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(`${config.content.getTopicDetails}${data}/`)
        if (response.status) {
            dispatch({
                type: contentConstants.GET_FAQ_TOPIC_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.GET_FAQ_TOPIC_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// NEW SUBTOPIC ADDITION FOR FAQ TOPIC
export const new_faq_subtopic_addition_for_topic_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.content.newTopicAddition, data.data)
        if (response.status) {
            dispatch({
                type: contentConstants.NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// DELETE SUBTOPIC ACTION
export const delete_subTopic_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.DELETE_FAQ_SUBTOPIC_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(`${config.content.deleteSubtopic}${data}/`, data.data)
        if (response.status === 204) {
            dispatch({
                type: contentConstants.DELETE_FAQ_SUBTOPIC_ACTION_SUCCESS,
                payload: response.data
            })
            return true
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.DELETE_FAQ_SUBTOPIC_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// UPDATE RECIPE DETAILS
export const update_recipe_details_action = (id, data) => async (dispatch) => {
    dispatch({
        type: contentConstants.UPDATE_RECIPE_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.put(`${config.content.updateRecipeContent}${id}/`, data)
        if (response.status) {
            dispatch({
                type: contentConstants.UPDATE_RECIPE_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return true
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.UPDATE_RECIPE_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// GET ANNOUNCEMENT DATA
export const get_announcement_table_list_action = (userType) => async (dispatch) => {
    dispatch({
        type: contentConstants.GET_ANNOUNCEMENT_TABLE_DATA_ACTION_REQUEST
    })
    try {
        let response;
        if (userType === "admin")
            response = await axiosInstance.get(config.content.announcementApi)
        else if (userType === 'user')
            response = await axiosInstance.get(config.content.userSideAnnouncements)

        if (response.status) {
            dispatch({
                type: contentConstants.GET_ANNOUNCEMENT_TABLE_DATA_ACTION_SUCCESS,
                payload: response.data
            })
            return {
                type: userType,
                response: response.data
            }
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: contentConstants.GET_ANNOUNCEMENT_TABLE_DATA_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const delete_announcement_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.DELETE_ANNOUNCEMENT_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(`${config.content.announcementApi}${data}/`)
        if (response && response.status) {
            dispatch({
                type: contentConstants.DELETE_ANNOUNCEMENT_ACTION_SUCCESS,
                payload: response && response.data
            })
            return {
                status: true,
                msg: response && response.data && response.data.msg
            }
        }
        else {
            return {
                status: false,
                msg: response['msg'] ? response['msg'] : 'Delete Announcement Unsuccessful!'
            }
        }
    } catch (e) {
        dispatch({
            type: contentConstants.DELETE_ANNOUNCEMENT_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const add_new_announcement_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.ADD_NEW_ANNOUNCEMENT_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.content.announcementApi, data)
        if (response) {
            if (response &&
                response.data &&
                response.data.msg &&
                response.data.msg.toLowerCase().includes("successfully created")
            ) {
                dispatch({
                    type: contentConstants.ADD_NEW_ANNOUNCEMENT_ACTION_SUCCESS,
                    payload: response && response.data
                })
                return {
                    status: true,
                    msg: response && response.data && response.data.msg
                }
            } else {
                return {
                    status: false,
                    msg: 'Announcement creation Unsuccessful!'
                }
            }
        }
    } catch (e) {
        dispatch({
            type: contentConstants.ADD_NEW_ANNOUNCEMENT_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_single_announcement_details_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(`${config.content.announcementApi}${data}/`)
        if (response) {
            if (response.data) {
                dispatch({
                    type: contentConstants.GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_SUCCESS,
                    payload: response.data
                })
                return response.data && response.data.data
            } else {
                return false
            }
        }
    } catch (e) {
        dispatch({
            type: contentConstants.GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const update_announcement_details_action = (data) => async (dispatch) => {
    dispatch({
        type: contentConstants.UPDATE_ANNOUNCEMENT_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.patch(`${config.content.announcementApi}${data.announcementId}/`, data.postData)
        if (response) {
            if (response.data) {
                dispatch({
                    type: contentConstants.UPDATE_ANNOUNCEMENT_DETAILS_ACTION_SUCCESS,
                    payload: response.data
                })
                return response.data
            } else {
                return false
            }
        }
    } catch (e) {
        dispatch({
            type: contentConstants.UPDATE_ANNOUNCEMENT_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}