import React, { Component } from "react";

import {
  Button,
  FormGroup,
  FormControl,
  Dropdown,
  Table,
  Accordion,
  Card
} from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";

import "./CarouselListing.scss";
import TopTab from "../../../common/TopTab/TopTab";
import { LoaderBasic } from "../../../common/Loader";
import { NoDataFoundBasic } from "../../../../Common/EmptyData";
import AdminSidebarMenu from "../../../common/SidebarMenu/AdminSidebarMenu";
import {
  get_all_content_action,
  add_to_dashboard_item_action,
  edit_to_dashboard_item_action
} from "../../../../../_actions/Admin/carousel.action";
import { isEmptyObject } from "../../../../../_helpers/validators";
import ContentLoader from "../../../../Common/Loader/ContentLoader";

class CarouselListing extends Component {
  state = {
    filter: "",
    search: "",
    errorText: "",
    labelText: "",
    allContent: [],
    coverImage: {},
    inputError: {},
    labelColor: "",
    coverImageName: "",
    isApiLoading: false,
    coverImagePreview: "",
    filteredAllContent: [],
    isAddApiLoading: false,
    dashboardItemType: "new",
    dashboardItemPriority: 1,
    isSelectedItem: { isSelected: false, selectedItem: {} },
    activeFilterType: { show: false, video: false, recipe: false }
  };

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.type
    ) {
      this.setState({ filter: this.props.match.params.type }, () => {
        this.getAllContentItemsApi();
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.priority &&
          this.props.location.state.dashboardItemType
        ) {
          this.setState({
            dashboardItemPriority: this.props.location.state.priority,
            dashboardItemType: this.props.location.state.dashboardItemType
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.allContentItems !== this.props.allContentItems) {
      if (
        this.props.allContentItems.results &&
        this.props.allContentItems.results.length
      ) {
        const { filter } = this.state;
        this.setState({
          allContent: this.props.allContentItems.results,
          filteredAllContent:
            filter !== "update"
              ? this.props.allContentItems.results.filter(
                  (allContentItem) =>
                    allContentItem.content.content_type === filter
                )
              : this.props.allContentItems.results
        });
      }
    }

    if (prevProps.dashboardItemAdded !== this.props.dashboardItemAdded) {
      if (
        this.props.dashboardItemAdded.itemAdded &&
        this.props.dashboardItemAdded.itemAdded.length
      ) {
        this.onCancelClickHandler();
        return this.props.history.goBack();
      }
    }

    if (prevProps.error !== this.props.error) {
      if (this.props.error.length) {
        this.setState({ errorText: this.props.error });
        return;
      }
    }

    if (prevState.search !== this.state.search) {
      this.setState({
        filteredAllContent: this.state.search.length
          ? this.state.allContent.filter((allContentItem) =>
              allContentItem.content.title
                .toLowerCase()
                .includes(this.state.search)
            )
          : this.state.allContent
      });
    }
  }

  getAllContentItemsApi = async () => {
    this.setState({ isApiLoading: true });
    await this.props.getAllContentItems();
    this.setState({ isApiLoading: false });
  };

  handleFilterChange = (type) => {
    this.setState(
      (prevState) => ({
        activeFilterType: {
          ...prevState.activeFilterType,
          [type]: !prevState.activeFilterType[type]
        }
      }),
      () => {
        this.filterContentData();
      }
    );
  };

  handleSelectItemHandler = (e, selectedItem) => {
  
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      (prevState) => ({
        isSelectedItem: {
          ...prevState.isSelectedItem,
          isSelected:
            selectedItem.id === prevState.isSelectedItem.selectedItem.id &&
            prevState.isSelectedItem.isSelected
              ? false
              : true
        }
      }),
      () => {
        if (this.state.isSelectedItem.isSelected) {
          this.setState((prevState) => ({
            isSelectedItem: { ...prevState.isSelectedItem, selectedItem }
          }));
          return;
        }
      }
    );
  };

  filterContentData = () => {
    const { allContent, activeFilterType } = this.state;
    this.setState({
      filteredAllContent:
        (activeFilterType.show &&
          activeFilterType.video &&
          activeFilterType.recipe) ||
        (!activeFilterType.show &&
          !activeFilterType.video &&
          !activeFilterType.recipe)
          ? [...allContent]
          : allContent.filter((allContentItem) => {
              if (
                activeFilterType.show &&
                allContentItem.content.content_type === "show"
              ) {
                return allContentItem;
              } else if (
                activeFilterType.video &&
                allContentItem.content.content_type === "video"
              ) {
                return allContentItem;
              } else if (
                activeFilterType.recipe &&
                allContentItem.content.content_type === "youtube-recipe"
              ) {
                return allContentItem;
              } else return false;
            })
    });
  };

  //Handle change for image input
  handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    this.setState((prevState) => ({
      coverImage: file ? file : prevState.coverImage,
      coverImageName: file && file.name ? file.name : prevState.coverImageName,
      coverImagePreview: file
        ? URL.createObjectURL(file)
        : prevState.coverImagePreview
    }));
  };

  //Handle label color and text
  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onAddClickHandler = async (type, typeData) => {
    const {
      filter,
      labelText,
      labelColor,
      coverImage,
      coverImageName,
      isSelectedItem,
      dashboardItemType,
      dashboardItemPriority
    } = this.state;

    if (!coverImageName.length) {
      this.setState((prevState) => ({
        inputError: {
          ...prevState.inputError,
          coverImage: "Cover image is required!"
        }
      }));
      return;
    }

    if (labelColor.length && !labelText.length) {
      this.setState((prevState) => ({
        inputError: {
          ...prevState.inputError,
          labelText: `Label text is required if you've chosen label color!`
        }
      }));
      return;
    }

    if (labelText.length && !labelColor.length) {
      this.setState((prevState) => ({
        inputError: {
          ...prevState.inputError,
          labelColor: `Label color is required if you want to add label!`
        }
      }));
      return;
    }

    let data = new FormData();

    data.append(
      "row_type",
      filter === "youtube-recipe" ? "youtube_recipe" : filter
    );
    data.append("cover_image", coverImage);
    data.append("priority", dashboardItemPriority);
    /*     data.append(
      filter === "youtube-recipe"
        ? "youtube_recipe"
        : filter === "update" && isSelectedItem.selectedItem.content_type === "episode"
        ? "episode"
        : filter,
      isSelectedItem.selectedItem.id
    ); */
    data.append(
      typeData === "youtube-recipe" ? "youtube_recipe" : typeData,
      isSelectedItem.selectedItem.id
    );

    if (labelColor.length && labelText.length) {
      data.append("title", labelText);
      data.append("title_label_color", labelColor);
    }

    this.setState({ isAddApiLoading: true });
    if (dashboardItemType === "new") {
      await this.props.addDashboardItem(data);
    } else {
      await this.props.editDashboardItem(dashboardItemType, data);
    }
    this.setState({ isAddApiLoading: false });
  };

  onCancelClickHandler = () => {
    this.setState({
      labelText: "",
      coverImage: {},
      inputError: {},
      labelColor: "",
      coverImageName: "",
      coverImagePreview: "",
      isSelectedItem: { isSelected: false, selectedItem: {} }
    });
  };

  render() {
    const {
      filter,
      errorText,
      inputError,
      isApiLoading,
      coverImageName,
      isSelectedItem,
      isAddApiLoading,
      activeFilterType,
      coverImagePreview,
      filteredAllContent
    } = this.state;

    return (
      <div className="content-listing-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu />
          <TopTab activeTab="Carousel" tabCategoryName="contentManagment" />
          <div className="main-content  bg-white">
            <h2 className="fs-34 font-weight-bold mb-4 pt-4">Carousel</h2>

            {/* remove/add "invisible" class to show / hide top section  */}
            {isSelectedItem.isSelected ? (
              <div className="carousel-listing-wrapper d-flex">
                <div className="left-content-wrapper">
                  <h5 className="font-weight-semi mb-2">
                    Selected{" "}
                    {isSelectedItem.selectedItem.content_type ===
                    "youtube-recipe"
                      ? "Recipe"
                      : isSelectedItem.selectedItem.content_type === "video"
                      ? "Video"
                      : isSelectedItem.selectedItem.content_type === "show"
                      ? "Show"
                      : ""}
                  </h5>
                  <p className="font-smallest font-weight-400 ">
                    {isSelectedItem.selectedItem.title &&
                      isSelectedItem.selectedItem.title}
                  </p>
                  <div className="add-btn-wrapper d-flex">
                    <label className="btn btn-outline-dark rounded px-5 mr-3 d-flex align-items-center jusfity-content-center">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={this.handleCoverImageChange}
                        className={`d-none ${
                          inputError.coverImage && inputError.coverImage.length
                            ? "input-invalid"
                            : ""
                        }`}
                      />
                      {coverImageName.length
                        ? coverImageName
                        : "Add Cover Image"}
                    </label>

                    <FormGroup className="rounded mb-0">
                      <FormControl
                        placeholder="Add label (if required)"
                        className={`${
                          inputError.labelText && inputError.labelText.length
                            ? "input-invalid px-4 mb-0 bg-grey-3 text-center h-100"
                            : "px-4 mb-0 bg-grey-3 text-center h-100"
                        }`}
                        onChange={(e) =>
                          this.handleChange("labelText", e.target.value)
                        }
                      />
                    </FormGroup>

                    <div className="right">
                      <ul className="data-exports d-flex flex-wrap ml-3 ">
                        <li
                          onClick={() =>
                            this.handleChange("labelColor", "violet")
                          }
                        >
                          <label
                            className="custom-square-radio"
                            htmlFor="voilet"
                          >
                            <input
                              type="radio"
                              name="label-color"
                              id="voilet"
                              name="labelSelector"
                            />
                            <span className="color-box bg-primary"></span>
                          </label>
                        </li>
                        <li
                          className="ml-2"
                          onClick={() =>
                            this.handleChange("labelColor", "yellow")
                          }
                        >
                          <label
                            className="custom-square-radio"
                            htmlFor="yellow"
                          >
                            <input
                              type="radio"
                              name="label-color"
                              id="yellow"
                              name="labelSelector"
                            />
                            <span className="color-box bg-warning"></span>
                          </label>
                        </li>
                        <li
                          className="ml-2"
                          onClick={() =>
                            this.handleChange("labelColor", "black")
                          }
                        >
                          <label
                            className="custom-square-radio"
                            htmlFor="black"
                          >
                            <input
                              type="radio"
                              name="label-color"
                              id="black"
                              name="labelSelector"
                            />
                            <span className="color-box bg-dark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right-btns-wrapper ml-5">
                  <Button
                    variant="info"
                    className="rounded px-5 mr-3 cursor-pointer"
                    onClick={() =>
                      this.onAddClickHandler(
                        "type",
                        isSelectedItem.selectedItem.content_type
                      )
                    }
                    disabled={
                      coverImageName.length && !isAddApiLoading ? false : true
                    }
                  >
                    {isAddApiLoading ? (
                      <ContentLoader color="text-white" />
                    ) : (
                      "Add"
                    )}
                  </Button>
                  <Button
                    variant="outline-dark"
                    className="rounded px-4"
                    onClick={this.onCancelClickHandler}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : null}

            {coverImageName.length ? (
              <img src={coverImagePreview} alt="Cover Image" className="mt-3" />
            ) : null}
            {errorText && errorText.length ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                {errorText}
              </p>
            ) : null}
            {inputError.labelText && inputError.labelText.length ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                {inputError.labelText}
              </p>
            ) : null}
            <div className="user-data carousel-selection-list bg-grey-6 pt-md-5 mt-5">
              <div className="user-data-wrapper bg-white">
                <p className="fs-24 font-weight-semi mb-5">
                  Select{" "}
                  {filter === "update"
                    ? "Show/Video/Recipe"
                    : filter === "youtube-recipe"
                    ? "Recipe"
                    : filter === "show"
                    ? "Show"
                    : filter === "video"
                    ? "Video"
                    : ""}{" "}
                  to post new update
                </p>

                {/* search and filters ========== */}
                <div className="search-and-filter d-flex mb-3">
                  <FormGroup className="mb-0 search-bar flex-grow-1">
                    <FormControl
                      placeholder="Search"
                      className="search"
                      onChange={(e) =>
                        this.handleChange("search", e.target.value)
                      }
                    />
                  </FormGroup>
                  {filter === "update" && (
                    <div className="search-filter ml-2">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="filter-toggler d-flex align-items-center justify-content-between bg-grey-3 w-100 rounded"
                        >
                          filter{" "}
                          <img
                            src="/assets/images/dropdown.svg"
                            alt="dropdown"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="font-smallest font-weight-bold bg-grey border-0 p-4 h-auto rounded w-100 shadow-sm">
                          <div className="d-flex">
                            <p>Filter by type :</p>
                            <ul className="ml-4">
                              <li className="custom-control custom-checkbox dark pb-1">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="Show"
                                  name="row-checkbox"
                                  checked={activeFilterType.show}
                                  onChange={() =>
                                    this.handleFilterChange("show")
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="Show"
                                >
                                  Show
                                </label>
                              </li>
                              <li className="custom-control custom-checkbox dark pb-1">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="Video"
                                  name="row-checkbox"
                                  checked={activeFilterType.video}
                                  onChange={() =>
                                    this.handleFilterChange("video")
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="Video"
                                >
                                  Video
                                </label>
                              </li>
                              <li className="custom-control custom-checkbox dark">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="Recipe"
                                  name="row-checkbox"
                                  checked={activeFilterType.recipe}
                                  onChange={() =>
                                    this.handleFilterChange("recipe")
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="Recipe"
                                >
                                  Recipe
                                </label>
                              </li>
                            </ul>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
                {/* search and filters ========== */}
              </div>
            </div>

            {/* Listing Table  */}
            {isApiLoading ? (
              <LoaderBasic />
            ) : filteredAllContent.length ? (
              <Table
                striped
                bordered
                hover
                responsive
                className="text-black listing-table mt-5"
              >
                <thead className="bg-primary text-white">
                  <tr className="text-nowrap">
                    <th> </th>
                    <th>#</th>
                    <th>Title Image</th>
                    <th>Content Name</th>
                    <th>type</th>
                    <th>No of Episode</th>
                    <th>Upload date</th>
                  </tr>
                </thead>
                <tbody className="font-smallest">
                  {filteredAllContent.map((filteredAllContentItem, index) => {
                    return (
                      <tr key={index + 1}>
                        <td>
                          <div
                            className="custom-control custom-checkbox dark"
                            onClick={(e) =>
                              this.handleSelectItemHandler(
                                e,
                                filteredAllContentItem.content
                              )
                            }
                          >                            
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="row-checkbox1"
                              readOnly={true}
                              checked={
                                isSelectedItem.isSelected &&
                                !isEmptyObject(isSelectedItem.selectedItem) &&
                                isSelectedItem.selectedItem.id ===
                                  filteredAllContentItem.content.id &&
                                isSelectedItem.selectedItem.content_type ===
                                  filteredAllContentItem.content.content_type
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="checkbox-table1"
                            ></label>
                          </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            src={
                              filteredAllContentItem.content &&
                              filteredAllContentItem.content.title_image
                                ? filteredAllContentItem.content.title_image
                                : "/assets/images/list-item-1.svg"
                            }
                            alt="list-item-1"
                            className="item-image rounded"
                          />
                        </td>
                        {filteredAllContentItem.content &&
                        filteredAllContentItem.content.content_type ===
                          "show" &&
                        filter === "update" ? (
                          <td className="listing-table-data">
                            <Accordion>
                              <Card className="border-0">
                                <Accordion.Toggle
                                  as={Card.Header}
                                  eventKey="0"
                                  className=" border-0 px-0 py-0"
                                >
                                  <p className="font-smallest font-weight-semi">
                                    {" "}
                                    {filteredAllContentItem.content &&
                                      filteredAllContentItem.content.title &&
                                      filteredAllContentItem.content.title}
                                  </p>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                  <Card.Body className="px-1">
                                    <ul>
                                      {filteredAllContentItem.content &&
                                      filteredAllContentItem.content.episodes &&
                                      filteredAllContentItem.content.episodes
                                        .length ? (
                                        filteredAllContentItem.content.episodes.map(
                                          (episode, i) => (
                                            <li
                                              className="d-flex mb-2"
                                              key={episode.id}
                                            >
                                              <label
                                                className="custom-control custom-checkbox dark  mt-2"
                                                onClick={(e) =>
                                                  this.handleSelectItemHandler(
                                                    e,
                                                    episode
                                                  )
                                                }
                                              >
                                            
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id="episode1"
                                                  name="row-checkbox"
                                                  checked={
                                                    isSelectedItem.isSelected &&
                                                    !isEmptyObject(
                                                      isSelectedItem.selectedItem
                                                    ) &&
                                                    isSelectedItem.selectedItem
                                                      .id === episode.id
                                                      &&
                                                      isSelectedItem.selectedItem
                                                      .content_type === episode.content_type 
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="episode1"
                                                ></label>
                                              </label>
                                              <span className="bg-grey px-3 d-flex align-items-center rounded mx-2">
                                                {i + 1}
                                              </span>
                                              <div className="form-group mb-0 flex-grow-1">
                                                <input
                                                  type="text"
                                                  value={episode.title}
                                                  className="form-control rounded font-weight-normal"
                                                  placeholder="Episode Title"
                                                  disabled={true}
                                                />
                                              </div>
                                            </li>
                                          )
                                        )
                                      ) : (
                                        <div>No episodes released yet!</div>
                                      )}
                                    </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </td>
                        ) : (
                          <td className="listing-table-data">
                            <p className="font-smallest font-weight-semi">
                              {" "}
                              {filteredAllContentItem.content &&
                                filteredAllContentItem.content.title}
                              .
                            </p>
                          </td>
                        )}
                        <td>
                          <span className="font-weight-400">
                            {filteredAllContentItem.content.content_type ===
                            "youtube-recipe"
                              ? "Recipe"
                              : filteredAllContentItem.content.content_type ===
                                "video"
                              ? "Video"
                              : filteredAllContentItem.content.content_type ===
                                "show"
                              ? "Show"
                              : ""}
                          </span>
                        </td>
                        <td>
                          <span className="font-weight-400">
                            {filteredAllContentItem.content.content_type ===
                            "show"
                              ? filteredAllContentItem.content &&
                                filteredAllContentItem.content.episodes &&
                                filteredAllContentItem.content.episodes.length
                              : "N/A"}
                          </span>
                        </td>
                        <td className="font-weight-bold">
                          {filteredAllContentItem.content &&
                          filteredAllContentItem.content.created
                            ? moment(
                                filteredAllContentItem.content.created
                              ).format("MM/DD/YYYY")
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <NoDataFoundBasic description="No published data found!" />
            )}
          </div>
        </Container>
      </div>
    );
  }
}

// itemAdded
// errorMessage

const mapStateToProps = (state) => {
  return {
    error: state.add_to_dashboard_items_reducer.errorMessage,
    allContentItems: state.carousel_items_reducer.allContentItems,
    dashboardItemAdded: state.add_to_dashboard_items_reducer
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllContentItems: () => dispatch(get_all_content_action()),
  addDashboardItem: (data) => dispatch(add_to_dashboard_item_action(data)),
  editDashboardItem: (id, data) =>
    dispatch(edit_to_dashboard_item_action(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselListing);
