import {contentConstants, userTableConstants} from "../../_constants";


const initialState = {}
export const add_recipe_video_content_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.GET_SAVED_CHANGES_ACTION_SUCCESS:
        case contentConstants.SAVE_RECIPE_CHANGES_ACTION_SUCCESS:
            return {
                savedChanges: action.payload
            }
        case contentConstants.GET_SAVED_CHANGES_ACTION_FAILURE:
        case contentConstants.SAVE_RECIPE_CHANGES_ACTION_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}

export const publish_recipe_video_content_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.PUBLISH_RECIPE_CHANGES_ACTION_SUCCESS:
            return {
                publishedVideo: action.payload
            }
        case contentConstants.PUBLISH_RECIPE_CHANGES_ACTION_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}

export const add_connected_recipe_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.SAVE_CONNECTED_RECIPE_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.SAVE_CONNECTED_RECIPE_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.SAVE_CONNECTED_RECIPE_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_connected_recipe_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.GET_CONNECTED_RECIPE_BY_ID_ACTION_SUCCESS:
            return {
                connectedRecipes: action.payload
            }
        case contentConstants.SAVE_CONNECTED_RECIPE_ACTION_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}


export const content_manager_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.SAVED_CONTENT_ACTION_SUCCESS:
            return {
               contentId: action.payload.id,
               contentType: action.payload.type,
            }
        case contentConstants.SAVED_CONNECTED_RECIPE_ACTION_SUCCESS:
            return {
                connectedRecipeId: action.payload.id,
             }
        default:
            return state;
    }
}

export const get_recipe_listing_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.GET_RECIPE_LISTING_SUCCESS:
            return {
                recipeListing: action.payload
            }
        case contentConstants.GET_RECIPE_LISTING_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}

export const edit_recipe_listing_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.EDIT_RECIPE_LISTING_SUCCESS:
            return {
                editRecipeListing: action.payload
            }
        case contentConstants.EDIT_RECIPE_LISTING_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}

export const delete_recipe_listing_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.DELETE_RECIPE_LISTING_SUCCESS:
            return {
                deleteRecipeListing: action.payload
            }
        case contentConstants.DELETE_RECIPE_LISTING_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}

export const upload_faq_question_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.CREATE_FAQ_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.CREATE_FAQ_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.CREATE_FAQ_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const load_faq_table_list_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.GET_FAQ_TABLE_DATA_LIST_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.GET_FAQ_TABLE_DATA_LIST_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.GET_FAQ_TABLE_DATA_LIST_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const delete_faq_topic_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.DELETE_FAQ_TOPIC_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.DELETE_FAQ_TOPIC_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.DELETE_FAQ_TOPIC_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const fetch_faq_topic_details_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.GET_FAQ_TOPIC_DETAILS_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.GET_FAQ_TOPIC_DETAILS_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.GET_FAQ_TOPIC_DETAILS_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const new_faq_subtopic_addition_for_topic_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const delete_subTopic_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.DELETE_FAQ_SUBTOPIC_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.DELETE_FAQ_SUBTOPIC_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.DELETE_FAQ_SUBTOPIC_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_saved_video_changes_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.GET_SAVED_VIDEO_CHANGES_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.GET_SAVED_VIDEO_CHANGES_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.GET_SAVED_VIDEO_CHANGES_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_announcement_table_list_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.GET_ANNOUNCEMENT_TABLE_DATA_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.GET_ANNOUNCEMENT_TABLE_DATA_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.GET_ANNOUNCEMENT_TABLE_DATA_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const delete_announcement_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.DELETE_ANNOUNCEMENT_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.DELETE_ANNOUNCEMENT_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.DELETE_ANNOUNCEMENT_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_single_announcement_details_reducer = (state = initialState, action) => {
    switch (action.type) {
        case contentConstants.GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case contentConstants.GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case contentConstants.GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}