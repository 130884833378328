import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";

import {
  logout_user_action,
  set_client_token_for_notification_action,
} from "../../../../_actions/Common";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import { get_notification_action } from "../../../../_actions/User/notification.action";
import { getOneSignalPlayerId } from "../../../../Config/onesignalService";

class SidebarMenu extends Component {
  state = {
    loading: false,
    newNotificationAdded: false,
  };

  updateNotifications = (payload) => {
    if (
      payload &&
      payload.notification &&
      payload.notification.title &&
      payload.notification.body
    ) {
      this.setState({ newNotificationAdded: true });
    }
  };

  handleLogout = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token) {
      const token = {
        refresh: user["refresh_token"],
      };
      // if (token) {
      //     let playerId = await getOneSignalPlayerId()
      //     if (playerId) {
      //         const data = {
      //             registration_id: playerId,
      //             type: "web",
      //             device_id: playerId.substr(0, 150),
      //             active: false
      //         }
      //         await this.props.setClientForNotification(data)
      //     }
      // }

      const logoutRes = await this.props.userLogout(token);
      if (logoutRes !== undefined) {
        this.props.history.push("/login");
      }
    }
    this.setState({ loading: false });
  };

  render() {
    const { loading, newNotificationAdded } = this.state;
    return (
      <div className="sidebar-menu bg-primary position-fixed  h-100">
        <ul className="d-flex flex-md-column align-items-center py-md-5 justify-content-between justify-content-md-start w-100">
          <li>
            <a
              href={
                localStorage.getItem("user") !== null &&
                localStorage.getItem("user")["userRole"] &&
                localStorage.getItem("user")["userRole"] === "user"
                  ? "/user-home-page"
                  : localStorage.getItem("user")["userRole"] === "admin"
                  ? "/admin-home-page"
                  : "/login"
              }
            >
              <img src={"/assets/images/home-icon.svg"} alt="home" />
            </a>
          </li>
          <li>
            <a href={"/shopping-home"}>
              <img src={"/assets/images/store-icon.svg"} alt="store" />
            </a>
          </li>
          <li>
            <a href={"/add-post/add-new"}>
              <img src={"/assets/images/plus-icon.svg"} alt="plus icon" />
            </a>
          </li>
          <li>
            <a href={"/chatboard"}>
              <img src={"/assets/images/group-icon.svg"} alt="group icon" />
            </a>
          </li>
          <li className={"position-relative"}>
            <a
              onClick={() => this.setState({ newNotificationAdded: false })}
              href={"/notifications"}
            >
              <span className="fa fa-bell text-white fs-20" />
              {newNotificationAdded ? (
                <span
                  className="fa fa-circle text-warning position-absolute"
                  style={{ top: "-5px", right: "-2px", zIndex: 1 }}
                />
              ) : null}
            </a>
          </li>
          <li>
            <a href="#" onClick={this.handleLogout}>
              {loading ? (
                <ContentLoader color="text-white" />
              ) : (
                <img src={"/assets/images/power-white.png"} alt="logout" />
              )}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userLogout: (data) => dispatch(logout_user_action(data)),
  mountNotification: () => dispatch(get_notification_action()),
  setClientForNotification: (data) =>
    dispatch(set_client_token_for_notification_action(data)),
});

export default connect(null, mapDispatchToProps)(SidebarMenu);
