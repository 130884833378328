import React, { Component } from "react";
import {
  Container,
  FormControl,
  Table,
  FormGroup,
  Dropdown,
} from "react-bootstrap";
import { Pagination } from "antd";
import { connect } from "react-redux";

import TopTab from "../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../common/SidebarMenu/AdminSidebarMenu";
import {
  get_all_reported_content_action,
  get_filtered_reports_action,
} from "../../../../_actions/Admin";
import { AntDLoader, LoaderBasic } from "../../common/Loader";

class ReportsReview extends Component {
  state = {
    selected: [],
    filterType: "",
    reportedItems: [],
    optionSelected: "",
    isApiLoading: false,
    pagination: {
      next: "",
      total: 0,
      current: 1,
      pageSize: 25,
      previous: "",
    },
  };

  componentDidMount() {
    this.getReportedItemsApi();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportedItems !== this.props.reportedItems) {
      if (
        this.props.reportedItems.data &&
        this.props.reportedItems.data.results
      ) {
        this.setState((prevState) => ({
          reportedItems: this.props.reportedItems.data.results,
          pagination: {
            ...prevState.pagination,
            next: this.props.reportedItems.data.next,
            previous: this.props.reportedItems.data.previous,
            total: this.props.reportedItems.data.count
              ? this.props.reportedItems.data.count
              : this.props.reportedItems.data.results.length,
          },
        }));
      }
    }
  }

  getFilteredReportsApi = async () => {
    const { filterType } = this.state;

    if (filterType === "none") {
      this.getReportedItemsApi();
    } else {
      this.setState({ isApiLoading: true });
      await this.props.getFilteredReports({ filter: filterType });
      this.setState({ isApiLoading: false });
    }
  };

  getReportedItemsApi = async () => {
    const { pagination } = this.state;
    this.setState({ isApiLoading: true });
    await this.props.getReportedItems(pagination.current);
    this.setState({ isApiLoading: false });
  };

  handleSelectCheckBox = (id) => {
    this.setState((prevState) => ({
      selected: prevState.selected.includes(id)
        ? prevState.selected.filter((item) => item !== id)
        : [...prevState.selected, id],
    }));
  };

  onToggleSelectAll = (type) => {
    this.setState((prevState) => ({
      selected:
        type === "select" ? prevState.reportedItems.map((item) => item.id) : [],
      optionSelected: type,
    }));
  };

  handlePagination = (page) => {
    this.setState(
      (prevState) => ({
        pagination: { ...prevState.pagination, current: page },
      }),
      () => {
        this.getReportedItemsApi();
      }
    );
  };

  handleFilterChange = (filterType) => {
    this.setState({ filterType: filterType }, () => {
      this.getFilteredReportsApi();
    });
  };

  handleReviewClick = (
    id,
    type,
    reportId,
    reporterFirstName,
    reporterLastName,
    reportType
  ) => {
    let reporterName = reporterFirstName + " " + reporterLastName;
    if (type === "photo") {
      return this.props.history.push(`/reported-photo/${id}`, {
        reportId,
        reporterName,
        reportType,
      });
    }
    if (type === "status") {
      return this.props.history.push(`/reported-status/${id}`, {
        reportId,
        reporterName,
        reportType,
      });
    }
    if (type === "video") {
      return this.props.history.push(`/reported-video/${id}`, {
        reportId,
        reporterName,
        reportType,
      });
    }
    if (type === "recipe") {
      return this.props.history.push(`/reported-recipe/${id}`, {
        reportId,
        reporterName,
        reportType,
      });
    }
    if (type === "comment") {
      return this.props.history.push(`/reported-comment/${id}`, {
        reportId,
        reporterName,
        reportType,
      });
    }
  };

  render() {
    const {
      selected,
      filterType,
      isApiLoading,
      reportedItems,
      pagination,
      optionSelected,
    } = this.state;

    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu />
          <TopTab activeTab="Report/Review" tabCategoryName="userDataReports" />
          <div className="main-content  bg-white">
            <div className="user-data report-review bg-grey-6 pt-md-5 mt-5">
              <Container className="p-0">
                <div className="user-data-wrapper bg-white">
                  <h1 className="mb-5">Report/Review</h1>

                  {/* search and filters ========== */}
                  <div className="search-and-filter d-flex mb-3">
                    <FormGroup className="mb-0 search-bar flex-grow-1">
                      <FormControl
                        placeholder="Search"
                        className="text-grey-3 h-100"
                      />
                    </FormGroup>
                    <div className="search-filter ml-2">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="filter-toggler d-flex align-items-center justify-content-between bg-grey-3"
                        >
                          filter{" "}
                          <img
                            src="/assets/images/dropdown.svg"
                            alt="dropdown"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="font-smallest font-weight-bold bg-grey border-0 p-4 h-auto rounded ">
                          <ul>
                            <li className="d-flex">
                              <p className="text-nowrap">Filter by action :</p>
                              <ul className="ml-3">
                                <li className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="actionDelete"
                                    name=""
                                    checked={filterType === "delete"}
                                    onClick={() =>
                                      this.handleFilterChange("delete")
                                    }
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="actionDelete"
                                  >
                                    Delete
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="actionDismiss"
                                    name=""
                                    checked={filterType === "dismiss"}
                                    onClick={() =>
                                      this.handleFilterChange("dismiss")
                                    }
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="actionDismiss"
                                  >
                                    Dismiss
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="actionNone"
                                    name=""
                                    checked={filterType === "none"}
                                    onClick={() =>
                                      this.handleFilterChange("none")
                                    }
                                  />
                                  <label
                                    className="custom-control-label v"
                                    htmlFor="actionNone"
                                  >
                                    None
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  {/* search and filters ========== */}

                  {/* data export */}
                  <ul className="data-exports d-flex flex-wrap mb-4">
                    <li>
                      <div class="custom-control custom-radio info">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="SelectAll1"
                          name="selectdata"
                          checked={optionSelected === "select"}
                          onClick={() => this.onToggleSelectAll("select")}
                        />
                        <label
                          class="custom-control-label ml-2 font-montserrat font-weight-400 text-grey-2 fs-14"
                          htmlFor={"SelectAll1"}
                        >
                          Select all
                        </label>
                      </div>
                    </li>
                    <li className="mr-2">
                      <div class="custom-control custom-radio info ml-3">
                        <input
                          type="radio"
                          id="DeselectAll2"
                          name="selectdata"
                          class="custom-control-input"
                          checked={optionSelected === "deselect"}
                          onClick={() => this.onToggleSelectAll("deselect")}
                        />
                        <label
                          class="custom-control-label ml-2 font-montserrat font-weight-400 text-grey-2 fs-14"
                          htmlFor="DeselectAll2"
                        >
                          Deselect All
                        </label>
                      </div>
                    </li>
                    <li className="ml-auto export">
                      <a
                        className="Clear-selected-items fs-small py-2 font-weight-bold text-info"
                        onClick={() => this.onToggleSelectAll("deselect")}
                      >
                        Clear all selected Items
                      </a>
                    </li>
                  </ul>
                  {/* data export */}

                  {/* Pagination */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 50,
                      marginBottom: 50,
                    }}
                  >
                    <Pagination
                      onChange={this.handlePagination}
                      showSizeChanger={false}
                      current={pagination.current}
                      pageSize={pagination.pageSize}
                      total={pagination.total}
                    />
                  </div>
                  {/* End Pagination */}

                  <Table borderless responsive className="text-black">
                    <thead>
                      <tr className="text-nowrap">
                        <th> </th>
                        <th>ID</th>
                        <th>Posted By</th>
                        <th>Reported By</th>
                        <th>Type</th>
                        <th>Report</th>
                        <th>Review</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {isApiLoading ? (
                      <tbody className="text-center">
                        <tr>
                          <td colSpan="7">
                            <AntDLoader size={40} />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="font-smallest">
                        {reportedItems && reportedItems.length ? (
                          reportedItems.map((reportedItem) => (
                            <tr key={reportedItems.id}>
                            
                              <td className="">
                                <div className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    name="row-checkbox16"
                                    className="custom-control-input"
                                    id={`checkbox-table${reportedItem.id}`}
                                    checked={selected.includes(reportedItem.id)}
                                    onClick={() =>
                                      this.handleSelectCheckBox(reportedItem.id)
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`checkbox-table${reportedItem.id}`}
                                  ></label>
                                </div>
                              </td>
                              <td>{reportedItem.id}</td>
                              <td>{`${reportedItem.user_posted.first_name} ${reportedItem.user_posted.last_name}`}</td>
                              <td>{`${reportedItem.reported_by_user_details.first_name} ${reportedItem.reported_by_user_details.last_name}`}</td>
                              <td>{reportedItem.content_type}</td>
                              <td>{reportedItem.report_type}</td>
                              <td>
                                <a
                                  className="text-info text-underline"
                                  onClick={() =>
                                    this.handleReviewClick(
                                      reportedItem.content_type === "comment"
                                        ? reportedItem.comment
                                        : reportedItem.post,
                                      reportedItem.content_type,
                                      reportedItem.id,
                                      reportedItem.reported_by_user_details
                                        .first_name,
                                      reportedItem.reported_by_user_details
                                        .last_name,
                                      reportedItem.report_type
                                    )
                                  }
                                >
                                  <span className="font-weight-bold">
                                    Review
                                  </span>
                                </a>
                              </td>
                              <td className="font-weight-bold">
                                {reportedItem.action}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div>No one has reported anything</div>
                        )}
                      </tbody>
                    )}
                  </Table>
                </div>
              </Container>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.add_to_dashboard_items_reducer.errorMessage,
    reportedItems: state.reported_content_reducer.reportedItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFilteredReports: (data) => dispatch(get_filtered_reports_action(data)),
  getReportedItems: (page) => dispatch(get_all_reported_content_action(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsReview);
