import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./EditProfile.scss";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import { connect } from "react-redux";
import {
  edit_user_name_action,
  edit_user_image_action,
} from "../../../_actions/User/profile.action";
import swal from "sweetalert";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      firstName: "",
      lastName: "",
      imgSrc: "",
      imageName: "",
      errorText: "",
      editImgStatus: null,
      provider: null,
    };
  }
  componentWillMount() {
    this.setState({
      content: this.props.location.state.user,
      firstName: this.props.location.state.user.first_name,
      lastName: this.props.location.state.user.last_name,
      imgSrc: this.props.location.state.user.profile_image,
      provider: this.props.location.state.user.provider,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.editUserName !== this.props.editUserName) {
      if (
        this.props.editUserName &&
        this.props.editUserName.message &&
        this.props.editUserName.message.length
      ) {
        swal({
          title: "Great!",
          text: "Your Profile has been updated Successfully",
          icon: "success",
        });
        this.props.history.goBack();

        return;
      } else {
        swal({
          title: "Oops!",
          text: "Updated Failed",
          icon: "error",
        });
        return;
      }
    }
  }

  // changes name function
  updateInfoHandler = () => {
    const { firstName, lastName, imgSrc } = this.state;
    if (!imgSrc) {
      this.props.history.goBack();
      return;
    }
    if (firstName === "" || lastName === "" || imgSrc === "") {
      swal({
        title: "Oops!",
        text: "Please fill all  fields",
        icon: "info",
      });
      return;
    }
    const data = {
      first_name: firstName,
      last_name: lastName,
    };

    this.props.updateUserName(data);
  };

  // image upload handler
  handleUploadFile = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    const data = new FormData();
    data.append("profile_image", file);
    let imgResponse = await this.props.updateUserImg(data);
    this.setState({
      imgSrc: imgResponse && imgResponse.data && imgResponse.data.profile_image,
    });
    swal({
      title: "Great!",
      text: "Your Profile Picture has been updated Successfully",
      icon: "success",
    });
    this.props.history.goBack();
  };

  render() {
    let profileData = this.state && this.state.content;

    // first letter for first name
    let firstLetterFirstName =
      profileData && profileData.first_name && profileData.first_name.charAt(0);

    // first letter for last name
    let firstLetterLastName =
      profileData && profileData.last_name && profileData.last_name.charAt(0);

    return (
      <div className="common-dashboard user-profile edit-profile">
        <Container className="p-0">
          <SidebarMenu />
          <div className="main-content bg-white">
            <Header />
            <div className="edit-profile-wrapper pt-5">
              <a  href="/user-profile" className="back-option text-dark font-weight-bold cursor-pointer mt-5 mb-3 d-none d-md-flex align-items-center">
                <img src="/assets/images/back-arrow.svg" alt="back button" />{" "}
                Back
              </a>
              <Row className="d-flex align-items-center justify-content-center">
                <Col md={6} lg={4} className="mt-5 mt-md-0">
                  <div className="profile-content text-center mt-4">
                    <div className="profile-section font-weight-bold ">
                      <div className="profile-img m-auto position-relative">
                        {this.state.imgSrc ? (
                          <img
                            src={this.state.imgSrc}
                            alt="profile-pic"
                            className="profile-pic img-fit rounded-circle  w-100 position-relative"
                          />
                        ) : (
                          <span className="user-initials bg-primary text-white d-flex align-items-center justify-content-center font-weight-bold rounded-circle">
                            {firstLetterFirstName} {firstLetterLastName}
                          </span>
                        )}

                        <label
                          htmlFor="uploadProfileImg"
                          className="cursor-pointer"
                        >
                          <input
                            type="file"
                            id="uploadProfileImg"
                            className="d-none"
                            onChange={(e) => this.handleUploadFile(e)}
                            accept="image/*"
                          />
                          <img
                            src="/assets/images/camera-edit.svg"
                            alt="camera-edit"
                            className="camera-edit img-fit rounded-circle bg-dark w-100 position-absolute"
                          />
                        </label>
                      </div>
                      <p className="">{this.state.editImgStatus}</p>

                      <h4 className="full-name fs-18 mt-4">
                        {profileData && profileData.first_name}{" "}
                        {profileData && profileData.last_name}
                      </h4>

                      <p className="fs-14 text-grey-4 ">
                        {profileData && profileData.email}
                      </p>
                    </div>

                    <div className="form-wraper d-flex justify-content-center align-items-center mt-4  my-5 mb-md-0 pb-2">
                      <div>
                        <Form.Group className="with-placeholder">
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={this.state.firstName}
                            name="firstName"
                            onChange={(e) =>
                              this.setState({ firstName: e.target.value })
                            }
                            required
                          />
                          <span className="placeholder-text font-montserrat">
                            First Name
                          </span>
                        </Form.Group>
                        <Form.Group className="with-placeholder">
                          <Form.Control
                            type="text"
                            placeholder=" "
                            name="lastName"
                            value={this.state.lastName}
                            onChange={(e) =>
                              this.setState({ lastName: e.target.value })
                            }
                            required
                          />
                          <span className="placeholder-text font-montserrat">
                            Last Name
                          </span>
                        </Form.Group>

                        {this.state.provider === "google" ||
                        this.state.provider === "facebook" ? null : (
                          <Form.Group>
                            <a
                              href="/change-password"
                              className="btn btn-block fs-16 text-primary py-2 bg-grey-3 font-weight-bold border-radius-10"
                            >
                              Change Password
                            </a>
                          </Form.Group>
                        )}

                        <Form.Group className="mb-4">
                          <Button
                            type="submit"
                            variant="warning"
                            className="fs-14 py-3 font-weight-bold border-6"
                            block
                            onClick={this.updateInfoHandler}
                          >
                            Done
                          </Button>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editUserName: state.edit_user_name_reducer,
    editUserImg: state.edit_user_image_reducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserName: (data) => dispatch(edit_user_name_action(data)),
  updateUserImg: (data) => dispatch(edit_user_image_action(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
