import React from "react";
import { Avatar } from "antd";
import { toUpperCaseAndDisplayFirstLetter } from "../../../_helpers/validators";

export default ({ size, image, username, containerStyles, avatarStyles }) => {
  const avSize = size || 40;
  return (
    <div style={{ ...containerStyles }}>
      {image ? (
        <Avatar size={avSize} src={image} style={{ ...avatarStyles }} />
      ) : (
        <Avatar
          size={avSize}
          style={{ ...avatarStyles, background: "#87449a" }}
        >
          {toUpperCaseAndDisplayFirstLetter(username)}
        </Avatar>
      )}
    </div>
  );
};
