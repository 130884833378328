import { headerSearchConstants } from "../../_constants/User";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

export const header_search_action = (data) => async(dispatch) => {
    dispatch({
        type: headerSearchConstants.HEADER_SEARCH_ACTION_REQUEST,
    });
    try {
        const response = await axiosInstance.post(
            config.userHeaderSearch.headerSearchResult,
            data
        );
        if (response.status) {
            dispatch({
                type: headerSearchConstants.HEADER_SEARCH_ACTION_SUCCESS,
                payload: response.data,
            });
            return response.data;
        } else {
            return false;
        }
    } catch (e) {
        dispatch({
            type: headerSearchConstants.HEADER_SEARCH_ACTION_FAILURE,
            payload: e.msg,
        });
    }
};



export const video_search_action = (data) => async(dispatch) => {
    dispatch({
        type: headerSearchConstants.VIDEO_SEARCH_ACTION_REQUEST,
    });
    try {
        const response = await axiosInstance.post(
            config.userHeaderSearch.videoSearchResult,
            data
        );
        if (response.status) {
            dispatch({
                type: headerSearchConstants.VIDEO_SEARCH_ACTION_SUCCESS,
                payload: response.data,
            });
            return response.data;
        } else {
            return false;
        }
    } catch (e) {
        dispatch({
            type: headerSearchConstants.VIDEO_SEARCH_ACTION_FAILURE,
            payload: e.msg,
        });
    }
};


export const recipe_search_action = (data) => async(dispatch) => {
    dispatch({
        type: headerSearchConstants.RECIPE_SEARCH_ACTION_REQUEST,
    });
    try {
        const response = await axiosInstance.post(
            config.userHeaderSearch.recipeSearchResult,
            data
        );
        if (response.status) {
            dispatch({
                type: headerSearchConstants.RECIPE_SEARCH_ACTION_SUCCESS,
                payload: response.data,
            });
            return response.data;
        } else {
            return false;
        }
    } catch (e) {
        dispatch({
            type: headerSearchConstants.RECIPE_SEARCH_ACTION_FAILURE,
            payload: e.msg,
        });
    }
};


export const show_search_action = (data) => async(dispatch) => {
    dispatch({
        type: headerSearchConstants.SHOW_SEARCH_ACTION_REQUEST,
    });
    try {
        const response = await axiosInstance.post(
            config.userHeaderSearch.showSearchResult,
            data
        );
        if (response.status) {
            dispatch({
                type: headerSearchConstants.SHOW_SEARCH_ACTION_SUCCESS,
                payload: response.data,
            });
            return response.data;
        } else {
            return false;
        }
    } catch (e) {
        dispatch({
            type: headerSearchConstants.SHOW_SEARCH_ACTION_FAILURE,
            payload: e.msg,
        });
    }
};