import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    return (
        <Route {...rest} render={props => (
            (user && user.user.role === 'user')
                ? <Redirect to='/user-home-page'  />
                : (user && user.user.role === 'admin')
                ? <Redirect to='/admin-home-page'  />
                : <Component {...props} />
        )} />
    )
}