import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Form, Row, Col } from "react-bootstrap";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import { header_search_action } from "../../../_actions/User";
// import ContentLoader from "../../Common/Loader/ContentLoader";
import "./SearchResults.scss";
class SearchResults extends Component {
  state = {
    videoLoading: false,
    pageSearchResults: [],
    newAddition: null,
    loading: false,
    searchResultData: [],
    noResultsFound: false,
    searchStatus: false,
    searchEnabled: false,
  };

  componentDidMount() {
    if (this.props.location.state.searchResultsData.count < 1) {
      this.setState({ noResultsFound: true });
    } else {
      this.setState({ noResultsFound: false });
      this.setState({
        searchResultData: this.props.location.state.searchResultsData.results,
      });
    }
  }

  // search handler ===========
  searchHandler = async (e) => {
    let searchText = e.target.value;
    let searchKeyword = { search: searchText };
    {
      searchText !== ""
        ? this.setState({ searchEnabled: true })
        : this.setState({ searchEnabled: false });
    }

    let searchResult = await this.props.searchResultData(searchKeyword);
    let searchResultData =
      searchResult && searchResult.data && searchResult.data.results;

    if (
      searchResult.data.count > 0 &&
      searchResultData &&
      searchResultData.length
    ) {
      this.setState({ pageSearchResults: searchResultData });
      this.setState({ searchStatus: true });
      return;
    } else {
      this.setState({ searchStatus: false });
    }
  };

  routeHandler = (data) => {
    if (data && data.content && data.content.content_type === "video" ) {
      this.props.history.push(`/video/${data.content.id}`)
    } else if (data && data.content && data.content.content_type === "show") {
      this.props.history.push(`/view-show/${data.content.id}`)
    } else if (data && data.content && data.content.content_type === "youtube-recipe") {
      this.props.history.push(`/recipe/${data.content.id}`)
    }
  };

  render() {
    const {
      videoLoading,
      pageSearchResults,
      searchResultData,
      noResultsFound,
      searchEnabled,
    } = this.state;
    return (
      <div className="common-dashboard">
        <Container className="p-0">
          <SidebarMenu />
          <div className="main-content bg-white position-relative">
            <Header searchType="videos" />
            <div className="top-search-wrapper flex-grow-1 mb-4 mr-0 mr-sm-2 mr-md-5">
              <Form.Group className="mb-0 h-100">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className="search-bar bg-grey-4 border-6 pl-4"
                  onChange={(e) => this.searchHandler(e)}
                />
              </Form.Group>
            </div>

            {/* all video wrapper */}
            <div className="all-video-wrapper mt-3 mt-md-5">
              <h3 className="font-weight-semi pt-4 mb-5">Search Results</h3>
              {!searchEnabled ? (
                <>
                  {!noResultsFound ? (
                    <>
                      <Row className="all-videos-row">
                        {searchResultData &&
                          searchResultData.map((data) => (
                            <Col className="col-6 col-md-3 mb-5">
                              <div
                                  className="video-box cursor-pointer"
                                  onClick={() => this.routeHandler(data)}
                              >
                                <img
                                  src={data.content.title_image}
                                  alt="slider-img-1"
                                  className="border-6 img-fit w-100"
                                />
                                <h4 className="title font-weight-semi my-2">
                                  {data.content.title}
                                </h4>
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </>
                  ) : (
                    <div className="text-center p-5">
                      <h3 className="text-danger-2">Oops! No Results Found</h3>
                    </div>
                  )}
                </>
              ) : (
                <Row className="search enabled">
                  {!this.state.searchStatus ? (
                    <div className="text-center p-5 w-100">
                      <h3 className="text-danger-2">Oops! No Results Found</h3>
                    </div>
                  ) : (
                    pageSearchResults &&
                    pageSearchResults.map((video) => (
                      <Col
                        key={video.content.id}
                        className="col-6 col-md-3 mb-5"
                      >
                        <div
                          className="video-box cursor-pointer"
                          onClick={() => this.routeHandler(video, video.content.id)}
                        >
                          <img
                            src={video.content.title_image}
                            alt="slider-img-1"
                            className="border-6 img-fit w-100"
                          />
                          <h4 className="title font-weight-semi my-2">
                            {video.content.title}
                          </h4>
                        </div>
                      </Col>
                    ))
                  )}
                </Row>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchResultData: (data) => dispatch(header_search_action(data)),
});

export default connect(null, mapDispatchToProps)(SearchResults);
