import React, { Component } from "react";
import { Container, Form, Button } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import OneSignalReact from "react-onesignal";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  addTagOnOneSignal,
  getOneSignalPlayerId,
} from "../../../Config/onesignalService";

import {
  facebook_login_action,
  google_login_action,
  login_user_action,
  set_client_token_for_notification_action,
} from "../../../_actions/Common";
import { validateEmail } from "../../../_helpers/validators";
import ContentLoader from "../Loader/ContentLoader";

class Login extends Component {
  state = {
    email: "",
    password: "",
    isEmailValid: true,
    loading: false,googleLoader: false,
    facebookLoader:false,
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { email, password, isEmailValid } = this.state;
    if (!email || !password) {
      swal("All fields are required!");
      this.setState({ loading: false });
      return;
    }
    if (isEmailValid) {
      const loginData = { email, password };

      const loginRes = await this.props.onLoginUser(loginData);
      if (loginRes !== undefined && loginRes && loginRes.user) {
        let userRole = loginRes.user.role;
        if (userRole === "user") {
          // hit the fcm token and redierct to home page
            this.props.history.push("/user-home-page");
            return;
        } else if (userRole === "admin")
          this.props.history.push("admin-home-page");
      }
    } else {
      swal("Email not valid!");
    }
    this.setState({ loading: !this.state.loading });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "email") {
        this.setState({ isEmailValid: validateEmail(this.state.email) });
      }
    });
  };
  successResponseGoogle = async (response) => {
 
    const token = { access_token: response.accessToken };
    if (token) {
      this.setState({ googleLoader: true });
      const loginRes = await this.props.onGoogleLogin(token);
      this.setState({ googleLoader: false });
      if (loginRes !== undefined && loginRes && loginRes.user) {
        // check if the user received is an admin or a regular user
        // and redirect accordingly
        let userRole = loginRes && loginRes.user && loginRes.user.role;
        if (userRole === "user") {
            this.props.history.push("/user-home-page");
            return;
        } else if (userRole && userRole === "admin")
          this.props.history.push("admin-home-page");
      } else {
        swal(response.error);
      }
    }
  };
  failureResponseGoogle = (response) => {
    if (response.error !== "popup_closed_by_user")
      if (response.details.includes("Cookies are not enabled"))
        swal("Please enable cookies.");
      else swal(response.error);
  };
  responseFacebook = async (response) => {
    if (response.status !== "unknown") {
      if (response.accessToken) {
        const token = { access_token: response.accessToken };
        this.setState({ facebookLoader: true });
        const loginRes = await this.props.onFacebookLogin(token);
        this.setState({ facebookLoader: false });
        if (loginRes && loginRes.user) {
          let userRole = loginRes.user.role;
          if (userRole === "user") {
            this.props.history.push("/user-home-page");
            return;
          } else if (userRole === "admin")
            this.props.history.push("admin-home-page");
        } else {
          swal("Sign in failed. Try again");
        }
      }
    }
  };

  setNotifications = async (user) => {
    const playerId = await getOneSignalPlayerId();
    let data;
    if (playerId) {
      data = {
        registration_id: playerId,
        type: "web",
        device_id: playerId.substr(0, 150),
        active: true,
      };
      await addTagOnOneSignal("userId", user.id);
      await this.props.setClientForNotification(data);
    }
    console.warn('Dvuvu: ', data)
    return this.props.history.push("/user-home-page");
  };

  render() {
    const { email, password, isEmailValid, loading,googleLoader } = this.state;
    console.warn('Test the app')
    return (
      <div className="common-login d-flex align-items-center jusfity-content-center fs-18">
        <Container className="p-0">
          <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">
            <div className="form-wraper d-flex justify-content-center align-items-center order-2 order-md-1 pb-5 mb-md-0">
              <Form action="#">
                <h2 className="text-dark form-title text-center font-poppins mb-5">
                  Login
                </h2>
                <Form.Group className="d-flex justify-content-between social-login-links">
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_ID}
                    render={(renderProps) => (
                      <a
                        type="button"
                        onClick={renderProps.onClick}
                        className="d-flex align-items-center justify-content-center font-smallest bg-grey-3 p-2 font-weight-bold text-black rounded-pill"
                      >
                         {googleLoader ? (
                          <ContentLoader color="text-warning mr-2" />
                        ) : (
                          <img
                            src="/assets/images/google-plus-icon.svg"
                            className="mr-2"
                            alt="Google"
                          />
                        )}
                        Google
                      </a>
                    )}
                    buttonText="Login"
                    onSuccess={this.successResponseGoogle}
                    onFailure={this.failureResponseGoogle}
                  />
                </Form.Group>
                <Form.Group className="with-placeholder">
                  <Form.Control
                    className={
                      email.length && !isEmailValid ? "input-invalid" : ""
                    }
                    type="email"
                    placeholder=" "
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="placeholder-text">Email address</span>
                </Form.Group>
                {email.length && !isEmailValid ? (
                  <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                    Email not valid!
                  </p>
                ) : null}
                <Form.Group className="with-placeholder">
                  <Form.Control
                    type="password"
                    placeholder=" "
                    Facebook
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="placeholder-text">Password</span>
                </Form.Group>
                <a
                  href="/forgot-password"
                  className="text-primary font-weight-bold d-block mb-3"
                >
                  Forgot Password?
                </a>
                <Form.Group className="mb-4">
                  <Button
                    type="submit"
                    onClick={this.handleSubmit}
                    variant="warning"
                    className="fs-18"
                    block
                  >
                    {loading ? (
                      <ContentLoader color="text-white" />
                    ) : (
                      <div>Login</div>
                    )}
                  </Button>
                </Form.Group>
                <p className="fs-16 font-source-sans font-weight-semi">
                  Dont have an Account yet?{" "}
                  <a
                    href="/create-account"
                    className="fs-20 text-underline text-primary font-weight-bold"
                  >
                    Create Account
                  </a>{" "}
                </p>
                {/* error-msg - currently excluded */}
                {/*<p className="text-danger-2 mt-4 font-weight-bold fs-14 text-center font-source-sans">The given email is not Registed yet. Please Create your account from <a href="/create-account" className="fs-14 text-underline text-primary font-weight-bold">Create Account</a> </p>*/}
              </Form>
            </div>
            <div className="display-img  order-1 order-md-2 mb-5 mb-md-0">
              <img src="/assets/images/bg-login.svg" alt="login bg" />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  onLoginUser: (data) => dispatch(login_user_action(data)),
  onGoogleLogin: (data) => dispatch(google_login_action(data)),
  onFacebookLogin: (data) => dispatch(facebook_login_action(data)),
  setClientForNotification: (data) =>
    dispatch(set_client_token_for_notification_action(data)),
});
export default connect(null, mapDispatchToProps)(Login);
