import React from "react";
import { Empty } from "antd";

export default ({ description }) => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>{description}</span>}
    />
  );
};
