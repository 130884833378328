import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Collection.scss";
import { withRouter } from "react-router";
import ContentLoader from "../../../Common/Loader/ContentLoader";
class Collection extends Component {
  constructor() {
    super();
    this.state = {
      collections: [],
      isApiloading:false
    };
  }

  componentWillMount() {
    this.setState({isApiloading:true},()=>{
    this.props.client.collection.fetchAllWithProducts().then((collections) => {
      
      const renderCollections=collections.filter((collection)=>collection.handle=='t-shirt'|| 
                                                              collection.handle=='cook-books' ||
                                                              collection.handle=='apron' || 
                                                              collection.handle=='hoodies-1' || 
                                                              collection.handle=='candles');
     
      this.setState({
        //collections: collections,
        isApiloading:false,
        collections:renderCollections
      });
    });
  })
  }

  routeHandler = (collection) => {
    // this.props.history.push("/collections");

    localStorage.setItem("currentCollectionId", collection && collection.id);
    localStorage.setItem(
      "currentCollectionTitle",
      collection && collection.title
    );
    localStorage.setItem(
      "currentCollectionHandle",
      collection && collection.handle
    );
    this.props.history.push({ pathname: "/collections", detail: collection });
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            adaptiveHeight: true,
          },
        },
      ],
    };
    return (
      <div className="collection-wrapper mt-5 mx-n1">
        <h3 className="font-weight-semi my-4 ml-2">See all collection below</h3>
        <Slider {...settings}>
          {this.state.isApiloading ?
            <div
                        style={{
                          margin: "50px auto 0",
                          textAlign: "center",
                        }}
                      >
                        <ContentLoader color="text-primary" />
                      </div>
           : this.state.collections.map((collection) => (
            <div className="collection-slide  px-2 cursor-pointer">
              <div
                className="img-box border-6"
                onClick={() => this.routeHandler(collection)}
              >
                <img
                  src={
                    collection.image
                      ? collection.image.src
                      : "/assets/images/collection-1.svg"
                  }
                  alt="slider-img-1"
                  className="border-6 img-fit w-100"
                />
              </div>
              <h4 className="title font-weight-semi mb-0 fs-24 mt-2">
                {collection.title}
              </h4>
              <h6 className="text-grey-4 font-weight-semi">
                by Chef Whitelabel, Time
              </h6>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
export default withRouter(Collection);
