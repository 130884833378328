import React, { Component } from "react";
import { Container, Row, Col, Table, Dropdown } from "react-bootstrap";
import "./ViewGroceryList.scss";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import {get_grocery_list_byid_action,
        add_item_to_grocery_list_action,
        delete_item_from_grocery_list_action,
        toggle_checkbox_action}
   from "../../../_actions/User/grocery.action";
import { connect } from "react-redux";
import swal from "sweetalert";
class ViewGroceryList extends Component {
  constructor(props){
    super(props);
    this.state={
      grocery_list_id:null,
      successMessage:"",
      listItems:[],
      itemsList:[],
      children: [
        {
          item_name:"",
          quantity:null,
          category:"",
          unit:"",
          grocery_list:null
        },
      ],
      

    }
  }
  componentDidMount = async() =>{
    let listId=this.props && this.props.location && this.props.location.pathname && this.props.location.pathname.replace('/view-grocery-list/',"");
   let res= await this.props.getGroceryListById(listId);
    this.setState({
      grocery_list_id:listId,
      children: [
        {
          item_name:"",
          quantity:null,
          category:"",
          unit:"",
          grocery_list:listId
        },
      ]
    })
 
 let changedListItems = res && res.data && res.data.grocery_items;

      let formatListItems = (listItems) => {
          let obj = {};
          listItems.forEach((listItem) => {
              if (obj[listItem.category.toUpperCase()]) {
                  obj[listItem.category.toUpperCase()] = [
                      ...obj[listItem.category.toUpperCase()],
                      { ...listItem },
                  ];
              } else {
                  obj[listItem.category.toUpperCase()] = [{ ...listItem }];
              }
          });
          return obj;
      };
          this.setState({
            listItems: changedListItems,
           itemsList: formatListItems(changedListItems),
          });  
          
  }

  routeHandler = () => {
    this.props.history.push("/add-grocery-list");
  };

  handleSubmit= async (e)=>{
  e.preventDefault();
  let isEmpty = "";
  let isValid= "";

  this.state.children.forEach((obj)=>
    !obj.item_name?isEmpty="Item Name": 
    !obj.quantity?isEmpty="Quantity":
    !obj.unit?isEmpty="Unit":
    !obj.category?isEmpty="Category":
    isEmpty=""
  )
  if(isEmpty.length>0){
    swal(`${isEmpty} is required`);
    return;
  }
 
 this.state.children.forEach((obj)=>
  isNaN(obj.quantity)==true?isValid="Please enter valid Quantity":
  (obj.quantity) <=0 ? isValid="Please enter valid Quantity":
    isValid=""  
 )

  if(isValid.length>0){
    swal(`${isValid}`);
    return;
  }

  let res1=await this.props.addItemToGroceryList(this.state.children[0])
  let res = await this.props.getGroceryListById(this.state.grocery_list_id);

  let changedListItems = res && res.data && res.data.grocery_items;

      let formatListItems = (listItems) => {
          let obj = {};
          listItems.forEach((listItem) => {
              if (obj[listItem.category.toUpperCase()]) {
                  obj[listItem.category.toUpperCase()] = [
                      ...obj[listItem.category.toUpperCase()],
                      { ...listItem },
                  ];
              } else {
                  obj[listItem.category.toUpperCase()] = [{ ...listItem }];
              }
          });
          return obj;
      };
    this.setState({
      listItems: changedListItems,
     itemsList: formatListItems(changedListItems),
    }); 

  if(res1.msg == "Successfully Submitted"){
  this.setState({
    successMessage:"Item added successfully to the list",
    
    children: [
      {
        item_name:"",
        quantity:"",
        category:"",
        unit:"",
        grocery_list:this.state.grocery_list_id
      },
    ]
  })
  }else{
    swal("Something went wrong.Please try again later!")
  } 
  }

  onDeleteItem=async(id)=>{
    
    let res1= await this.props.deleteItemFromGroceryList(id)
    let res= await this.props.getGroceryListById(this.state.grocery_list_id);
  /*if(res1.msg==""){
  this.setState({successMessage:"Item deleted successfully from the list"})
  }else{
    swal("Something went wrong.Please try again later!")
  } */
  let changedListItems = res && res.data && res.data.grocery_items;

      let formatListItems = (listItems) => {
          let obj = {};
          listItems.forEach((listItem) => {
              if (obj[listItem.category.toUpperCase()]) {
                  obj[listItem.category.toUpperCase()] = [
                      ...obj[listItem.category.toUpperCase()],
                      { ...listItem },
                  ];
              } else {
                  obj[listItem.category.toUpperCase()] = [{ ...listItem }];
              }
          });
          return obj;
      };
    this.setState({
      listItems: changedListItems,
     itemsList: formatListItems(changedListItems),
     successMessage:"Item deleted successfully from the list"
    }); 
    
  }


  appendChild = () => {
    this.setState({
      children: [
        ...this.state.children,
        {
            item_name:"",
            quantity:null,
            category:"",
            unit:"",
        },
      ],
    });
  };
  
  deleteChild = (e, index) => {
    e.stopPropagation();
    let array = [...this.state.children];
    array.splice(index, 1);
    this.setState({ children: array });
  };
  
  onFieldChange = (index) => (e) => {
    
    const array = [...this.state.children];
    const {name,value} = e.target;
    array[index] = { ...array[index], [name]: value };
  
    this.setState({ children: array, [name]: value  });
  };

handleCheck=async(item)=>{

if(item.purchased == true){
  const data = { purchased: false}
  await this.props.toggleCheckbox(item.id,data)
  let res = await this.props.getGroceryListById(this.state.grocery_list_id);
  let changedListItems = res && res.data && res.data.grocery_items;

    let formatListItems = (listItems) => {
        let obj = {};
        listItems.forEach((listItem) => {
            if (obj[listItem.category.toUpperCase()]) {
                obj[listItem.category.toUpperCase()] = [
                    ...obj[listItem.category.toUpperCase()],
                    { ...listItem },
                ];
            } else {
                obj[listItem.category.toUpperCase()] = [{ ...listItem }];
            }
        });
        return obj;
    };
    this.setState({
      listItems: changedListItems,
     itemsList: formatListItems(changedListItems),
    }); 
  return;  
}else{
  const data = { purchased: true}
  await this.props.toggleCheckbox(item.id,data)
  let res=await this.props.getGroceryListById(this.state.grocery_list_id);
  let changedListItems = res && res.data && res.data.grocery_items;

    let formatListItems = (listItems) => {
        let obj = {};
        listItems.forEach((listItem) => {
            if (obj[listItem.category.toUpperCase()]) {
                obj[listItem.category.toUpperCase()] = [
                    ...obj[listItem.category.toUpperCase()],
                    { ...listItem },
                ];
            } else {
                obj[listItem.category.toUpperCase()] = [{ ...listItem }];
            }
        });
        return obj;
    };
    this.setState({
      listItems: changedListItems,
     itemsList: formatListItems(changedListItems),
    }); 
}
}

  render() {
    
    const listDetail=this.props && this.props.listDetail && this.props.listDetail.data;
    
    const{successMessage,listItems,itemsList}=this.state
    return (
      <div className="common-dashboard grocery-creation grocery-list">
        <Container className="p-0">
            <SidebarMenu
                alertNewNotification={(payload) => this.openNewNotification(payload)}
                history={this.props.history}
            />
          <div className="main-content grocery-list-content grocery-creation-content bg-white">
            <Header />
            <div className="grocery-list-wrapper grocery-creation-wrapper">
              <a href="/grocery-lists" className="d-none d-md-flex align-items-center pt-5">
                <img
                  src="/assets/images/back-arrow.svg"
                  alt="back"
                  className="ml-n2"
                />
                <span className="text-dark fs-16 font-weight-bold ml-1">
                  Back
                </span>
              </a>
              <Row>
                <Col lg={7} xl={6} className="mb-5">
                  <div className="grocery-cards grocery-list-wrapper mb-4">
                    <h3 className="font-weight-bold font-montserrat pt-4 mb-3">
                     {listDetail? listDetail && listDetail.list_name : null }
                    </h3>
                    <div className="top-actions mb-5">
                      <div className="add-item d-flex align-items-center">
                        <div className="item-name-input  mr-2">
                          <h4 className="category-title  fs-18 mb-0">
                            Item Name
                          </h4>
                        </div>
                        <div className="right d-flex ml-auto">
                          <h4 className="fs-18 mb-0">Qty</h4>
                          <h4 className="fs-18 mb-0 ml-2">Unit</h4>
                          <h4 className="fs-18 mb-0 ml-2">Category</h4>
                        </div>
                      </div>
                     {/*  <p className="mt-2">
                        <span className="cursor-pointer text-grey-4 font-weight-bold "
                         onClick={this.appendChild}
                         >
                          + List Item
                        </span>
                      </p> */}
                      <br/>
                      {/* add item form */}
                      {/* add class "d-none" to hide form  */}
                      {this.state.children.map((element, index) => (<>
                      <form action="#" className="" key={index}>
                        <div className="add-item d-flex  justify-content-between flex-wrap flex-sm-nowrap ">
                          <div className="item-name-input mr-0 mr-sm-2">
                            <input
                              type="text"
                              placeholder="Item Name"
                              className="form-control"
                              name="item_name"
                              onChange={this.onFieldChange(index)}
                              value={this.state.children[index].item_name}
                              required
                            />
                          </div>
                          <div className="item-name-input mr-0 mr-sm-2">
                            <input
                              type="text"
                              placeholder="Qty"
                              className="form-control"
                              name="quantity"
                              onChange={this.onFieldChange(index)}
                              value={this.state.children[index].quantity}
                              required
                            />
                          </div>
                          <div className="item-name-input mr-0 mr-sm-2">
                            <input
                              type="text"
                              placeholder="Unit"
                              className="form-control"
                              name="unit"
                              onChange={this.onFieldChange(index)}
                              value={this.state.children[index].unit}
                              required
                            />
                          </div>
                          <div className="ml-sm-2 mt-2 mt-sm-0">
                            <select className="form-control"
                              name="category"
                              onChange={this.onFieldChange(index)}
                              value={this.state.children[index].category}
                              required
                            >
                              <option value="0">Select</option>
                              <option value="vegetable">Vegetable</option>
                              <option value="fruit">Fruit</option>
                              <option value="meat">Meat</option>
                              <option value="dairy">Dairy</option>
                              <option value="sauce">Sauce</option>
                            </select>
                          </div>
                          <div className="action mt-2 mt-sm-0" onClick={this.handleSubmit}>
                            <button className="btn btn-primary h-100 btn-block py-1 px-3 ml-sm-2 rounded ">
                              Add
                            </button>
                          </div>

                          &nbsp;&nbsp;&nbsp;
                          {index > 0 && (
                        <div className="remove-item">
                          <a
                            onClick={(e) => this.deleteChild(e, index)}
                            className="text-red"
                          >
                           <span class="fa fa-minus-circle"></span>
                          </a>
                        </div>
                      )}
                        </div>
                      </form><br/>
</>))}
    {successMessage.length ? (
              <p className="fs-14 text-success-2 font-source-sans text-left font-weight-semi">
                {successMessage}
              </p>
            ) : (
              ""
            )}
</div>

{/*****************code for item listing starts*****************/}


{listItems.length ? (
 Object.keys(itemsList).map((key) => {
 return itemsList[key].length ? (<>
<div className="category mb-4"> 
<h4 className="category-title text-grey-4 fs-18 mb-3">
                         {key}
                       </h4>
{itemsList[key].map((item, i) => (<>
              <div className="item d-flex align-items-center" >
                      <h5 
                      className= 
                      {item.purchased == true ? "text-deleted text-danger font-weight-semi":
                      "category-title"
                      }
                      >
                      {item.item_name}{' '}
                      </h5>
                      
                        <div className="right pl-2 ml-auto d-flex align-items-center">
                          <p className="qty text-grey-4 mb-0  mr-5">{item.quantity}{' '}{item.unit}</p>
                          <label
                            htmlFor={item.id}
                            className="custom-control custom-checkbox "
                            onChange={()=>this.handleCheck(item)}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={item.id}
                              name=""
                              checked={item.purchased == true ? true:false}
                            />
                            <span
                              className="custom-control-label cursor-pointer"
                              htmlFor={item.id}
                            ></span>
                          </label>
                          <span className="cursor-pointer fa fa-minus-circle fs-18 ml-4" 
                           onClick={()=>this.onDeleteItem(item.id)}></span>
                        </div>
                      </div>    
                     </> ))}
          </div>
          </>)
          :null})):null}
{/********************code for item listing ends***********************/}
                                    
                  </div>
                </Col>
              </Row>
              <button
                className="fs-14 btn-add-grocery-list d-flex align-items-center btn btn-primary px-4 py-1 cursor-pointer position-fixed"
                onClick={this.routeHandler}
              >
                <span className="fa fa-plus-circle mr-2"></span>New list
              </button>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
   listDetail: state.get_grocery_list_reducer.data,
    
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGroceryListById: (id) => dispatch(get_grocery_list_byid_action(id)),
  addItemToGroceryList:(data) => dispatch(add_item_to_grocery_list_action(data)),
  deleteItemFromGroceryList:(id) => dispatch(delete_item_from_grocery_list_action(id)),
  toggleCheckbox:(id,data)=> dispatch(toggle_checkbox_action(id,data)),
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewGroceryList);

