import React from "react";
import { Container, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { notification } from "antd";
import ReactHtmlparser from "react-html-parser";
import './HomePage.scss';

import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import NewEpisodes from "../common/NewEpisodes/NewEpisodes";
import PopularVideos from "./PopularVideos/PopularVideos";
import PopularShows from "./PopularShows/PopularShows";
import PopularRecipes from "./PopularRecipes/PopularRecipes";
import TopNotification from "../common/TopNotification/TopNotification";
import {
    get_carousel_content_action,
    header_search_action,
} from "../../../_actions/User";
import Loader from "../../Common/Loader/FullPageLoader";
import {get_announcement_table_list_action} from "../../../_actions/Admin";
import ContentLoader from "../../Common/Loader/ContentLoader";


class HomePage extends React.Component {

    state = {
        loading: false,
        recipeList: null,
        videoList: null,
        showList: null,
        newAddition: null,
        updatingAnnouncements: false,
        topNotifications: [],
        searchResultsData: {},
        searchKeyword: "",
    }

    componentDidMount() {
        this.setContents()
        this.mountOrUpdateAnnouncements()
    }

    setContents = async () => {
        this.setState({ loading: true })

        const carouselContentRes = await this.props.getCarouselContent()

        let PopularShows = [];
        let LatestUpdated = [];
        let PopularVideos = [];
        let PopularRecipes = [];
        if(carouselContentRes &&
            carouselContentRes.data &&
            carouselContentRes.data.length
        ) {
            carouselContentRes.data.forEach((contentItem) => {
                if (contentItem['row_type'] === "youtube_recipe") {
                    PopularRecipes = [...PopularRecipes, { ...contentItem }];
                }
                if (contentItem['row_type'] === "video") {
                    PopularVideos = [...PopularVideos, { ...contentItem }];
                }
                if (contentItem['row_type'] === "show") {
                    PopularShows = [...PopularShows, { ...contentItem }];
                }
                if (contentItem['row_type'] === "update") {
                    LatestUpdated = [...LatestUpdated, { ...contentItem }];
                }
            });
        }
        this.setState({
            recipeList: PopularRecipes.length ?
                PopularRecipes.sort((a, b) => a.priority - b.priority)
                : [],
            videoList: PopularVideos.length ?
                PopularVideos.sort((a, b) => a.priority - b.priority)
                : [],
            newAddition: LatestUpdated.length ?
                LatestUpdated.sort((a, b) => a.priority - b.priority)
                : [],
            showList: PopularShows.length ?
                PopularShows.sort((a, b) => a.priority - b.priority)
                : []
        })

        this.setState({ loading: false })
    }

    // search handler ===========
    getSearchKeyword = async (e) => {
        let searchText = e.target.value;
        this.setState({ searchKeyword: searchText });
    };
    searchHandler = async (e) => {
        e.preventDefault();
        let { searchKeyword } = this.state;

        let searchKeywordText = { search: searchKeyword };
        let searchResult = await this.props.searchResultData(searchKeywordText);

        let searchResultData = searchResult && searchResult.data;

        if (searchResultData) {
            this.props.history.push({
                pathname: "/search-results",
                state: {
                    searchResultsData: searchResultData,
                },
            });
            return;
        }
    };

    mountOrUpdateAnnouncements = async () => {
        await this.setState({ updatingAnnouncements: true, topNotifications: [] })

        // re-fetch announcement details and show it again
        const getAnnouncementDetailsRes = await this.props.getAnnouncementData("user")
        if (
            getAnnouncementDetailsRes &&
            getAnnouncementDetailsRes.type &&
            getAnnouncementDetailsRes.type === "user"
        ) {
            if (
                getAnnouncementDetailsRes &&
                getAnnouncementDetailsRes.response &&
                getAnnouncementDetailsRes.response.data
            ) {
                this.setState({ topNotifications: getAnnouncementDetailsRes.response.data })
            }
        }

        this.setState({ updatingAnnouncements: false })

    }

    openNewNotification = (payload) => {
        notification['info']({
            message: ReactHtmlparser(payload.body),
            description: ReactHtmlparser(payload.title)
        })
        this.mountOrUpdateAnnouncements()
    }

    render() {
        
        return (
            <div className="common-dashboard user-home-page-dashboard">
                <Container className="p-0">
                    <SidebarMenu
                        alertNewNotification={(payload) => this.openNewNotification(payload)}
                        history={this.props.history}
                    />
                    <div className="main-content  bg-white position-relative">
                        <Header />

                        {/* search handler */}
                        <div className="top-search-wrapper flex-grow-1 mb-4 mr-0 mr-sm-2 mr-md-5">
                            <Form onSubmit={(e) => this.searchHandler(e)}>
                                <Form.Group className="mb-0 h-100">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search"
                                        className="search-bar bg-grey-4 border-6 pl-4"
                                        onChange={(e) => this.getSearchKeyword(e)}
                                    />
                                </Form.Group>
                            </Form>
                        </div>

                        {
                            this.state.loading ?
                                <Loader color='text-warning' />
                                : <>
                                    {
                                        this.state.topNotifications.length > 0 ?
                                            <TopNotification
                                                updatingAnnouncements={this.state.updatingAnnouncements}
                                                notifications={this.state.topNotifications.length > 0 ? this.state.topNotifications : []}
                                            />
                                            : this.state.updatingAnnouncements ?
                                            <div style={{ margin: "50px auto 0", textAlign: 'center' }}
                                            >
                                                <ContentLoader color='text-warning'/>
                                            </div>
                                            : <p className='text-info'>No notifications to show!</p>
                                    }
                                    <NewEpisodes
                                        loading={this.state.loading}
                                        newVideos={this.state.newAddition}
                                        history={this.props.history}
                                    />
                                    <PopularVideos
                                        loading={this.state.loading}
                                        videoList={this.state.videoList}
                                        history={this.props.history}
                                    />
                                    <PopularShows
                                        loading={this.state.loading}
                                        history={this.props.history}
                                        showList={this.state.showList}
                                    />
                                    <PopularRecipes
                                        loading={this.state.loading}
                                        recipeList={this.state.recipeList}
                                        history={this.props.history}
                                    />
                                </>
                        }
                    </div>
                </Container>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    getCarouselContent: () => dispatch(get_carousel_content_action()),
    getAnnouncementData: (userType) => dispatch(get_announcement_table_list_action(userType)),
    searchResultData: (data) => dispatch(header_search_action(data)),
})

export default connect(null, mapDispatchToProps)(HomePage);
