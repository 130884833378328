export const recipeConstants = {
    GET_RECIPE_CONTENT_ACTION_REQUEST: 'GET_RECIPE_CONTENT_ACTION_REQUEST',
    GET_RECIPE_CONTENT_ACTION_SUCCESS: 'GET_RECIPE_CONTENT_ACTION_SUCCESS',
    GET_RECIPE_CONTENT_ACTION_FAILURE: 'GET_RECIPE_CONTENT_ACTION_FAILURE',
    
    GET_RECIPE_BY_ID_ACTION_REQUEST: 'GET_RECIPE_BY_ID_ACTION_REQUEST',
    GET_RECIPE_BY_ID_ACTION_SUCCESS: 'GET_RECIPE_BY_ID_ACTION_SUCCESS',
    GET_RECIPE_BY_ID_ACTION_FAILURE: 'GET_RECIPE_BY_ID_ACTION_FAILURE',

    GET_ALL_RECIPE_ACTION_REQUEST: 'GET_ALL_RECIPE_ACTION_REQUEST',
    GET_ALL_RECIPE_ACTION_SUCCESS: 'GET_ALL_RECIPE_ACTION_SUCCESS',
    GET_ALL_RECIPE_ACTION_FAILURE: 'GET_ALL_RECIPE_ACTION_FAILURE'
}
