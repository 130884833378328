import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import "./SearchBar.scss";
class SearchBar extends Component {
    render() {
        return (
            <div className="admin-top-search-wrapper flex-grow-1 mr-5">
                <Form.Group className="mb-0 h-100">
                    <Form.Control type="text" placeholder="Search" className="search-bar bg-grey-4 border-6 pl-4 h-100"></Form.Control>
                </Form.Group>
            </div>
        )
    }
}
export default SearchBar;