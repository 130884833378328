import React, { Component } from "react";
import {
  Container,
  FormControl,
  Table,
  FormGroup,
  Dropdown,
  Pagination,
} from "react-bootstrap";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import "../UserData/UserData.scss";

import {
  get_csv_data_action,
  get_next_user_details_action,
  get_prev_user_details_action,
  get_user_details_action,
} from "../../../../_actions/Admin";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import TopTab from "../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../common/SidebarMenu/AdminSidebarMenu";
import './UserData.scss';
class UserData extends Component {
  state = {
    userData: null,
    entriesPerPage: 10,
    currentPageNumber: 1,
    maxPages: 1,
    selectAll: false,
    deselectAll: false,
    prevDataLink: null,
    nextDataLink: null,
    searchString: "",
    userArray: [],
    checkedUsersIds: [],
    loadingUserData: false,
    exportLoader: false,
    usersSortOrder: "",
  };

  componentDidMount() {
    this.setUserData();
  }

  setUserData = async () => {
    this.setState({ loadingUserData: true });
    // get all user details and set data, prev and next link in state
    const userDetailsRes = await this.props.getUserDetails();
    if (userDetailsRes) {
      this.setState(
        {
          userData: userDetailsRes.results,
          userArray: userDetailsRes.results,
          nextDataLink: userDetailsRes.next,
          prevDataLink: userDetailsRes.previous,
        },
        () => {
          this.setMaxPagesValue();
        }
      );
    }
    this.setState({ loadingUserData: false });
  };

  setMaxPagesValue = () => {
    // calculate max number of pages required to accommodate all the user details
    const { entriesPerPage, userData } = this.state;
    const remainder = userData.length % entriesPerPage;
    const quotient = userData.length / entriesPerPage;
    let maxPagesNumber =
      remainder > 0 ? parseInt((quotient + 1).toString(), 10) : quotient;
    this.setState({ maxPages: maxPagesNumber }, () => {
      // manage current page number if it exceeds the max page capacity.
      const { currentPageNumber, maxPages } = this.state;
      if (currentPageNumber > maxPages)
        this.setState({ currentPageNumber: this.state.maxPages });
      if (currentPageNumber < 1) this.setState({ currentPageNumber: 1 });
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: parseInt(value) }, () => this.setMaxPagesValue());
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({ searchString: e.target.value }, () => {
      this.searchUsers();
    });
  };

  searchUsers = () => {
    const { searchString, userData } = this.state;
    const filterBy = (stringPassed) => {
      const lowerCaseStringValue = stringPassed.toLowerCase();
      return (user) =>
        Object.keys(user) &&
        Object.keys(user).some(() => {
          return (
            user.first_name.toLowerCase().indexOf(lowerCaseStringValue) !==
              -1 ||
            user.last_name.toLowerCase().indexOf(lowerCaseStringValue) !== -1 ||
            user.email.toLowerCase().indexOf(lowerCaseStringValue) !== -1
          );
        });
    };
    const searchedUsers = userData.filter(filterBy(searchString));
    this.setState({ userArray: searchedUsers });
  };

  sortUsers = (type) => {
    const { userData } = this.state;
    this.setState({ usersSortOrder: type });
    let sortedUserArray = userData;
    if (type === "asc")
      sortedUserArray = sortedUserArray.sort((a, b) => {
        return new Date(a["created"]) - new Date(b["created"]);
      });
    else if (type === "dec")
      sortedUserArray = sortedUserArray.sort((a, b) => {
        return new Date(b["created"]) - new Date(a["created"]);
      });
    this.setState({ userArray: sortedUserArray });
  };

  handlePageNumberChange = async (type) => {
    const {
      currentPageNumber,
      prevDataLink,
      nextDataLink,
      userData,
      maxPages,
    } = this.state;
    if (type === "decrease") {
      if (currentPageNumber > 1)
        // decrease page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
      else {
        // hit the prev data api to fetch data of users
        if (prevDataLink !== null) {
          this.setState({ loadingUserData: true });
          const prevUserDataRes = await this.props.getPrevUserDetails(
            prevDataLink
          );
          if (prevUserDataRes) {
            const prevUserDataArray = prevUserDataRes.results;
            prevUserDataArray.push(...userData);
            this.setState({
              userData: prevUserDataArray,
              prevDataLink: prevUserDataRes.previous,
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    } else if (type === "increase") {
      if (currentPageNumber < maxPages)
        // increase page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
      else {
        // hit the next data api to fetch data of users
        if (nextDataLink !== null) {
          this.setState({ loadingUserData: true });
          const nextUserDataRes = await this.props.getNextUserDetails(
            nextDataLink
          );
          if (nextUserDataRes) {
            const nextUserDataArray = userData;
            nextUserDataArray.push(...nextUserDataRes.results);
            this.setState({
              userData: nextUserDataArray,
              nextDataLink: nextUserDataRes.next,
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    }
  };

  updateCheckedUsers = (e, id) => {
    const { userData } = this.state;
    const { checked } = e.target;
    const checkedUsersIds = this.state.checkedUsersIds;

    if (checked === true) {
      const user = userData.filter((user) => user.id === id);
      checkedUsersIds.push(user[0].id);
    } else if (checked === false) {
      checkedUsersIds.filter((userId) => userId !== id);
    }
    this.setState({ checkedUsersIds });
  };

  addAllIds = () => {
    if (this.state.selectAll) {
      const ids = this.state.userData.map((user) => user["id"]);
      this.setState({ checkedUsersIds: ids });
    } else if (!this.state.selectAll) {
      this.setState({ checkedUsersIds: [] });
    }
  };

  exportCsv = async () => {
    this.setState({ exportLoader: true });
    const { checkedUsersIds, userData } = this.state;
    let data = {};
    if (checkedUsersIds.length > 0) data["users_list"] = checkedUsersIds;
    else data["users_list"] = userData.map((user) => user.id);

    const csvRes = await this.props.getCsvData(data);
    if (csvRes) {
      const csvData = new Blob([csvRes], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(csvData, "UserDetails.csv");
    }
    this.setState({ exportLoader: false });
  };
  render() {
    const {
      entriesPerPage,
      currentPageNumber,
      maxPages,
      searchString,
      userArray,
      selectAll,
      deselectAll,
      loadingUserData,
      exportLoader,
      usersSortOrder,
    } = this.state;

    let startIndex = (currentPageNumber - 1) * entriesPerPage;
    let lastIndex = startIndex + entriesPerPage;
    let users =
      userArray.length <= entriesPerPage ?
          userArray.slice(startIndex, entriesPerPage)
          : userArray.slice(startIndex, lastIndex);

    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu />
          <TopTab activeTab="User Data"
          tabCategoryName="userDataReports" />
          <div className="main-content  bg-white">
            <div className="user-data bg-grey-6 pt-md-5 mt-5">
              <Container className="p-0">
                <div className="user-data-wrapper bg-white">
                  <h1 className="mb-5">User Information</h1>

                  {/* search and filters ========== */}
                  <div className="search-and-filter d-flex mb-3">
                    <FormGroup className="mb-0 search-bar flex-grow-1">
                      <FormControl
                        onChange={this.handleSearch}
                        value={searchString}
                        placeholder="Search"
                        className="text-grey-3 h-100"
                      />
                    </FormGroup>
                    <div className="search-filter ml-2">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="filter-toggler d-flex align-items-center justify-content-between bg-grey-3"
                        >
                          filter{" "}
                          <img
                            src={"/assets/images/dropdown.svg"}
                            alt="dropdown"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="font-smallest font-weight-bold bg-grey border-0 p-4 h-auto rounded">
                          <ul>
                            <li
                              className={
                                usersSortOrder === "asc"
                                  ? "cursor-pointer mb-2 text-primary text-nowrap"
                                  : "cursor-pointer mb-2"
                              }
                              onClick={() => this.sortUsers("asc")}
                            >
                              Sort by Joining date (Ascending)
                            </li>
                            <li
                              className={
                                usersSortOrder === "dec"
                                  ? "cursor-pointer mb-2 text-primary text-nowrap"
                                  : "cursor-pointer mb-2 text-nowrap"
                              }
                              onClick={() => this.sortUsers("dec")}
                            >
                              Sort by Joining date (Descending)
                            </li>
                            <li className="d-flex mb-2">
                              <p>Filter by Plan :</p>
                              <ul className="ml-3">
                                <li className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="planMonthly"
                                    name=""
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="planMonthly"
                                  >
                                    Monthly
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="planAnually"
                                    name=""
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="planAnually"
                                  >
                                    Anually
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="planSingle"
                                    name=""
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="planSingle"
                                  >
                                    Single
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="noPlan"
                                    name=""
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="noPlan"
                                  >
                                    No Plan
                                  </label>
                                </li>
                              </ul>
                            </li>
                            <li className="d-flex mb-2">
                              <p className="text-nowrap">Filter by Status :</p>
                              <ul className="ml-3">
                                <li className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="statusActive"
                                    name="row-checkbox"
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="statusActive"
                                  >
                                    Active
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="statusInActive"
                                    name="row-checkbox"
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="statusInActive"
                                  >
                                    In-active
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  {/* search and filters ========== */}

                  {/* data export */}
                  <ul className="data-exports d-flex flex-wrap mb-4">
                    <li>
                      <div className="custom-control custom-radio info">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="SelectAll"
                          name="selectAll"
                          defaultChecked={false}
                          checked={selectAll}
                          onClick={() =>
                            this.setState(
                              {
                                selectAll: !this.state.selectAll,
                                deselectAll: false,
                              },
                              () => this.addAllIds()
                            )
                          }
                        />
                        <label
                          className="custom-control-label ml-2 font-montserrat font-weight-400 text-grey-2 fs-14"
                          htmlFor="SelectAll"
                        >
                          Select all
                        </label>
                      </div>
                    </li>
                    <li className="mr-2">
                      <div className="custom-control custom-radio info ml-3">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="DeselectAll"
                          name="deselectAll"
                          defaultChecked={false}
                          checked={deselectAll}
                          onClick={() =>
                            this.setState({
                              deselectAll: !this.state.deselectAll,
                              selectAll: false,
                              checkedUsersIds: [],
                            })
                          }
                        />
                        <label
                          className="custom-control-label ml-2 font-montserrat font-weight-400 text-grey-2 fs-14"
                          htmlFor="DeselectAll"
                        >
                          Deselect All
                        </label>
                      </div>
                    </li>
                    <li className="ml-auto export">
                      <button
                        className="btn btn-info Export-btn py-2 rounded"
                        onClick={() => this.exportCsv()}
                      >
                        {!exportLoader ? (
                          "Export to CSV"
                        ) : (
                          <ContentLoader color="text-white" />
                        )}
                      </button>
                    </li>
                  </ul>
                  {/* data export */}

                  <div className="count-filters-and-pagination d-flex justify-content-end mb-4">
                    <div className="count-filter d-flex align-items-center">
                      <p className="font-smallest opacity-half font-weight-semi mr-2 mb-0">
                        Entries per page :
                      </p>
                      <select
                        id="#"
                        className="border"
                        name="entriesPerPage"
                        value={entriesPerPage}
                        onChange={this.handleChange}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                      </select>
                    </div>
                    <Pagination className="table-pagination ml-2">
                      <Pagination.Prev
                        className="prev"
                        onClick={() => this.handlePageNumberChange("decrease")}
                      />
                      <Pagination.Item>{currentPageNumber}</Pagination.Item>
                      <Pagination.Item className="last-item">
                        {maxPages}
                      </Pagination.Item>
                      <Pagination.Next
                        className="next"
                        onClick={() => this.handlePageNumberChange("increase")}
                      />
                    </Pagination>
                  </div>

                  <div
                    style={{ minHeight: "400px" }}
                    className="table-responsive position-relative"
                  >
                    <Table borderless className="text-black">
                      <thead>
                        <tr className="text-nowrap">
                          <th> </th>
                          <th>#</th>
                          <th>Name</th>
                          <th>E-mail</th>
                          <th>Joining Date</th>
                          <th>Plan</th>
                          <th>Subscription Status</th>
                          <th>Source of joining</th>
                          <th>App information</th>
                        </tr>
                      </thead>
                      <tbody className="font-smallest position-relative">
                        {loadingUserData ? (
                          <div
                            className="position-absolute"
                            style={{
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <div
                              className="spinner-border position-absolute text-warning"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          users !== null &&
                          users.map((user) => (
                            <tr key={user.id}>
                              <td className="">
                                <div className="custom-control custom-checkbox dark">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    onChange={(e) =>
                                      this.updateCheckedUsers(e, user.id)
                                    }
                                    id={user.id}
                                    name="row-checkbox1"
                                    checked={
                                      (selectAll && !deselectAll) ||
                                      (!deselectAll && null)
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={user.id}
                                  />
                                </div>
                              </td>
                              <td>{user.id}</td>
                              <td>{`${user.first_name} ${user.last_name}`}</td>
                              <td>{user.email}</td>
                              <td>{user["created"].substring(0, 10)}</td>
                              <td>
                                <span className="font-weight-bold">
                                  {user["plan"]
                                    ? user["plan"]
                                    : "Not Available"}
                                </span>
                              </td>
                              <td>
                                <span className="text-success-3">
                                  {user["subscription"]
                                    ? user["subscription"]
                                    : "Not Available"}
                                </span>
                              </td>
                              <td className="font-weight-bold">
                                {user["provider"]}
                              </td>
                              <td className="font-weight-bold">
                                {user["app_info"]
                                  ? user["app_info"]
                                  : "Not Available"}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: () => dispatch(get_user_details_action()),
  getPrevUserDetails: (data) => dispatch(get_prev_user_details_action(data)),
  getNextUserDetails: (data) => dispatch(get_next_user_details_action(data)),
  getCsvData: (data) => dispatch(get_csv_data_action(data)),
});

export default connect(null, mapDispatchToProps)(UserData);
