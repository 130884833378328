import React, { Component } from "react";
import { connect } from "react-redux";

import { logout_user_action } from "../../../../_actions/Common";
import ContentLoader from "../../../Common/Loader/ContentLoader";

class AdminSidebarMenu extends Component {
  state = {
    loading: false,
  };
  handleLogout = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token) {
      this.setState({ loading: true });

      const token = {
        refresh: user["refresh_token"],
      };
      await this.props.userLogout(token);
      this.setState({ loading: false }, () => window.location.reload());
      return;
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <div className="sidebar-menu bg-grey-4 position-fixed  h-100">
        <ul className="d-flex flex-md-column align-items-center py-md-5 justify-content-between justify-content-md-start w-100">
          <li>
            <a href={"/admin-home-page"}>
              <img src={"/assets/images/data-icon.svg"} alt="home" />
            </a>
          </li>
          <li>
            <a href={"/carousel-management"}>
              <img src={"/assets/images/content-icon.svg"} alt="plus icon" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={"/assets/images/store-icon-2.svg"} alt="group icon" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={"/assets/images/video-icon.svg"} alt="notification" />
            </a>
          </li>
          <li>
            <a href="#" onClick={this.handleLogout}>
              {loading ? (
                <ContentLoader color="text-black" />
              ) : (
                <img src={"/assets/images/power.png"} alt="logout" />
              )}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userLogout: (data) => dispatch(logout_user_action(data)),
});

export default connect(null, mapDispatchToProps)(AdminSidebarMenu);
