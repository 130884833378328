import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from 'react-redux'

import UploadShowContent from "./UploadShowContent/UploadShowContent";
import UploadVideoContent from "./UploadVideoContent/UploadVideoContent";
import UploadRecipeContent from "./UploadRecipeContent/UploadRecipeContent";
import "./UploadNewContent.scss";
import TopTab from "../../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../../common/SidebarMenu/AdminSidebarMenu";
import {get_saved_recipe_changes_action, get_saved_video_changes_action} from "../../../../../_actions/Admin";
import ContentLoader from "../../../../Common/Loader/ContentLoader";

class UploadNewContent extends Component {
  state = {
    activeContentType: "",
    recipeDetails: null,
    videoDetails: null,
    recipeLoading: false,
    videoLoading: false
  };

  componentDidMount() {
    const { contentType } = this.props.match.params
    this.setState({ activeContentType: this.props.match.params['contentType'] })
    if (contentType === "recipe")
      this.loadRecipeDetails()
    else if (contentType === 'video')
      this.loadVideoDetails()
  }

  loadRecipeDetails = async () => {
    this.setState({ recipeLoading: true })

    const loadRecipeDetailsRes = await this.props.getRecipeDetails(this.props.match.params['contentId'])
    if (loadRecipeDetailsRes)
      await this.setState({ recipeDetails: loadRecipeDetailsRes.data })
    this.setState({ recipeLoading: false })
  }

  loadVideoDetails = async () => {
    this.setState({ videoLoading: true })
    const loadVideoDetailsRes = await this.props.getVideoDetails(this.props.match.params['contentId'])

    if (loadVideoDetailsRes)
      await this.setState({ videoDetails: loadVideoDetailsRes.data })

    this.setState({ videoLoading: false })
  }

  handleChangeActiveContentType = (activeContentType) => {
    this.setState({ activeContentType });
  };

  render() {
    const { handleContentIDForRecipe, handleActiveContentChange } = this.props;
    const { activeContentType, recipeLoading, videoLoading, videoDetails, recipeDetails } = this.state;
    const { contentType } = this.props.match.params

    return (
        <div className="content-management admin-home-dashboard common-dashboard">
          <Container className="p-0">
            <AdminSidebarMenu />
            <TopTab
                activeTab="Upload Content"
                tabCategoryName="contentManagment"
            />
            <div className="main-content  bg-white">
              <div className="upload-new-content bg-grey-6">
                <Container className="px-0 mt-4">
                  <Row>
                    <Col sm={3}>
                    <span
                        onClick={() => this.props.history.push('/upload-content-listing')}
                        className="btn-back  cursor-pointer font-weight-bold d-flex align-items-center"
                    >
                      <img src={"/assets/images/back-arrow.svg"} alt="" /> Back
                    </span>
                    </Col>
                    <Col lg={6} md={9}>
                      <div className="upload-new-content-wrapper">
                        <h2 className="font-weight-semi">Upload New Content</h2>
                        <p className="font-poppins font-weight-semi fs-14 mt-3">
                          Click on “Save Changes” periodically to keep saving your
                          data
                        </p>

                        {/*========== selection post type =======  */}
                        <ul className="d-flex bg-grey-4 p-3 fs-16 font-weight-semi mt-3">
                          <li>Type:</li>
                          <li className="ml-3">
                            <div className="custom-control custom-radio info">
                              <input
                                  onClick={() => this.handleChangeActiveContentType("show")}
                                  type="radio"
                                  className="custom-control-input"
                                  id="Show"
                                  name="show"
                                  checked={activeContentType === "show" || activeContentType === 'new-content'}
                                  disabled={contentType === 'recipe' || contentType === 'video'}
                              />
                              <label
                                  className="custom-control-label ml-2  text-grey-4"
                                  htmlFor="Show"
                              >
                                Show
                              </label>
                            </div>
                          </li>
                          <li className="ml-3">
                            <div className="custom-control custom-radio info">
                              <input
                                  onClick={() => this.handleChangeActiveContentType("video")}
                                  type="radio"
                                  className="custom-control-input"
                                  id="Video"
                                  name="show"
                                  checked={activeContentType === "video"}
                                  disabled={contentType === 'recipe' || contentType === 'show'}
                              />
                              <label
                                  className="custom-control-label ml-2 text-grey-4"
                                  htmlFor="Video"
                              >
                                Video
                              </label>
                            </div>
                          </li>
                          <li className="ml-3">
                            <div className="custom-control custom-radio info">
                              <input
                                  onClick={() => this.handleChangeActiveContentType("recipe")}
                                  type="radio"
                                  className="custom-control-input"
                                  id="Recipe"
                                  name="show"
                                  checked={activeContentType === "recipe"}
                                  disabled={contentType === 'video' || contentType === 'show'}
                              />
                              <label
                                  className="custom-control-label ml-2 font-montserrat text-grey-4"
                                  htmlFor="Recipe"
                              >
                                Recipe
                              </label>
                            </div>
                          </li>
                        </ul>
                        {/*========== selection post type =======  */}
                        <div className="content mt-3">
                          {/* remove/add 'd-none' className to show/hide relevant form  */}
                          <div
                              className={`selected-form ${activeContentType === "show" || activeContentType === 'new-content' ? "" : "d-none"}`}
                          >
                            <UploadShowContent
                                history={this.props.history}
                            />
                          </div>
                          {
                            videoLoading ?
                                <ContentLoader color="text-warning" />
                                : <div
                                    className={`selected-form ${activeContentType !== "video" ? "d-none" : ""}`}
                                >
                                  <UploadVideoContent
                                      history={this.props.history}
                                      videoDetails={videoDetails}
                                  />
                                </div>
                          }

                          {
                            recipeLoading ?
                                <ContentLoader color="text-warning" />
                                : <div
                                    className={`selected-form ${activeContentType !== "recipe" ? "d-none" : ""}`}
                                >
                                  <UploadRecipeContent
                                      history={this.props.history}
                                      contentId={this.props.contentId}
                                      recipeDetails={recipeDetails}
                                  />
                                </div>
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Container>
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getRecipeDetails: (data) => dispatch(get_saved_recipe_changes_action(data)),
  getVideoDetails: (data) => dispatch(get_saved_video_changes_action(data))
})

export default connect(null, mapDispatchToProps)(UploadNewContent);
