import {recipeConstants} from "../../_constants/User";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

// get receipe content from backend
export const get_recipe_content_action = () => async (dispatch) => {
    dispatch({
        type: recipeConstants.GET_RECIPE_CONTENT_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userRecipe.getRecipeContent)
        if (response.status) {
            dispatch ({
                type: recipeConstants.GET_RECIPE_CONTENT_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: recipeConstants.GET_RECIPE_CONTENT_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_recipe_by_id_action = (id) => async (dispatch) => {
    dispatch({
        type: recipeConstants.GET_RECIPE_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userRecipe.getRecipeById.replace(":id", id))
        if (response.status) {
            dispatch ({
                type: recipeConstants.GET_RECIPE_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: recipeConstants.GET_RECIPE_BY_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_all_user_recipes_action = () => async (dispatch) => {
    dispatch({
        type: recipeConstants.GET_ALL_RECIPE_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userRecipe.getAllRecipe)
        if (response.status) {
            dispatch ({
                type: recipeConstants.GET_ALL_RECIPE_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: recipeConstants.GET_ALL_RECIPE_ACTION_FAILURE,
            payload: e.msg
        })
    }
}