import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommentsRecipeTabs from "../common/CommentsRecipeTabs/CommentsRecipeTabs";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import SuggestedRecipes from "../common/SuggestedRecipes/SuggestedRecipes";
import "./ViewVideo.scss";
import { connect } from "react-redux";
import { get_video_by_id_action } from "../../../_actions/User";
import VideoPlayer from "../common/VideoPlayer/VideoPlayer";
import ContentLoader from "../../Common/Loader/ContentLoader";
//import SubscriptionPopup from '../../common/SubscriptionPopup/SubscriptionPopup';
import SubscriptionPopup from '../../User/common/SubscriptionPopup/SubscriptionPopup';
import {add_bookmark_to_post_action, hit_like_action} from "../../../_actions/User/post.action";

class ViewVideo extends Component {
  state = {
    loading: false,
    content: [],
    videoId: "",
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.getVideoContentData !== this.props.getVideoContentData) {
      this.setState({
        content: nextProps.getVideoContentData,
      });
    }
  };

  componentDidMount = () => { this.loadVideoDetails() };

  loadVideoDetails = async () => {
    await this.setState({ loading: true })

    window.scrollTo(0, 0);

    if (
        this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
    ) {
      await this.setState({ videoId: this.props.match.params.id }, async () => {
        await this.props.getVideoContent(this.state.videoId);
      });
    }

    await this.setState({ loading: false })
  }


  likeVideo = async (id) => {
    if (!id) return
    const data = {
      content_type: "video",
      id
    }
    await this.props.hitLike(data)
    await this.props.getVideoContent(id)
  }

  bookmarkVideo = async (id) => {
    if (!id) return
    const data = {
      content_type: "video",
      id
    }
    await this.props.addBookmarkAction(data)
    await this.props.getVideoContent(id)
  }

  render() {
    const { loading } = this.state
    let getVideoData = this.state &&
        this.state.content &&
        this.state.content.data;
    let {data}=this.state && this.state.content;
        
    const userObject = JSON.parse(localStorage.getItem("user"))

    return (
        <div className="common-dashboard view-video-wapper">
          <Container className="p-0">
          <SidebarMenu />
           
                    {
                        !loading ?
                            <SubscriptionPopup details={data}/>
                            : null
                    } 

          <div className="main-content bg-white">
            <Header className="pt-2" />
            {/* video box*/}
            <Row className="video-box">
              <Col md={8} lg={9}>

                {
                  loading ?
                      <div style={{ margin: "50px auto 0", textAlign: 'center' }}
                      >
                        <ContentLoader color='text-warning'/>
                      </div>
                      : 
                      getVideoData && getVideoData.video_playback_id?
                      (<VideoPlayer playbackId={getVideoData && getVideoData.video_playback_id}></VideoPlayer>)
                      : null    
                      
                }

                <div className="episode-wrapper d-flex justify-content-between align-items-center mt-3">
                  <h3 className="mb-3 font-weight-semi">
                    {getVideoData && getVideoData.title}
                  </h3>
                  {/* episode name and user action */}
                  <div className="bottom d-flex align-items-center">
                    {/* icon list */}
                    <div className="user-action-btn">
                      {/* like icon */}
                      <a>
                        {" "}
                        {
                          getVideoData &&
                          getVideoData['likes'] &&
                          getVideoData['likes'].includes(userObject.user.id) ?
                              <span onClick={() => this.likeVideo(getVideoData && getVideoData.id)}>
                                <i className="fa fa-heart text-danger-2 mr-2 mr-md-4 fs-18" aria-hidden="true"/>
                              </span>
                              :
                              <span onClick={() => this.likeVideo(getVideoData && getVideoData.id)}>
                                <i className="fa fa-heart-o text-black mr-2 mr-md-4 fs-18" aria-hidden="true"/>
                              </span>
                        }
                      </a>
                      {/* share icon */}
                      <a href="#">
                        {" "}
                        <img
                          src={"/assets/images/share.png"}
                          alt="share"
                          className="mr-2 mr-md-4"
                        />
                      </a>
                      {/* bookmark icon */}
                      <a>
                        {" "}
                        {
                          getVideoData &&
                          getVideoData['bookmarks'] &&
                          getVideoData['bookmarks'].includes(userObject.user.id) ?
                              <span onClick={() => this.bookmarkVideo(getVideoData && getVideoData.id)}>
                                <i className="fa fa-bookmark text-warning fs-18" aria-hidden="true"/>
                              </span>
                              :
                              <span onClick={() => this.bookmarkVideo(getVideoData && getVideoData.id)}>
                                <i className="fa fa-bookmark-o text-black fs-18" aria-hidden="true"/>
                              </span>
                        }
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="video-desc mb-4 pr-md-5">
                  <p className="text-grey-2 fs-small font-weight-semi">{getVideoData && getVideoData.description}</p>
                </div> */}
                {/* <img src={getVideoData && getVideoData.title_image} alt="" /> */}
              </Col>
              <Col md={4} lg={3} className="d-none d-md-block">
                {/* suggestions list */}
                <h3 className="mb-4">Suggested Recipe</h3>
                <SuggestedRecipes />
              </Col>
            </Row>

            {
              loading && !getVideoData ?
                  <div style={{margin: "50px auto 0", textAlign: 'center'}}>
                    <ContentLoader color='text-warning'/>
                  </div>
                  : <div className="recipe-desc-wrapper">
                    {/* Recipe & comments tab*/}
                    <CommentsRecipeTabs details={getVideoData} />
                  </div>
            }
          </div>
          </Container>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getVideoContentData: state.get_video_content_reducer.data,
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVideoContent: (id) => dispatch(get_video_by_id_action(id)),
  hitLike: (data) => dispatch(hit_like_action(data)),
  addBookmarkAction: (data) => dispatch(add_bookmark_to_post_action(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewVideo);
