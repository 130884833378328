import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./NewEpisodes.scss";

class NewEpisodes extends Component {

  componentDidMount(){
   
  }
  routeHandler = (details, episode, show, video, recipe) => {
    
    if (details['content_type'] === "episode" && episode)
      this.props.history.push(`/watch-show/${episode}`)
    else if (details['content_type'] === "show" && show)
      this.props.history.push(`/view-show/${show}`)
    else if (details['content_type'] === "video" && video)
      this.props.history.push(`/video/${video}`)
    else if (details['content_type'] === "youtube-recipe" && recipe)
      this.props.history.push(`/recipe/${recipe}`)
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
          },
        },
      ],
    };

    return (
      <div className="new-episodes-slider mx-n1 ">
        <Slider {...settings}>
          {this.props.newVideos &&
            this.props.newVideos.map((vDetails) => (
              <div
                key={vDetails.id}
                className="new-episode-preview cursor-pointer px-2 position-relative"
              >
                <div className="img-box border-6"
                     onClick={() => this.routeHandler(vDetails, vDetails['episode'], vDetails['show'], vDetails['video'], vDetails['youtube_recipe'])}
                >
                  <img
                    src={vDetails['cover_image']}
                    alt="slider-img-1"
                    className="img-fit w-100"
                  />
                </div>
                {/* {vDetails.title &&
                vDetails['title_label_color'] &&
                <span
                    className="tag text-white position-absolute px-2"
                    style={{
                      background: vDetails['title_label_color'] === 'violet' ?
                          "#87449a"
                          : vDetails['title_label_color'] === 'yellow' ?
                              "#ffbc42"
                              : vDetails['title_label_color'] === 'black' ?
                                  "#343a40"
                                  : ""
                    }}
                >
                  {vDetails.title}
                  {console.log("video_details",vDetails.video_details)}
                  
                  is_premium
                </span>} */}

                {vDetails &&
                    vDetails.video_details &&
                    vDetails.video_details.is_premium ? (
                      <span className="tag text-white bg-warning d-flex align-items-center position-absolute px-2">
                        <img
                          src={"../assets/images/royal-crown.png"}
                          alt="crown"
                          className="mr-1"
                          style={{maxWidth:'15px'}}
                        />
                        Premium
                      </span>
                    ) : null}
              </div>
            ))}
        </Slider>
      </div>
    );
  }
}

export default NewEpisodes;
