import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import GroceryListCard from "./GroceryListCard/GroceryListCard";
import "./GroceryLists.scss";
import {get_grocery_list_action} from "../../../_actions/User/grocery.action";
import { connect } from "react-redux";
class GroceryLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }

  componentDidMount = async ()=>{
        await this.props.getGroceryList();
  }

  routeHandler = () => {
    this.props.history.push("/add-grocery-list");
  };
 
  render() {
    
    const groceryList = this.props && this.props.groceryList && this.props.groceryList.data
    
    return (
      <div className="common-dashboard grocery-creation">
        <Container className="p-0">
          <SidebarMenu />
          <div className="main-content grocery-creation-content bg-white">
            <Header />

            <div className="grocery-creation-wrapper">
              <h3 className="font-weight-bold font-montserrat pt-4 mb-3">
                My Grocery List
              </h3>
              <Row>
                <Col lg={9} className="mb-5">
                  <div className="grocery-cards">
                    <Row>

                    { groceryList?
                      groceryList.map((item)=>
                        (<>
                          <Col sm={6} lg={4}>
                            <GroceryListCard item={item} history={this.props.history} />
                          </Col>
                        </>)):null
                    }
                     {/*  <Col sm={6} lg={4}>
                        <GroceryListCard history={this.props.history} />
                      </Col>
                     */}

                    </Row>
                  </div>
                </Col>
              </Row>
              <button
                className="fs-14 btn-add-grocery-list d-flex align-items-center btn btn-primary px-2 py-1 cursor-pointer position-fixed"
                onClick={this.routeHandler}
              >
                <span className="fa fa-plus-circle mr-2"></span>New list
              </button>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
   groceryList: state.get_grocery_list_reducer.data,
    
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGroceryList: () => dispatch(get_grocery_list_action()),
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroceryLists);
