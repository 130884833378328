export const profileConstants = {
  GET_PROFILE_CONTENT_ACTION_REQUEST: "GET_PROFILE_CONTENT_ACTION_REQUEST",
  GET_PROFILE_CONTENT_ACTION_SUCCESS: "GET_PROFILE_CONTENT_ACTION_SUCCESS",
  GET_PROFILE_CONTENT_ACTION_FAILURE: "GET_PROFILE_CONTENT_ACTION_FAILURE",

  EDIT_USER_NAME_ACTION_REQUEST: "EDIT_USER_NAME_ACTION_REQUEST",
  EDIT_USER_NAME_ACTION_SUCCESS: "EDIT_USER_NAME_ACTION_SUCCESS",
  EDIT_USER_NAME_ACTION_FAILURE: "EDIT_USER_NAME_ACTION_FAILURE",

  EDIT_PROFILE_IMAGE_ACTION_REQUEST: "EDIT_PROFILE_IMAGE_ACTION_REQUEST",
  EDIT_PROFILE_IMAGE_ACTION_SUCCESS: "EDIT_PROFILE_IMAGE_ACTION_SUCCESS",
  EDIT_PROFILE_IMAGE_ACTION_FAILURE: "EDIT_PROFILE_IMAGE_ACTION_FAILURE",


  CHANGE_PASSWORD_ACTION_REQUEST: "CHANGE_PASSWORD_ACTION_REQUEST",
  CHANGE_PASSWORD_ACTION_SUCCESS: "CHANGE_PASSWORD_ACTION_SUCCESS",
  CHANGE_PASSWORD_ACTION_FAILURE: "CHANGE_PASSWORD_ACTION_FAILURE",
};
