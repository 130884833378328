import {carouselConstants} from "../../_constants/User";
import {uploadEpisodeConstants} from "../../_constants/Admin/uploadEpisode.constants";


const initialState = {}
export const get_carousel_content_reducer = (state = initialState, action) => {
    switch (action.type) {
        case carouselConstants.GET_CAROUSEL_CONTENT_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case carouselConstants.GET_CAROUSEL_CONTENT_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case carouselConstants.GET_CAROUSEL_CONTENT_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_user_episode_details_reducer = (state = initialState, action) => {
    switch (action.type) {
        case uploadEpisodeConstants.GET_USER_SIDE_EPISODE_DETAILS_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case uploadEpisodeConstants.GET_USER_SIDE_EPISODE_DETAILS_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case uploadEpisodeConstants.GET_USER_SIDE_EPISODE_DETAILS_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}