import React from 'react';
import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { notificationListener } from '../Config/onesignalService';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    
    useEffect(() => {
        notificationListener()
    }, [])

    const user = JSON.parse(localStorage.getItem('user'))

    return (
        <Route {...rest} render={props => (
            (user && user.user.role === 'user')
                ? <Component {...props} />
                : <Redirect to='/login'  />
        )} />
    )
}