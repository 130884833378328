import {notificationConstants} from "../../_constants/User/notification.constants";

const initialState = {}
export const get_notification_reducer = (state = initialState, action) => {
    switch (action.type) {
        case notificationConstants.GET_NOTIFICATION_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case notificationConstants.GET_NOTIFICATION_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case notificationConstants.GET_NOTIFICATION_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}