import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./UploadRecipes.scss";
import UploadCarouselSlide from "../../../../common/UploadCarouselSlide/UploadCarouselSlide";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default ({ recipes, addUpdateClickHandler }) => {
  return (
    <div className="popular-recipes-slider pt-sm-4">
      <h3 className="font-weight-semi d-inline-block pt-4 mb-3 pl-2">
        Upload Recipe
      </h3>
      <Slider {...settings} className="recipes">
        {recipes && recipes.length
          ? recipes.map((recipe) => (
              <div
                className="recipe-item"
                key={recipe.priority}
                onClick={() =>
                  addUpdateClickHandler(
                    "youtube-recipe",
                    recipe.priority,
                    recipe.id ? recipe.id : "new"
                  )
                }
              >
                <UploadCarouselSlide
                  buttonTitle="Add Recipe"
                  imgPreview={recipe.cover_image ? recipe.cover_image : null}
                />
                <h4 className="title font-weight-semi my-2 ml-2">
                  {recipe.youtube_recipe_details &&
                  recipe.youtube_recipe_details.title
                    ? recipe.youtube_recipe_details.title
                    : "Recipe Title"}
                </h4>
              </div>
            ))
          : null}
      </Slider>
    </div>
  );
};
