import swal from 'sweetalert'
import { userTableConstants } from "../../_constants";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

// GET USER DETAILS
export const get_user_details_action = () => async (dispatch) => {
    dispatch({
        type: userTableConstants.GET_USER_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.users.getUserDetails)
        if (response.status) {
            dispatch({
                type: userTableConstants.GET_USER_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: userTableConstants.GET_USER_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// GET PREVIOUS USER DETAILS
export const get_prev_user_details_action = (data) => async (dispatch) => {
    dispatch({
        type: userTableConstants.GET_PREV_USER_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(data)
        if (response.status) {
            dispatch({
                type: userTableConstants.GET_PREV_USER_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: userTableConstants.GET_PREV_USER_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// GET NEXT USER DETAILS
export const get_next_user_details_action = (data) => async (dispatch) => {
    dispatch({
        type: userTableConstants.GET_NEXT_USER_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(data)
        if (response.status) {
            dispatch({
                type: userTableConstants.GET_NEXT_USER_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: userTableConstants.GET_NEXT_USER_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// GET CSV DATA
export const get_csv_data_action = (data) => async (dispatch) => {
    dispatch({
        type: userTableConstants.GET_CSV_DATA_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.users.getCsvData, data)
        if (response) {
            dispatch({
                type: userTableConstants.GET_CSV_DATA_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: userTableConstants.GET_CSV_DATA_ACTION_FAILURE,
            payload: e.msg
        })
    }
}