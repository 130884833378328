import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PopularRecipes.scss";
class PopularRecipes extends Component {
  routeHandler = (id) => {
    this.props.history.push(`/recipe/${id}`);
  };
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div className="popular-recipes-slider pt-sm-4 mx-n1">
        <h3 className="font-weight-semi d-inline-block pt-4 mb-3 pl-2">
          Popular Recipes
        </h3>
        <a
          href={"/all-recipes"}
          className="fs-18 font-weight-semi font-poppins text-primary pl-5"
        >
          view all{" "}
          <img src={"/assets/images/next-btn.svg"} className="ml-3" alt="" />{" "}
        </a>
        {this.props && this.props.recipeList && this.props.recipeList.length ? (
          <Slider {...settings}>
            {this.props.recipeList &&
              this.props.recipeList.map((rDetails) => (
                <div
                  key={rDetails.id}
                  className="popular-recipes-preview cursor-pointer px-2"
                >
                  <div
                    className="img-box border-6"
                    onClick={() =>
                      this.routeHandler(
                        rDetails["youtube_recipe_details"]["id"]
                      )
                    }
                  >
                    <img
                      src={rDetails.cover_image}
                      alt="slider-img-1"
                      className="img-fit w-100"
                    />
                  </div>
                  <h4 className="title font-weight-semi my-2">
                    {rDetails["youtube_recipe_details"]["title"]}
                  </h4>
                </div>
              ))}
          </Slider>
        ) : (
          <div className="col-12 mt-3">
            <img src={"/assets/images/norecipes-home.png"} alt="" />
            <h4>No Popular Recipes Yet</h4>
          </div>
        )}
      </div>
    );
  }
}
export default PopularRecipes;
