import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import "./CreateAccount.scss";
import GoogleLogin from "react-google-login";
import swal from "sweetalert";
import {
  facebook_login_action,
  google_login_action,
  set_client_token_for_notification_action,
} from "../../../_actions/Common";
import { connect } from "react-redux";
import ContentLoader from "../Loader/ContentLoader";
import {
  addTagOnOneSignal,
  getOneSignalPlayerId,
} from "../../../Config/onesignalService";

class CreateAccount extends Component {
  state = {
    googleLoading: false,
    facebookLoading: false,
  };

  successResponseGoogle = async (response) => {
    const token = { access_token: response.accessToken };
    if (token) {
      this.setState({ googleLoading: true });
      const loginRes = await this.props.onGoogleLogin(token);
      if (loginRes !== undefined && loginRes.user) {
        let userRole = loginRes.user.role;
        if (userRole === "user") {
          this.setNotifications(loginRes.user)
          return;
        } else if (userRole === "admin")
          this.props.history.push("admin-home-page");
      } else {
        swal(loginRes);
      }

      this.setState({ googleLoading: false });
    }
  };
  failureResponseGoogle = (response) => {
    this.setState({ googleLoading: true });
    if (response.error !== "popup_closed_by_user")
      if (response.details.includes("Cookies are not enabled"))
        swal("Please enable cookies.");
      else swal(response.error);
    this.setState({ googleLoading: false });
  };
  responseFacebook = async (response) => {
    if (response.status !== "unknown") {
      this.setState({ facebookLoading: true });
      if (response.accessToken) {
        const token = { access_token: response.accessToken };
        const loginRes = await this.props.onFacebookLogin(token);
        if (loginRes && loginRes.user) {
          let userRole = loginRes.user.role;
          if (userRole === "user") {
            this.props.history.push("/user-home-page");
            return;
          } else if (userRole === "admin")
            this.props.history.push("admin-home-page");
        } else {
          swal("Signup failed failed! Try again");
        }
      }
      this.setState({ facebookLoading: false });
    }
  };

  setNotifications = async (user) => {
    const playerId = await getOneSignalPlayerId();
    if (playerId) {
      const data = {
        registration_id: playerId,
        type: "web",
        device_id: playerId.substr(0, 150),
        active: true,
      };
    
      await addTagOnOneSignal("userId", user.id);
      await this.props.setClientForNotification(data);
    }
    this.props.history.push("/user-home-page");
  };

  render() {
    const { googleLoading, facebookLoading } = this.state;
    return (
      <div className="common-login d-flex align-items-center jusfity-content-center">
        <Container className="p-0">
          <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">
            <div className="form-wraper d-flex justify-content-center align-items-center order-2 order-md-1 pb-5 mb-md-0">
              <Form action="#">
                <h2 className="text-dark form-title text-center font-poppins mb-4">
                  Create Account
                </h2>
                <p className="fs-16 text-dark   mb-5 text-center font-weight-bold">
                  Welcome to Chef Whitelabel ! <br /> Create Account using any
                  one of the following options{" "}
                </p>
                <Form.Group className="d-flex justify-content-between social-login-links py-3">
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_ID}
                    render={(renderProps) => (
                      <a
                        type="button"
                        onClick={renderProps.onClick}
                        className="d-flex align-items-center justify-content-center font-smallest bg-grey-3 p-2 font-weight-bold text-black rounded-pill"
                      >
                        {googleLoading ? (
                          <ContentLoader color="text-white" />
                        ) : (
                          <img
                            src={"/assets/images/google-plus-icon.svg"}
                            className="mr-2"
                            alt="Google"
                          />
                        )}
                        Google
                      </a>
                    )}
                    buttonText="Login"
                    onSuccess={this.successResponseGoogle}
                    onFailure={this.failureResponseGoogle}
                  />
                </Form.Group>

                <Form.Group className="d-flex justify-content-between social-login-links mb-3">
                  <a
                    href={"/sign-up"}
                    className="d-flex align-items-center justify-content-center fs-14 bg-grey-3 p-3 font-weight-bold text-black rounded-pill"
                  >
                    Create Account using Email
                  </a>
                </Form.Group>

                <p className="fs-14 font-Modelica text-center mt-5 pt-md-5 font-weight-bold">
                  Already have an Account?{" "}
                  <a
                    href={"/login"}
                    className="fs-20 text-underline text-primary"
                  >
                    Login
                  </a>{" "}
                </p>
              </Form>
            </div>
            <div className="display-img  order-1 order-md-2 mb-5 mb-md-0">
              <img src={"/assets/images/bg-signup.svg"} alt="login bg" />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onGoogleLogin: (data) => dispatch(google_login_action(data)),
  onFacebookLogin: (data) => dispatch(facebook_login_action(data)),
  setClientForNotification: (data) =>
    dispatch(set_client_token_for_notification_action(data)),
});

export default connect(null, mapDispatchToProps)(CreateAccount);
