import React from "react";

export default ({styles}) => {
  return (
    <div
      className="position-absolute"
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        ...styles
      }}
    >
      <div
        className="spinner-border position-absolute text-warning"
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
