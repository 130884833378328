import React, { Component } from "react";
import TopSearch from "./TopSearch/TopSearch";
import "./Header.scss";
import { get_profile_content_action } from "../../../../_actions/User";
import { connect } from "react-redux";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      backToTop: false,
    };
  }
  componentWillMount() {
    this.props.getProfileContent();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.getProfileContentData !== this.props.getProfileContentData) {
      this.setState({
        content: nextProps.getProfileContentData,
      });
    }
  };

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 250) {
      this.setState({
        backToTop: true,
      });
    } else {
      this.setState({
        backToTop: false,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    let profileData = this.state && this.state.content;
    // first letter for first name
    let firstLetterFirstName =
      profileData && profileData.first_name && profileData.first_name.charAt(0);

    // first letter for last name
    let firstLetterLastName =
      profileData && profileData.last_name && profileData.last_name.charAt(0);

      const { backToTop } = this.state;
    return (
      <div className="user-header-wrapper d-flex flex-column position-fixed">
        <div className="container px-md-0 py-2 pt-4 bg-white">
          <div className="top-strip d-flex justify-content-between align-items-center ">
            {/* mobile back button  */}
            <button className="d-md-none btn-back bg-transparent border-0 text-grey-3 fs-30 ">
              <span className="fa fa-angle-left"></span>
            </button>
            <a
              href="/user-home-page"
              className="left-part fs-30 font-poppins font-weight-bold"
            >
              {/* Chef Whitelabel */}
              <img src={"../assets/images/logo-main.png"} alt="" className="logo-img" />
            </a>
            <div className="right-part d-flex align-items-center">
              <a href="#">
                <img
                  src="/assets/images/cart.svg"
                  alt="Shopping-cart"
                  className="mr-3"
                />
              </a>
              <a href="/user-profile">
                {profileData && profileData.profile_image ? (
                  <img
                    src={profileData && profileData.profile_image}
                    alt="Profile Image"
                    className="rounded-circle user-img"
                  />
                ) : (
                  <span className="user-initials bg-primary text-white text-uppercase d-flex align-items-center justify-content-center font-weight-bold rounded-circle">
                    {firstLetterFirstName} {firstLetterLastName}
                  </span>
                )}
              </a>
            </div>
          </div>

          {/* <div className="hometop-wrapper flex-wrap flex-sm-nowrap d-flex justify-content-between pt-4 pb-1">
            <TopSearch
              searchType={this.props.searchType}
              history={this.props.history}
            />
          </div> */}
        </div>

        {backToTop && (
          <span className="back-top-btn bg-primary d-flex cursor-pointer align-items-center justify-content-center rounded-circle" onClick={() => this.scrollToTop()}>
            <span className="text-white fa fa-angle-up"></span>
          </span>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProfileContent: () => dispatch(get_profile_content_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
