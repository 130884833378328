import React, { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./RecipeIngredients.scss";
//import {get_grocery_list_action} from "../../../_actions/User/grocery.action";
import {
  get_grocery_list_action,
  add_grocery_list_action,
  add_grocery_item_to_list_action,
} from "../../../../../../_actions/User/grocery.action";
import { connect } from "react-redux";
import swal from "sweetalert";

class RecipeIngredients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_name: "",
      showAddListForm: "",
      itemsList: [],
      showRight: [],
    };
  }

  componentDidMount = async () => {
    await this.props.getGroceryList();
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  createGroceryList = async () => {
    if (!this.state.list_name) {
      swal("List Name is required!");
      return;
    }
    const addGroceryList = { list_name: this.state.list_name };

    const res = await this.props.addGroceryList(addGroceryList);
    if (res.msg == "Successfully Submitted") {
      this.setState({
        list_name: "",
        showAddListForm: !this.state.showAddListForm,
      });
    } else {
      swal("Something went wrong.Try again later!");
    }
    await await this.props.getGroceryList();
  };

  handleNewList = () => {
    this.setState({ showAddListForm: !this.state.showAddListForm });
  };

  addGroceryItemToList = async (list, ingredients) => {
    let formatListItems = (ingredients) => {
      //following line checks if ingredients is type of array or object if it is an array then it returns true
      let res = Object.prototype.toString.call(ingredients) == "[object Array]";

      let obj = {};
      let ans = [];
      if (res == true) {
        ingredients.forEach((item) => {
          if (item) {
            obj = {
              item_name: item.name,
              quantity: item.quantity,
              unit: item.unit,
              category: item.category,
            };

            ans.push(obj);
          }
        });
      } else {
        obj = {
          item_name: ingredients.name,
          quantity: ingredients.quantity,
          unit: ingredients.unit,
          category: ingredients.category,
        };
        ans.push(obj);
      }
      return ans;
    };
    this.setState(
      {
        itemsList: formatListItems(ingredients),
      },
      async () => {
        let data = {
          list_name: list && list.list_name,
          add_grocery_items: this.state.itemsList,
        };

        let res = await this.props.addGroceryItemToList(list && list.id, data);
        if (res && res.msg == "Successfully Updated") {
          let id = list && list.id;
          this.setState({ showRight: [...this.state.showRight, id] });
        } else {
          swal("Something went wrong,please try again later!...");
        }
      }
    );
  };

  GroceryListAction = (ingredients) => {
    //this.setState({showRight:[]},()=>{
    let groceryList =
      this.props && this.props.groceryList && this.props.groceryList.data;

    return (
      <Dropdown.Menu>
        <div className="py-3 px-4" style={{ minWidth: "300px" }}>
          <h5 className="text-primary py-2">Select Grocery List</h5>
          {this.state.showAddListForm ? (
            <>
              <form className="">
                <div className="add-item d-flex  justify-content-between flex-wrap flex-sm-nowrap ">
                  <div className="item-name-input mr-0 flex-grow-1">
                    <input
                      type="text"
                      placeholder="List Name"
                      className="form-control"
                      name="list_name"
                      onChange={this.handleChange}
                      value={this.state.list_name}
                    />
                  </div>
                </div>{" "}
              </form>
              <div className="add-item d-flex  my-3  flex-wrap flex-sm-nowrap ">
                <div
                  className="action mt-2 mt-sm-0"
                  onClick={() => {
                    this.createGroceryList();
                  }}
                >
                  <button className="btn btn-primary h-100 btn-block py-1 px-3  rounded ">
                    Add
                  </button>
                </div>
                <div
                  className="action ml-3 mt-2 mt-sm-0"
                  onClick={this.handleNewList}
                >
                  <button className="btn btn-danger h-100 btn-block py-1 px-3 rounded ">
                    Cancel
                  </button>
                </div>
              </div>
            </>
          ) : null}
          <div
            className="create-list-btn cursor-pointer pb-2"
            onClick={this.handleNewList}
          >
            <h5 className="font-montserrat text-grey-4 d-inline-block mr-4">
              Create a New List
            </h5>
            <img src="/assets/images/add.png" alt="add-item" />
          </div>
          {groceryList
            ? groceryList.map((list, index) => (
                <>
                  <ul
                    className="font-poppins font-weight-semi fs-16 cursor-pointer"
                    key={index}
                    onClick={() => this.addGroceryItemToList(list, ingredients)}
                  >
                    <li className="text-dark">
                      {list.list_name}
                      {/* {list && list.grocery_items && list.grocery_items.length ?
                                list.grocery_items.includes()
                            :null } */}
                      {this.state.showRight &&
                      this.state.showRight.includes(list.id) == true ? (
                        // <img src="/assets/images/like.png" alt="add-item" />
                        <span className="fa fa-check ml-2 text-success"></span>
                      ) : null}
                    </li>
                  </ul>
                </>
              ))
            : null}
        </div>
      </Dropdown.Menu>
    );
    //})
  };
  handleStatus = () => {
    this.setState({ showRight: [] });
  };
  render() {
    let ingredientsData = this.props && this.props.recipeIngredients;

    return (
      <>
        {/*recipe ingredients box */}
        <div className="recipe-ingredients">
          <h6 className="recipe-heading bg-primary rounded-pill d-inline-block text-white text-center py-2 mt-4">
            Ingredients
          </h6>
          <div className="add-action-wrapper text-right mt-3 mb-2">
            {/* dropdown for grocery list */}
            <Dropdown className="d-inline-block">
              <Dropdown.Toggle variant=" " id="dropdown-basic">
                <span
                  href="#"
                  className="text-dark mr-3 d-inline-block cursor-pointer font-weight-bold pb-2"
                >
                  Add All
                </span>
                <img
                  src="/assets/images/add.png"
                  alt="add-item"
                  onClick={this.handleStatus}
                />
              </Dropdown.Toggle>
              {this.GroceryListAction(ingredientsData)}
            </Dropdown>
          </div>
          {/* dropdown for grocery list  Ends*/}

          {/* recipe items lists */}
          <ul className="recipe-item-list">
            {ingredientsData &&
              ingredientsData.map((ingredients) => (
                <li className="item-box d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <h5>{ingredients.name}</h5>
                    <h6 className="text-grey-4 d-inline-block mr-5">
                      {ingredients.quantity}
                    </h6>
                    <h6 className="text-grey-4 d-inline-block">
                      {ingredients.category}
                    </h6>
                  </div>
                  {/* dropdown for grocery list */}
                  <Dropdown>
                    <Dropdown.Toggle variant=" " id="dropdown-basic">
                      <img
                        src="/assets/images/add.png"
                        alt="add-item"
                        onClick={this.handleStatus}
                      />
                    </Dropdown.Toggle>
                    {this.GroceryListAction(ingredients)}
                  </Dropdown>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groceryList: state.get_grocery_list_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGroceryList: () => dispatch(get_grocery_list_action()),
  addGroceryList: (data) => dispatch(add_grocery_list_action(data)),
  addGroceryItemToList: (id, data) =>
    dispatch(add_grocery_item_to_list_action(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeIngredients);
