import React, { Component } from 'react';
import "./SuggestedRecipes.scss";


class SuggestedRecipes extends Component {
    render() {
        return (
            <div className="common-dashboard suggestions-wapper">
                <ul className="suggestion-list">
                    <li className="suggestion-detail d-flex mb-4">
                        <img src="/assets/images/slider-item.svg" alt="suggestions-item" className="img-fit border-6 mr-4" />
                        <div className="right d-flex flex-column align-items-around">
                            <h6>Recipe Name</h6>
                            <p className="info font-weight-semi font-smallest pt-1">Information</p>
                            <p className="time text-grey-4 font-weight-medium font-smallest">Time</p>
                        </div>
                    </li>
                    <li className="suggestion-detail d-flex mb-4">
                        <img src="/assets/images/slider-item.svg" alt="suggestions-item" className="img-fit border-6 mr-4" />
                        <div className="right d-flex flex-column align-items-around">
                            <h6>Recipe Name</h6>
                            <p className="info font-weight-semi font-smallest pt-1">Information</p>
                            <p className="time text-grey-4 font-weight-medium font-smallest">Time</p>
                        </div>
                    </li>
                   
                    <li className="suggestion-detail d-flex mb-4">
                        <img src="/assets/images/slider-item.svg" alt="suggestions-item" className="img-fit border-6 mr-4" />
                        <div className="right d-flex flex-column align-items-around">
                            <h6>Recipe Name</h6>
                            <p className="info font-weight-semi font-smallest pt-1">Information</p>
                            <p className="time text-grey-4 font-weight-medium font-smallest">Time</p>
                        </div>
                    </li>
                   
                    <li className="suggestion-detail d-flex mb-4">
                        <img src="/assets/images/slider-item.svg" alt="suggestions-item" className="img-fit border-6 mr-4" />
                        <div className="right d-flex flex-column align-items-around">
                            <h6>Recipe Name</h6>
                            <p className="info font-weight-semi font-smallest pt-1">Information</p>
                            <p className="time text-grey-4 font-weight-medium font-smallest">Time</p>
                        </div>
                    </li>
                   
                   
                  
                </ul>
            </div>
        )
    }
}
export default SuggestedRecipes;